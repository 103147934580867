import DialogModal from '@/components/DialogModal';
import usePersistedState from '@/hooks/usePersistedState';
import {
  useCronofyAuthUrl,
  useCronofyDisconnectProfile,
  useCronofyProfiles,
  useCronofySetInboundSync,
  useCronofySetOutboundSync,
} from '@/services/cronofy/hooks';
import { IconAlert, IconArrowDownFull, IconPlus } from '@/theme/icons';
import { providerIcon } from '@/utils/calendar';
import rem from '@/utils/rem';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  Tooltip,
  UnorderedList,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';

type CalendarsListProps = {
  separatorColor: string;
};

function CalendarsSettings({ separatorColor }: CalendarsListProps) {
  const toast = useToast();
  const { data: cronofyAuthUrl } = useCronofyAuthUrl();
  const { data: cronofyProfiles, refetch: _refetchProfiles } = useCronofyProfiles();
  const { mutateAsync: disconnectProfile } = useCronofyDisconnectProfile();
  const { mutate: setInboundSync } = useCronofySetInboundSync();
  const { mutateAsync: setOutboundSync } = useCronofySetOutboundSync();
  const [oldCronofyProfilesLength, setOldCronofyProfilesLength] = useState<number>(cronofyProfiles?.length || 0);
  const [isFetching, setIsFetching] = useState(false);

  const [reduceMotion, setReduceMotion] = useState(false);
  const refetchProfiles = useCallback(async () => {
    // Chakra UI Accordion has a bug where it doesn't start the expand animation
    // when an expanded element is dynamically added to the list. To fix this,
    // we disable animations while we are loading the new list, and re-enable
    // them after the list has been rendered.
    setReduceMotion(true);
    await _refetchProfiles();
    setTimeout(() => setReduceMotion(false), 200);
  }, [_refetchProfiles]);

  useEffect(() => {
    if (
      oldCronofyProfilesLength === undefined ||
      cronofyProfiles?.length === oldCronofyProfilesLength ||
      !cronofyProfiles
    )
      return;

    const isProfileRemoved = cronofyProfiles.length < oldCronofyProfilesLength;
    setOldCronofyProfilesLength(cronofyProfiles.length);

    toast({
      title: `Calendar ${isProfileRemoved ? 'removed' : 'added'}`,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

    if (isProfileRemoved) {
      setProfileIdToDelete(undefined);
    }
  }, [cronofyProfiles]);

  const [profileIdToDelete, setProfileIdToDelete] = useState<string>();

  const handleDeleteProfile = async () => {
    if (!profileIdToDelete) return;
    try {
      setIsFetching(true);
      await disconnectProfile({ profileId: profileIdToDelete });
      await refetchProfiles();
      await handleSetOutboundSync(profileIdToDelete, null);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const [loadingOutbountSync, setLoadingOutbountSync] = useState<string>();

  const handleSetOutboundSync = async (profileId: string, calendarId: string | null) => {
    setLoadingOutbountSync(profileId);
    // Make sure the spinner is visible for at least 1.5 seconds
    await Promise.all([
      setOutboundSync({ profileId, calendarId }),
      new Promise((resolve) => setTimeout(resolve, 1500)),
    ]);
    setLoadingOutbountSync(undefined);
  };

  const openPopup = (url: string) => {
    if (!url) return;

    const width = 600;
    const height = 820;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;

    // Note: in order for Safari to not block the popup, the window.open() call
    // must be triggered by a user action, meaning it must be called from a
    // click event handler and it must not be async
    const newWindow = window.open(
      url,
      'Connect Calendar Account',
      `scrollbars=1,width=${width},height=${height},left=${left},top=${top}`,
    );

    if (!newWindow) return;

    // Unfortunately this is the only way to detect when both the connect and
    // relink popups get closed. An alternative approach was to use postMessage,
    // but that would have worked only for the connect popup, not the relink one,
    // as the latter is fully managed by Cronofy, and we can't add the postMessage
    // custom code to it.
    const interval = setInterval(() => {
      if (newWindow.closed) {
        refetchProfiles();
        // In some instances, Cronofy profiles are not yet updated after the
        // popup is closed, so the refetch above doesn't pick up the updates.
        // To fix this, we refetch again after a delay. If everything goes
        // well update will be instantaneous, otherwise will come a bit later.
        setTimeout(() => refetchProfiles(), 2000);
        clearInterval(interval);
      }
    }, 500);
  };

  // Storing collapsed profiles instead of expanded ones, so if a new unknown
  // profile appears it will not be present in this list, and so will be rendered
  // expanded by default
  const [collapsedProfiles, setCollapsedProfiles] = usePersistedState(
    'rpm-settings-collapsed-profiles',
    Array<string>(),
  );
  const expandedAccordionIndexes = useMemo(
    () =>
      (cronofyProfiles ?? [])
        .map((profile, index) => (collapsedProfiles.includes(profile.id) ? false : index))
        .filter((index) => index !== false) as number[],
    [cronofyProfiles, collapsedProfiles],
  );

  const transition = '0.25s ease';

  return (
    <>
      {cronofyProfiles && cronofyProfiles.length > 0 && (
        <Accordion
          allowMultiple
          defaultIndex={expandedAccordionIndexes}
          index={expandedAccordionIndexes}
          onChange={(expandedIndexes) =>
            setCollapsedProfiles(
              cronofyProfiles
                .filter((_, index) => (expandedIndexes as number[]).includes(index) === false)
                .map((profile) => profile.id),
            )
          }
          reduceMotion={reduceMotion}
        >
          {cronofyProfiles.map((profile) => (
            <AccordionItem
              key={profile.id}
              borderBottom={`${rem(1)} solid ${separatorColor}`}
              paddingX={rem(16)}
              role="group"
            >
              {/* Calendar profile header */}
              <Box position="relative" width="full">
                <AccordionButton gap={rem(8)} display="flex" width="full" paddingX={0} paddingY={rem(16)}>
                  <Icon as={providerIcon.get(profile.provider)} width={rem(18)} />
                  <Text
                    overflow="hidden"
                    minWidth={0}
                    fontSize="lg"
                    fontWeight={500}
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {profile.name}
                  </Text>
                  {profile.relinkUrl && (
                    <Icon
                      as={IconAlert}
                      // margin-right leaves room for the bin button in case the
                      // text is very long and fills the width (the bin button is
                      // positioned absolutely to avoid having a button-inside-button
                      // situation)
                      marginRight={rem(32)}
                      color="amber.400"
                    />
                  )}
                  {/* Container to add padding to leave space for bin icon in 
                      case of very long text */}
                  <Flex marginLeft="auto" paddingLeft={rem(20)}>
                    <AccordionIcon />
                  </Flex>
                </AccordionButton>
                <Tooltip label="Disconnect Calendar Account" placement="top">
                  <IconButton
                    position="absolute"
                    top={rem(20)}
                    right={rem(28)}
                    marginLeft="auto"
                    opacity={0}
                    _groupHover={{ opacity: 1 }}
                    transition={`opacity ${transition}`}
                    aria-label="remove profile"
                    icon={<Icon as={FaRegTrashAlt} size={rem(14)} />}
                    onClick={() => setProfileIdToDelete(profile.id)}
                    size={rem(14)}
                    variant="tertiary"
                  />
                </Tooltip>
              </Box>

              {/* List of calendars */}
              <AccordionPanel padding={0}>
                {profile.relinkUrl && (
                  <HStack alignItems="start" gap={rem(8)} paddingBottom={rem(16)} color="amber.400">
                    {/* The Flex below centers the alert icon with the others  */}
                    <Flex justifyContent="center" flexShrink={0} width={rem(18)}>
                      <Icon as={IconAlert} boxSize={rem(14)} />
                    </Flex>
                    <Text width="full" fontSize="sm">
                      There are issues connecting to this account,
                      <br />
                      <Link textDecoration="underline" onClick={() => openPopup(profile.relinkUrl)}>
                        click here to relink
                      </Link>
                      .
                    </Text>
                  </HStack>
                )}
                {profile.initializing ? (
                  <Text
                    width="full"
                    paddingTop={rem(8)}
                    paddingBottom={rem(24)}
                    color="gray.400"
                    fontSize="md"
                    textAlign="center"
                  >
                    Loading calendars…
                    <Spinner marginLeft={rem(10)} size="xs" />
                  </Text>
                ) : (
                  <>
                    <UnorderedList
                      flexDirection="column"
                      gap={rem(8)}
                      display="flex"
                      margin={0}
                      paddingTop={rem(4)}
                      aria-label="Read events from"
                    >
                      {profile.calendars.map((calendar) => (
                        <ListItem key={calendar.id} display="flex">
                          <Checkbox
                            sx={{ span: { overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 'md' } }}
                            minWidth={0}
                            isChecked={calendar.inboundSync}
                            onChange={() =>
                              setInboundSync({
                                profileId: profile.id,
                                calendarId: calendar.id,
                                enabled: !calendar.inboundSync,
                              })
                            }
                            size="sm"
                          >
                            {calendar.name}
                          </Checkbox>
                        </ListItem>
                      ))}
                    </UnorderedList>

                    {/* Dropdown for outbound sync */}
                    <Text
                      as="span"
                      display="flex"
                      height={rem(15)} // Keeps height stable when spinner appears
                      marginTop={rem(20)}
                      marginBottom={rem(8)}
                      color="gray.400"
                      fontSize="xs"
                      fontWeight="900"
                      textTransform="uppercase"
                    >
                      Write RPM events to:
                      {loadingOutbountSync === profile.id && <Spinner marginLeft="auto" size="xs" />}
                    </Text>
                    <Menu matchWidth>
                      <MenuButton
                        as={Button}
                        sx={{
                          _disabled: { opacity: 0.6 },
                          'span:first-of-type': { overflow: 'hidden', textOverflow: 'ellipsis' },
                        }}
                        justifyContent="space-between"
                        display="flex"
                        width="full"
                        marginBottom={rem(24)}
                        fontSize="md"
                        textAlign="left"
                        transition={`opacity ${transition}`}
                        isDisabled={loadingOutbountSync === profile.id}
                        rightIcon={<Icon as={IconArrowDownFull} boxSize={rem(7)} />}
                        variant="secondary"
                      >
                        {profile.outboundSyncCalendarId
                          ? profile.calendars.find((calendar) => calendar.id === profile.outboundSyncCalendarId!)?.name
                          : 'None'}
                      </MenuButton>
                      <MenuList
                        sx={{
                          // Style of the menu items
                          button: {
                            justifyContent: 'space-between',
                            background: 'none',
                            display: 'flex',
                            _hover: { backgroundColor: 'background-tertiary' },
                            'span:first-of-type': {
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              minWidth: 0,
                            },
                            'span:last-of-type': {
                              flexShrink: 0,
                              width: rem(20),
                              textAlign: 'right',
                            },
                          },
                        }}
                        padding={rem(8)}
                        fontSize="md"
                        borderWidth={rem(1)}
                        borderStyle="solid"
                        borderColor="gray.700"
                        borderRadius="4"
                        backgroundColor="background-primary"
                      >
                        <MenuItem onClick={() => handleSetOutboundSync(profile.id, null)}>
                          <Box as="span">None</Box>
                          <Box as="span">{profile.outboundSyncCalendarId === null && '✓'}</Box>
                        </MenuItem>
                        {profile.calendars
                          ?.filter((calendar) => !calendar.readonly) //Don't show readonly calendars
                          .map((calendar) => (
                            <MenuItem
                              key={calendar.id}
                              onClick={() =>
                                profile.outboundSyncCalendarId !== calendar.id &&
                                handleSetOutboundSync(profile.id, calendar.id)
                              }
                            >
                              <Box as="span">{calendar.name}</Box>
                              <Box as="span">{profile.outboundSyncCalendarId === calendar.id && '✓'}</Box>
                            </MenuItem>
                          ))}
                      </MenuList>
                    </Menu>
                  </>
                )}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )}

      <Box borderBottom={`${rem(1)} solid ${separatorColor}`} paddingX={rem(16)} paddingY={rem(24)}>
        <Button
          width="full"
          height="unset"
          fontSize="inherit"
          fontWeight="inherit"
          leftIcon={<IconPlus width={rem(24)} height={rem(24)} />}
          onClick={() => cronofyAuthUrl && openPopup(cronofyAuthUrl)}
          paddingY={rem(16)}
          variant="secondary"
        >
          Add Calendar Account
        </Button>
      </Box>

      <DialogModal
        isOpen={profileIdToDelete !== undefined}
        onCancel={() => setProfileIdToDelete(undefined)}
        title="Confirmation"
        message="Are you sure you want to disconnect this calendar account?"
        confirmVariant="danger"
        confirmText="Disconnect"
        onConfirm={handleDeleteProfile}
        isButtonConfirmLoading={isFetching}
      />
    </>
  );
}

export default CalendarsSettings;
