import CaptureThrive from '@/components/CaptureThrive';
import CreateEditCategoryModal from '@/components/CreateEditCategoryModal';
import MonthYearNavigator from '@/components/MonthYearNavigator';
import { FadeInOutVariants } from '@/constants/animations';
import { useAnimatePlanPages } from '@/contexts/AnimatedPlanPages';
import { GET_MONTHLY_CALENDAR_ACTIONS } from '@/gql/actions';
import { keys } from '@/gql/global/keys';
import { APIError } from '@/gql/global/types';
import DashboardLayout from '@/layouts/Dashboard';
import { useCategories } from '@/services/categories/hooks';
import { useCronofyProfiles } from '@/services/cronofy/hooks';
import { useExternalEvents } from '@/services/events/hooks';
import { fetchData } from '@/services/graphql';
import { useActionModal } from '@/stores/useActionModal';
import { CMImageFour, CMImageTen } from '@/theme/StaticImages';
import { ActionResponse, isPlannedAction } from '@/types/actions';
import { MyEvent } from '@/types/calendar';
import { calendarAdapterApi, endOfTheMonth, externalCalendarAdapterApi, startOfTheMonth } from '@/utils/calendar';
import { getColorFromToken } from '@/utils/color';
import { pageTransition } from '@/utils/pageAnimation';
import rem from '@/utils/rem';
import { trackCreateCategorySelected } from '@/utils/tracking';
import { Button, Flex, Spinner, Text, VStack, useColorMode } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { Suspense, lazy, useCallback, useMemo, useState } from 'react';

const MonthlyCalendar = lazy(() => import('@/components/MonthlyCalendar'));

const blue1000 = getColorFromToken('blue.1000', '1');

const MotionFlex = motion(Flex);

function Homepage() {
  const { pageNavigationEffect } = useAnimatePlanPages();
  const { colorMode } = useColorMode();

  const { data: cronofyProfiles } = useCronofyProfiles();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const startDate = selectedDate && startOfTheMonth(selectedDate);
  const endDate = selectedDate && endOfTheMonth(selectedDate);

  const { onGetCurrentActionData: getCurrentActionData, setActionModalOpen } = useActionModal();

  const { data: categoriesList } = useCategories();
  // < 2 because Uncategorized category is always present
  const isEmptyState = (categoriesList?.category?.length ?? 0) < 2;

  const blue1000_a50 = getColorFromToken('blue.1000', isEmptyState ? '.8' : '0.50');

  const [isCreateCategoryModalOpen, setIsCreateCategoryModalOpen] = useState(false);

  const { data: actionsList } = useQuery<ActionResponse, APIError, ActionResponse>({
    ...keys.actions.all._ctx.month(startDate, endDate),
    queryFn: () =>
      fetchData(GET_MONTHLY_CALENDAR_ACTIONS, {
        where: {
          scheduledDate: {
            _gte: startDate,
            _lte: endDate,
          },
        },
      }),
    // This query was refetched on every load of the homepage via a useEffectOnce
    // in the DashboardLayout component. Refactored to use query props instead.
    // TODO: leverage cache and avoid refetching at every load
    staleTime: 0,
    refetchOnMount: true,
    enabled: !!selectedDate,
  });

  const { data } = useExternalEvents(startDate, endDate);

  const adaptedInternalAndExternalEvents = useMemo(() => {
    const plannedActions = actionsList?.action.filter(isPlannedAction) || [];
    const actionsWithCategory = plannedActions.filter((action) => action.category !== null);

    const events = actionsWithCategory.map((action) => calendarAdapterApi(action));
    const externalEvents = externalCalendarAdapterApi(data ?? [], cronofyProfiles);

    return [...events, ...externalEvents];
  }, [cronofyProfiles, data, actionsList?.action]);

  const onClickEvent = useCallback(
    (event: MyEvent) => {
      const action = event.__metadata?.action;
      if (!action) return;
      getCurrentActionData(action);
      setActionModalOpen(true, {
        onCreateEvent: () => null,
        readonlyCategory: true,
        readonlyTooltipLabel: 'The category of this action cannot be changed on the planner',
      });
    },
    [getCurrentActionData, setActionModalOpen],
  );

  const backgroundImage = isEmptyState ? CMImageTen : CMImageFour;

  return (
    <DashboardLayout pageTitle="Dashboard" paddingTop={0} position="relative" paddingX={0}>
      <motion.div initial="initial" animate="in" exit="out" variants={pageNavigationEffect} transition={pageTransition}>
        <MotionFlex
          position="absolute"
          top="0"
          variants={FadeInOutVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{ duration: 0.5 }}
          width="full"
          height={{
            base: isEmptyState ? 'full' : rem(200),
          }}
          background={
            colorMode === 'dark'
              ? `linear-gradient(180deg, ${blue1000_a50} ${isEmptyState ? '18.43%' : '33.43%'}, ${blue1000} 95.38%), url(${backgroundImage}), lightgray`
              : `linear-gradient(180deg, rgba(255, 255, 255, 0.7) ${isEmptyState ? '63.43%' : '33.43%'}, white 95.38%), url(${backgroundImage}), white`
          }
          backgroundSize="cover"
          backgroundPosition="center 45%"
          backgroundRepeat="no-repeat"
          backgroundColor="blue.1000"
          justifyContent={isEmptyState ? 'flex-start' : 'center'}
          alignItems={'center'}
          flexDirection="column"
          gap={rem(40)}
          paddingX={{
            base: rem(32),
            lg: rem(160),
          }}
          textAlign="center"
        >
          <Text
            as="h4"
            textStyle={isEmptyState ? 'h2BarSB' : 'h4BarSB'}
            marginTop={rem(isEmptyState ? 70 : 0)}
            color="text-primary"
            textTransform="uppercase"
          >
            “Activity without purpose is the drain to a life of fulfillment.” -Tony Robbins{' '}
          </Text>
        </MotionFlex>

        <Suspense
          fallback={
            <Flex justifyContent="center" width="full">
              <Spinner size="xl" />
            </Flex>
          }
        >
          <VStack
            gap={rem(16)}
            width="full"
            marginTop={{
              base: isEmptyState ? rem(350) : rem(200),
            }}
            marginBottom={rem(96)}
            padding="0"
            paddingX={rem(45)}
          >
            {isEmptyState ? (
              <Button
                height={rem(50)}
                onClick={() => {
                  setIsCreateCategoryModalOpen(true);
                  trackCreateCategorySelected('home');
                }}
                type="button"
              >
                Get Started! Create a Category
              </Button>
            ) : (
              <>
                <MonthYearNavigator selectedDate={selectedDate} updateSelectedDate={setSelectedDate} showDatepicker />
                <MonthlyCalendar
                  selectedDate={selectedDate}
                  events={adaptedInternalAndExternalEvents}
                  onClickEvent={onClickEvent}
                />
                <CaptureThrive />
              </>
            )}
          </VStack>
        </Suspense>
      </motion.div>
      <CreateEditCategoryModal
        isOpen={isCreateCategoryModalOpen}
        onClose={() => setIsCreateCategoryModalOpen(false)}
        showEntityModalOnCreate
      />
    </DashboardLayout>
  );
}

export default Homepage;
