import ActionListCompletedActions from '@/components/ActionListCompletedActions';
import ActionRow from '@/components/ActionRow';
import AddActionButton from '@/components/AddActionButton';
import ConfirmDeleteBlockModal from '@/components/ConfirmDeleteBlockModal';
import CoverImageModal from '@/components/CoverImageModal';
import CreateBlockModal from '@/components/CreateBlockModal';
import DeleteBlockModal from '@/components/DeleteBlockModal';
import DetailsCard from '@/components/DetailsCard';
import DialogModal from '@/components/DialogModal';
import DndContainer from '@/components/DndContainer';
import { ActionList, DroppableContainer } from '@/components/DragAndDrop';
import ActionListItem from '@/components/DragAndDrop/ActionListItem';
import DnDBlockItem from '@/components/DragAndDrop/DnDBlockItem';
import DuplicateBlockModal from '@/components/DuplicateBlockModal';
import EditBlockModal from '@/components/EditBlockModal';
import EmptyActionList from '@/components/EmptyActionList';
import HeroImage from '@/components/HeroImage';
import ImageViewerModal from '@/components/ImageViewerModal';
import ManageProjectModal from '@/components/ManageProjectModal';
import { EventsPerDayType } from '@/components/MyPlanStep/WeeklyCalendar';
import NotesModal from '@/components/NotesModal';
import InspirationBoard from '@/components/ProjectDetail/InspirationBoard';
import KeyResultItem from '@/components/ProjectDetail/KeyResultItem';
import KeyResults from '@/components/ProjectDetail/KeyResults';
import PlannerControls from '@/components/ProjectDetail/PlannerControls';
import ProjectProgress from '@/components/ProjectDetail/ProjectProgress';
import ThreeDotsProjectButton from '@/components/ProjectDetail/ThreeDotsProjectButton';
import WeeklyCalendar, { CalendarEventType, DataType } from '@/components/ProjectDetail/WeeklyCalendar';
import UncompletedActionsModal from '@/components/UncompletedActionsModal';
import { COMPLETE } from '@/constants/action';
import { CATEGORIES_ICONS } from '@/constants/category';
import { keys } from '@/gql/global/keys';
import { GetBoardInspirationItemResponse } from '@/gql/project/types';
import DashboardLayout from '@/layouts/Dashboard';
import { sortBlocksByCompletion } from '@/pages/WeeklyPlan/MyWeeklyPlan/helpers';
import { RoutesList } from '@/routes/routesList';
import { useCategories } from '@/services/categories/hooks';
import {
  removeIncompleteActionsFromBlock,
  useAddActionToBlock,
  useCompleteBlockStatus,
  useDeleteBlock,
  useIncompleteBlockStatus,
  useRemoveActionFromBlock,
  useUpdateBlockActionsBlockOrder,
} from '@/services/myPlan/hooks';
import {
  useDeleteInspirationBoardItemByPk,
  useDeleteProject,
  useEditNoteByPk,
  useInsertImageOne,
  useInsertNoteOne,
  useProjectDetail,
  useUpdateActionsProjectOrder,
  useUpdateBlockProjectOrder,
  useUpdateKeyResultsOrder,
  useUpdateProject,
  useUpdateProjectImage,
} from '@/services/project/hooks';
import { useActionModal } from '@/stores/useActionModal';
import { useActionsCategories } from '@/stores/useActionsCategories';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { IconChevronLeft, IconClock, IconFire, IconMountain, IconStar } from '@/theme/icons';
import { Action } from '@/types/actions';
import { Block } from '@/types/block';
import { ImageInfo, NoteInfo } from '@/types/inspirations';
import { ActionEventType } from '@/types/myPlan';
import { KeyResult } from '@/types/project';
import {
  getActionById,
  getActionsByBlockId,
  getCompletedActions,
  getIncompleteActions,
  getIncompleteActionsByBlockId,
  getTotalDurationOfActionsExtendedFormat,
} from '@/utils/action';
import { getBlockById } from '@/utils/block';
import { endOfTheWeek, getDaysWithinDatesRange, startOfTheWeek } from '@/utils/calendar';
import { getCategoryById } from '@/utils/category';
import { getColorFromToken } from '@/utils/color';
import { getTotalDurationOfStarredActions } from '@/utils/events';
import { fixUncategorizedName } from '@/utils/index';
import { eventFor } from '@/utils/myplan';
import {
  getKeyResultById,
  getPercentageOfCompletedAllActions,
  getPercentageOfCompletedStarredActions,
} from '@/utils/project';
import rem from '@/utils/rem';
import { invalidateQueries } from '@/utils/tanStackQuery';
import { trackBulkActionCompleted, trackProjectDeleted } from '@/utils/tracking';
import {
  Box,
  Button,
  Editable,
  EditablePreview,
  EditableTextarea,
  Flex,
  HStack,
  Icon,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { DragEndEvent, DragOverlay, DragStartEvent, UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { format } from 'date-fns';
import { isEmpty, isNil, isNull, orderBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { v4 as uuidv4 } from 'uuid';

type UpdateProjectPayload = {
  name: string;
  result: string;
  purpose: string;
  categoryId: string;
  imageUrl?: string | null;
};

interface ProjectLayoutProps {
  children: React.ReactNode;
  onNavigate: () => void;
  onOpenProjectImagesModal: () => void;
  openUpdateProjectModal: () => void;
  onOpenDeleteProjectModal: () => void;
  isMobileScreen?: boolean;
}

const ProjectLayout = ({
  children,
  onNavigate,
  onOpenProjectImagesModal,
  openUpdateProjectModal,
  onOpenDeleteProjectModal,
  isMobileScreen,
}: ProjectLayoutProps) => (
  <DashboardLayout paddingX="0" pageTitle="Project" paddingTop="0">
    <Flex
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
      position="sticky"
      zIndex="banner"
      top="0"
      alignItems="center"
      justifyContent="space-between"
      width="full"
      height={rem(44)}
      padding={`${rem(6)} ${rem(16)}`}
      boxShadow="0px 3px 10px 0px rgba(0,0,0,0.3)"
      backgroundColor="background-tertiary"
    >
      <HStack gap={rem(16)} height="full">
        <Button
          alignItems="center"
          flexDirection="row"
          gap={rem(12)}
          display="flex"
          onClick={onNavigate}
          variant="unstyled"
        >
          <Icon as={IconChevronLeft} boxSize={rem(14)} />
          <Text textStyle="large">Projects</Text>
        </Button>
      </HStack>
      <HStack>
        <Button
          height={rem(32)}
          onClick={onOpenProjectImagesModal}
          paddingInline={rem(8)}
          size="sm"
          variant="secondary"
        >
          <HStack alignItems="center">
            <Icon as={IconMountain} boxSize={rem(14)} color="gray.300" />
            <Text textStyle="small" color="text-primary" fontWeight={900}>
              {!isMobileScreen && `Change `}Cover Image
            </Text>
          </HStack>
        </Button>

        <ThreeDotsProjectButton onClickEdit={openUpdateProjectModal} onClickDelete={onOpenDeleteProjectModal} />
      </HStack>
    </Flex>

    {children}
  </DashboardLayout>
);

function ProjectDetail() {
  const { id: projectId } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedNoteData, setSelectedNoteData] = useState<{ id: string; body: string; color: string } | null>(null);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [activeItemCameFrom, setActiveItemCameFrom] = useState('');
  const [tempBlockId, setTempBlockId] = useState<string | null>(null);
  const [tempBlockActionId, setTempBlockActionId] = useState<string | null>(null);
  const [tempResult, setTempResult] = useState<string | null>(null);
  const [addActionToBlockAfterDelete, setAddActionToBlockAfterDelete] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState<Block | null>(null);
  const [isSavingImage, setIsSavingImage] = useState(false);

  const isMobileScreen = useBreakpointValue({ base: true, lg: false });

  const { data: categories } = useCategories();
  const insertInspirationImage = useInsertImageOne();
  const { isLoading: iseDeletingInspirationBoardItem, mutate: deleteInspirationBoardItem } =
    useDeleteInspirationBoardItemByPk({
      onSuccess: () => {
        if (!projectId) return;

        invalidateQueries([keys.project.detail(projectId).queryKey]);
      },
    });
  const { isLoading: isLoadingInsertNote, mutate: insertInspirationNote } = useInsertNoteOne();
  const { isLoading: isEditingNote, mutate: editInspirationNote } = useEditNoteByPk({
    onSuccess: () => {
      if (!projectId) return;

      invalidateQueries([keys.project.detail(projectId).queryKey]);
    },
  });

  const { data: project, isLoading } = useProjectDetail(projectId, {
    cacheTime: 0,
  });

  const [result, setResult] = useState(project?.projectByPk?.result);
  const [purpose, setPurpose] = useState(project?.projectByPk?.purpose);

  const {
    isOpen: isOpenCreateBlockModal,
    onOpen: openCreateBlockModal,
    onClose: onCloseCreateBlockModal,
  } = useDisclosure();

  const {
    isOpen: isOpenDeleteBlockModal,
    onOpen: openDeleteBlockModal,
    onClose: onCloseDeleteBlockModal,
  } = useDisclosure();

  const {
    isOpen: isOpenConfirmDeleteBlockModal,
    onOpen: openConfirmDeleteBlockModal,
    onClose: onCloseConfirmDeleteBlockModal,
  } = useDisclosure();

  const {
    isOpen: isOpenUpdateProjectModal,
    onOpen: openUpdateProjectModal,
    onClose: onCloseUpdateProjectModal,
  } = useDisclosure();

  const {
    isOpen: isDeleteProjectModalOpen,
    onOpen: onOpenDeleteProjectModal,
    onClose: onCloseDeleteProjectModal,
  } = useDisclosure();

  const { isOpen: isImagesModalOpen, onOpen: onOpenImagesModal, onClose: onCloseImagesModal } = useDisclosure();
  const {
    isOpen: isProjectImagesModalOpen,
    onOpen: onOpenProjectImagesModal,
    onClose: onCloseProjectImagesModal,
  } = useDisclosure();

  const { isOpen: isNotesModalOpen, onOpen: onOpenNotesModal, onClose: onCloseNotesModal } = useDisclosure();

  const { isOpen: isImageViewerOpen, onOpen: onOpenImageViewer, onClose: onCloseImageViewer } = useDisclosure();

  const { isOpen: isOpenEditBlockModal, onOpen: openEditBlockModal, onClose: onCloseEditBlockModal } = useDisclosure();

  const {
    isOpen: isOpenDuplicateBlockModal,
    onOpen: openDuplicatedBlockModal,
    onClose: onCloseDuplicatedBlockModal,
  } = useDisclosure();

  const {
    isOpen: isOpenSetUncompletedActionsModal,
    onOpen: openSetUncompletedActionsModal,
    onClose: onCloseSetUncompletedActionsModal,
  } = useDisclosure();

  const { onGetCurrentActionData: getCurrentActionData, setActionModalOpen } = useActionModal();

  const updateSelectedCategory = useActionsCategories((state) => state.updateSelectedCategory);

  const { mutateAsync: updateProject, isLoading: isUpdatingProject } = useUpdateProject();
  const { mutateAsync: updateProjectImage, isLoading: isUpdatingProjectImage } = useUpdateProjectImage();

  const { mutateAsync: deleteProject } = useDeleteProject();

  const { mutate: updateActionsProjectOrder } = useUpdateActionsProjectOrder({
    onSuccess: () => {
      if (!projectId) return;

      invalidateQueries([keys.project.detail(projectId).queryKey]);
    },
  });

  const { mutate: addActionToBlock } = useAddActionToBlock();

  const { mutate: updateBlockActionsBlockOrder } = useUpdateBlockActionsBlockOrder();

  const { mutate: removeActionFromBlock } = useRemoveActionFromBlock();

  const { mutate: deleteBlock } = useDeleteBlock();

  const { mutate: incompleteBlockStatus } = useIncompleteBlockStatus({
    onSuccess: () => {
      if (!projectId) return;

      invalidateQueries([keys.project.detail(projectId).queryKey]);
    },
  });

  const { mutate: completeBlockStatus } = useCompleteBlockStatus({
    onSuccess: () => {
      if (!projectId) return;

      invalidateQueries([keys.project.detail(projectId).queryKey]);
    },
  });

  const { mutate: updateKeyResultOrder } = useUpdateKeyResultsOrder({
    onSuccess: () => {
      if (!projectId) return;

      invalidateQueries([keys.project.detail(projectId).queryKey]);
    },
  });

  const { mutate: updateBlockProjectOrder } = useUpdateBlockProjectOrder({
    onSuccess: () => {
      if (!projectId) return;

      invalidateQueries([keys.project.detail(projectId).queryKey]);
    },
  });

  const selectedDate = useCalendarMonthlyStore((state) => state.selectedDate);

  const weekDays = getDaysWithinDatesRange(startOfTheWeek(selectedDate), endOfTheWeek(selectedDate));

  const category =
    project?.projectByPk.categoryId && getCategoryById(categories?.category ?? [], project.projectByPk.categoryId);

  const onInsertInspirationImage = useCallback(
    (selectedImage: ImageInfo) => {
      if (!projectId || !selectedImage?.imageUrl) {
        return;
      }

      insertInspirationImage.mutate(
        {
          id: uuidv4(),
          imageUrl: selectedImage?.imageUrl,
          mimeType: selectedImage?.mimeType,
          projectId,
        },
        {
          onSuccess: () => {
            onCloseImagesModal();

            setIsSavingImage(false);

            toast({
              title: 'Image saved',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          },

          onError: (error) => {
            setIsSavingImage(false);

            toast({
              title: error?.response?.errors?.[0]?.message,
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    },
    [insertInspirationImage, onCloseImagesModal, projectId, toast],
  );

  const onOpenImageFullScreenModal = useCallback(
    (selectedImage: Pick<ImageInfo, 'imageUrl'>) => {
      setSelectedImage(selectedImage?.imageUrl);

      onOpenImageViewer();
    },
    [onOpenImageViewer],
  );

  const onCloseImageFullScreenModal = useCallback(() => {
    onCloseImageViewer();
    setSelectedImage(null);
  }, [onCloseImageViewer]);

  const onInsertInspirationNote = useCallback(
    (noteData: NoteInfo) => {
      if (!projectId || !noteData?.body) {
        return;
      }

      insertInspirationNote(
        {
          id: uuidv4(),
          body: noteData?.body,
          color: noteData?.color,
          mimeType: 'txt',
          projectId,
        },
        {
          onSuccess: () => {
            onCloseNotesModal();

            toast({
              title: 'Note saved',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          },

          onError: (error) => {
            toast({
              title: error?.response?.errors?.[0]?.message,
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    },
    [insertInspirationNote, onCloseNotesModal, projectId, toast],
  );

  const onOpenNoteModal = useCallback(
    (noteInfo: Pick<GetBoardInspirationItemResponse, 'id' | 'body' | 'color'>) => {
      setSelectedNoteData({
        id: noteInfo?.id,
        body: noteInfo?.body ?? '',
        color: noteInfo?.color ?? '',
      });

      onOpenNotesModal();
    },
    [onOpenNotesModal],
  );

  const onCloseNoteModal = useCallback(() => {
    onCloseNotesModal();
    setSelectedNoteData(null);
  }, [onCloseNotesModal]);

  const onEditNote = useCallback(
    ({ body, color }: NoteInfo) => {
      if (!isEmpty(selectedNoteData)) {
        editInspirationNote({
          id: selectedNoteData?.id,
          body,
          color,
        });
      }

      onCloseNoteModal();
    },
    [editInspirationNote, onCloseNoteModal, selectedNoteData],
  );

  const onDeleteInspirationBoardItem = useCallback(
    (itemId: string, isNote?: boolean) => {
      if (!projectId && !itemId) {
        return;
      }

      deleteInspirationBoardItem({
        id: itemId,
      });

      toast({
        title: isNote ? 'Note deleted' : 'Image deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onCloseNoteModal();
    },
    [deleteInspirationBoardItem, onCloseNoteModal, projectId, toast],
  );

  const formattedEvents = useMemo<CalendarEventType>(() => {
    let events: Record<string, EventsPerDayType> = {};

    weekDays.forEach((day, index) => {
      const filteredEvents: ActionEventType[] = [];

      project?.projectByPk.actions?.forEach((action) => {
        const event = {
          action,
          typeName: action.event?.typeName || 'Event',
          id: action.event?.id,
          actionId: action.id,
        } as ActionEventType;

        const eventOnDay = eventFor(event, day);

        if (eventOnDay) {
          filteredEvents.push(eventOnDay);
        }
      });

      const key = `event-${category && category.id}-${index + 1}`;

      events = {
        ...events,
        [key]: {
          events: filteredEvents,
          day,
        },
      };
    });

    let categoryWithEvents = {} as DataType;

    const header = weekDays.map((day) => {
      const dateDay = new Date(day);

      return {
        weekDay: format(dateDay, 'eee'),
        monthDay: format(dateDay, 'dd'),
        dateDay,
      };
    });

    if (category) {
      categoryWithEvents = {
        category,
        events,
      };
    }

    return {
      header,
      data: categoryWithEvents,
    };
  }, [category, project?.projectByPk.actions, weekDays]);

  const filteredActions = useMemo(() => {
    if (!project?.projectByPk) {
      return [];
    }

    let captureList = project.projectByPk.actions;

    if (tempBlockActionId) {
      captureList = captureList.filter((action) => action.id !== tempBlockActionId);
    }

    // Filter actions without a blockId, but do not sort them here
    return orderBy(
      captureList.filter((action) => action.blockId === null),
      'projectOrder',
      'asc',
    );
  }, [project?.projectByPk, tempBlockActionId]);

  const incompleteActions = useMemo(() => getIncompleteActions(filteredActions, 'projectOrder'), [filteredActions]);
  const completedActions = useMemo(() => getCompletedActions(filteredActions, 'modifiedAt'), [filteredActions]);

  const filteredBlocks = useMemo<Block[]>(() => {
    if (!project?.projectByPk) {
      return [];
    }

    return orderBy(
      project?.projectByPk.blocks.filter(
        (block) => getActionsByBlockId(block.id, project?.projectByPk?.actions)?.length,
      ),
      'projectOrder',
      'asc',
    );
  }, [project?.projectByPk]);

  const onEditProject = useCallback(
    async ({ name, result, purpose, categoryId, imageUrl }: UpdateProjectPayload) => {
      if (!projectId) {
        return;
      }

      await updateProject({ id: projectId, name, result, purpose, categoryId, imageUrl: imageUrl ?? null });

      toast({
        title: 'Project edited',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onCloseUpdateProjectModal();
    },
    [onCloseUpdateProjectModal, projectId, toast, updateProject],
  );

  const onProjectImageUploadProject = useCallback(
    async ({ imageUrl }: { imageUrl?: string }) => {
      setIsSavingImage(true);
      if (!projectId || !imageUrl) {
        return;
      }

      await updateProjectImage({ id: projectId, imageUrl });

      toast({
        title: 'Project Image updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onCloseProjectImagesModal();
      setIsSavingImage(false);
    },
    [onCloseProjectImagesModal, projectId, toast, updateProjectImage],
  );

  const onDeleteProject = useCallback(async () => {
    if (!projectId) {
      return;
    }

    await deleteProject({ id: projectId });

    toast({
      title: 'Project deleted',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });

    trackProjectDeleted();

    navigate(RoutesList.ProjectsPage);
  }, [deleteProject, navigate, projectId, toast]);

  const handleDeleteBlock = useCallback(
    (block: Block | undefined) => {
      if (!block) {
        return;
      }

      setSelectedBlock(block);
      openDeleteBlockModal();
    },
    [openDeleteBlockModal],
  );

  const updateCaptureListActionsOrder = useCallback(
    (actions: Action[]) => {
      const mutationPayload = actions.map((action, index) => ({
        _set: {
          projectOrder: index + 1,
        },
        where: {
          id: {
            _eq: action.id,
          },
        },
      }));

      updateActionsProjectOrder(mutationPayload);
    },
    [updateActionsProjectOrder],
  );

  const updateBlockActionsOrder = useCallback(
    (actions: Action[]) => {
      const mutationPayload = actions.map((action, index) => ({
        _set: {
          blockOrder: index + 1,
        },
        where: {
          id: {
            _eq: action.id,
          },
        },
      }));

      updateBlockActionsBlockOrder({
        updates: mutationPayload,
        categoryId: actions[0].categoryId,
        projectId: actions[0].projectId,
      });
    },
    [updateBlockActionsBlockOrder],
  );

  const updateKeyResultsOrder = useCallback(
    (keyResults: KeyResult[]) => {
      const mutationPayload = keyResults.map((keyResult, index) => ({
        _set: {
          order: index + 1,
        },
        where: {
          id: {
            _eq: keyResult.id,
          },
        },
      }));

      updateKeyResultOrder(mutationPayload);
    },
    [updateKeyResultOrder],
  );

  const handleUpdateBlockProjectOrder = useCallback(
    (blocks: Block[]) => {
      const mutationPayload = blocks.map((action, index) => ({
        _set: {
          projectOrder: index + 1,
        },
        where: {
          id: {
            _eq: action.id,
          },
        },
      }));

      updateBlockProjectOrder(mutationPayload);
    },
    [updateBlockProjectOrder],
  );

  const onUpdateBlock = useCallback(
    (updatedBlock: Block) => {
      if (!projectId) return;

      invalidateQueries([keys.project.detail(projectId).queryKey]);

      onCloseEditBlockModal();
      setSelectedBlock(null);
    },
    [onCloseEditBlockModal, projectId],
  );

  const onDeleteBlock = useCallback(async () => {
    if (!selectedBlock) {
      return;
    }

    if (addActionToBlockAfterDelete && tempBlockActionId && tempBlockId && project?.projectByPk?.actions) {
      await addActionToBlock({
        actionId: tempBlockActionId,
        blockId: tempBlockId,
        blockOrder: getActionsByBlockId(tempBlockId, project.projectByPk.actions).length,
        projectId: project.projectByPk.id,
        categoryId: project.projectByPk.categoryId,
      });
    }

    deleteBlock({ blockId: selectedBlock.id, projectId });

    onCloseDeleteBlockModal();
    onCloseConfirmDeleteBlockModal();
    setSelectedBlock(null);
    onCloseEditBlockModal();
  }, [
    addActionToBlock,
    addActionToBlockAfterDelete,
    deleteBlock,
    onCloseConfirmDeleteBlockModal,
    onCloseDeleteBlockModal,
    onCloseEditBlockModal,
    project?.projectByPk?.id,
    project?.projectByPk?.actions,
    project?.projectByPk.categoryId,
    selectedBlock,
    tempBlockActionId,
    tempBlockId,
    projectId,
  ]);

  const handleAddActionToBlock = useCallback(
    (actionId: string, blockId: string) => {
      if (!project?.projectByPk?.blocks) {
        return;
      }

      const block = getBlockById(project.projectByPk.blocks, blockId);

      if (!block) {
        return;
      }

      let blockOrder = 0;

      if (project.projectByPk.blocks.length) {
        blockOrder = project.projectByPk.blocks.length;
      }

      addActionToBlock({
        actionId,
        blockId,
        blockOrder,
        projectId: project.projectByPk.id,
        categoryId: project.projectByPk.categoryId,
      });
    },
    [project?.projectByPk?.id, project?.projectByPk?.blocks, project?.projectByPk.categoryId, addActionToBlock],
  );

  const handleDeleteBlockAndAddActionToOtherBlock = useCallback(
    (fromBlockId: string, toBlockId: string, actionId: string) => {
      if (!project?.projectByPk) {
        return;
      }

      const block = getBlockById(project.projectByPk.blocks, fromBlockId);

      if (block) {
        setSelectedBlock(block);
        openConfirmDeleteBlockModal();

        setAddActionToBlockAfterDelete(true);
        setTempBlockId(toBlockId);
        setTempBlockActionId(actionId);
      }
    },
    [project?.projectByPk, openConfirmDeleteBlockModal],
  );

  const moveActionFromBlockToCaptureList = useCallback(
    (actionId: string, blockId: string, shouldDeleteBlock: boolean) => {
      if (!project) {
        return;
      }

      const block = getBlockById(project.projectByPk.blocks, blockId);

      if (shouldDeleteBlock && block) {
        setSelectedBlock(block);
        openConfirmDeleteBlockModal();
        return;
      }

      removeActionFromBlock({
        actionId,
        categoryId: block?.categoryId,
        projectId: block?.projectId,
      });
    },
    [openConfirmDeleteBlockModal, project, removeActionFromBlock],
  );

  const onCompleteBlock = useCallback(
    (blockId: string) => {
      completeBlockStatus({
        id: blockId,
      });
    },
    [completeBlockStatus],
  );

  const onUncompleteBlock = useCallback(
    (blockId: string) => {
      if (!project?.projectByPk) {
        return;
      }

      const block = getBlockById(project?.projectByPk?.blocks, blockId);

      if (!block) {
        return;
      }

      incompleteBlockStatus({
        id: blockId,
      });
    },
    [incompleteBlockStatus, project?.projectByPk],
  );

  const onCompleteBlockActions = useCallback(() => {
    if (!selectedBlock || !projectId) {
      return;
    }

    if (selectedBlock.actions) {
      const incompleteActions = getIncompleteActions(selectedBlock.actions);
      incompleteActions.forEach((action) => trackBulkActionCompleted(action));
    }

    invalidateQueries([keys.project.detail(projectId).queryKey]);
    setSelectedBlock(null);
  }, [selectedBlock, projectId]);

  const onRemoveBlockActions = useCallback(() => {
    if (!selectedBlock) {
      return;
    }

    removeIncompleteActionsFromBlock(selectedBlock);
    setSelectedBlock(null);
  }, [selectedBlock]);

  const onCloseCreateNewBlockModal = useCallback(() => {
    setTempBlockActionId(null);
    setTempResult(null);
    onCloseCreateBlockModal();
  }, [onCloseCreateBlockModal]);

  const onCreateBlockFromAction = useCallback(
    (actionId: string) => {
      setTempBlockActionId(actionId);
      openCreateBlockModal();
    },
    [openCreateBlockModal],
  );

  const onCreateBlockFromKeyResult = useCallback(
    (keyResultName: string) => {
      setTempResult(keyResultName);
      openCreateBlockModal();
    },
    [openCreateBlockModal],
  );

  const onDragStart = ({ active }: DragStartEvent) => {
    setActiveId(active.id);
    setActiveItemCameFrom(active?.data?.current?.containerId);
    setActiveItemIndex(active?.data?.current?.index);
  };

  const onDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (!project?.projectByPk) {
        return;
      }

      if (active.id == null) {
        setActiveId(null);
        setActiveItemCameFrom('');
        setActiveItemIndex(0);
        return;
      }

      let overId = over?.id;
      if (over?.data?.current?.containerId === 'blocks') {
        // special case when an item is dropped over a block but not over the list of actions
        overId = `block-${overId}`;
      }

      if (overId == null) {
        setActiveItemCameFrom('');
        setActiveItemIndex(0);
        return;
      }

      const activeIndex = active?.data?.current?.index;
      const overIndex = over?.data?.current?.index;

      const activeContainerId = active?.data?.current?.containerId;
      const overContainerId = over?.data?.current?.containerId;

      if (activeItemCameFrom === 'captureList' && overContainerId === 'captureList') {
        updateCaptureListActionsOrder(arrayMove(incompleteActions, activeIndex, overIndex));
      }

      if (
        activeItemCameFrom === 'captureList' &&
        (overContainerId.toString().startsWith('block-') || overContainerId === 'blocks')
      ) {
        handleAddActionToBlock(active.id.toString(), over?.data?.current?.blockId);
      }

      if (
        activeContainerId.toString().startsWith('block-') &&
        overContainerId.startsWith('block-') &&
        activeContainerId === overContainerId
      ) {
        const blockActions = orderBy(
          getIncompleteActionsByBlockId(active?.data?.current?.blockId, project.projectByPk.actions),
          'blockOrder',
          'asc',
        );

        updateBlockActionsOrder(arrayMove(blockActions, activeIndex, overIndex));
      }

      if (
        activeContainerId.toString().startsWith('block-') &&
        overContainerId.startsWith('block-') &&
        activeContainerId !== overContainerId
      ) {
        if (getActionsByBlockId(active?.data?.current?.blockId, project.projectByPk.actions).length === 1) {
          handleDeleteBlockAndAddActionToOtherBlock(
            active?.data?.current?.blockId,
            over?.data?.current?.blockId,
            active.id.toString(),
          );

          setActiveId(null);
          setActiveItemCameFrom('');
          setActiveItemIndex(0);

          return;
        }

        handleAddActionToBlock(active.id.toString(), over?.data?.current?.blockId);
      }

      if (activeItemCameFrom.startsWith('block-') && overContainerId === 'captureList') {
        moveActionFromBlockToCaptureList(
          active.id.toString(),
          active?.data?.current?.blockId,
          getActionsByBlockId(active?.data?.current?.blockId, project.projectByPk.actions).length === 1,
        );
      }

      if (activeItemCameFrom === 'blocks' && overContainerId === 'blocks') {
        const blocks = arrayMove(orderBy(filteredBlocks, 'projectOrder'), activeIndex, overIndex);
        handleUpdateBlockProjectOrder(blocks);
      }

      if (activeItemCameFrom === 'keyResult' && overContainerId === 'keyResult') {
        updateKeyResultsOrder(arrayMove(project.projectByPk.key_results, activeIndex, overIndex));
      }

      setActiveId(null);
      setActiveItemCameFrom('');
      setActiveItemIndex(0);
    },
    [
      project?.projectByPk,
      activeItemCameFrom,
      updateCaptureListActionsOrder,
      incompleteActions,
      handleAddActionToBlock,
      updateBlockActionsOrder,
      handleDeleteBlockAndAddActionToOtherBlock,
      moveActionFromBlockToCaptureList,
      filteredBlocks,
      handleUpdateBlockProjectOrder,
      updateKeyResultsOrder,
    ],
  );

  const onDragCancel = () => {
    setActiveId(null);
    setActiveItemCameFrom('');
    setActiveItemIndex(0);
  };

  const handleRemoveActionFromBlock = useCallback(
    (actionId: string, blockId: string) => {
      if (!project?.projectByPk) {
        return;
      }

      moveActionFromBlockToCaptureList(
        actionId,
        blockId,
        getActionsByBlockId(actionId, project.projectByPk.actions).length === 1,
      );
    },
    [project?.projectByPk, moveActionFromBlockToCaptureList],
  );

  const CaptureListBlocksItemOverlay = useMemo(() => {
    if (!activeId || !project?.projectByPk) {
      return <></>;
    }

    if (activeItemCameFrom === 'blocks') {
      const block = getBlockById(project.projectByPk.blocks, activeId.toString());

      if (!block) {
        return <></>;
      }

      return createPortal(
        <DragOverlay dropAnimation={{ easing: 'ease-in', duration: 200 }}>
          <DnDBlockItem
            actions={getActionsByBlockId(block.id, project.projectByPk.actions)}
            block={block}
            index={0}
            key={block.id}
            onCompleteBlock={() => null}
            onSelectBlock={() => null}
            onUncompleteBlock={() => null}
            openSetUncompletedActionsModal={() => null}
            onClickAddAction={() => null}
            onClickRemoveFromBlock={() => null}
            onDeleteBlock={() => null}
            openEditBlockModal={openEditBlockModal}
            openDuplicateBlockModal={openDuplicatedBlockModal}
          />
        </DragOverlay>,
        document.body,
      );
    }

    const item = getActionById(project.projectByPk.actions, activeId.toString());

    if (!item) {
      return <></>;
    }

    return createPortal(
      <DragOverlay dropAnimation={{ easing: 'ease-in', duration: 200 }}>
        <ActionRow action={item} idx={activeItemIndex + 1} backgroundColor="background-primary" />
      </DragOverlay>,
      document.body,
    );
  }, [activeId, activeItemCameFrom, activeItemIndex, openEditBlockModal, project?.projectByPk]);

  const KeyResultItemOverlay = useMemo(() => {
    if (!activeId || !projectId || !project?.projectByPk || !category) {
      return <></>;
    }

    const item = getKeyResultById(project.projectByPk.key_results, activeId.toString());

    if (!item) {
      return <></>;
    }

    return createPortal(
      <DragOverlay dropAnimation={{ easing: 'ease-in' }}>
        <KeyResultItem
          keyResult={item}
          projectId={projectId}
          index={activeItemIndex + 1}
          onOpen={() => null}
          onClose={() => null}
          onCreateAction={() => null}
          onCreateBlock={() => null}
          projectCategoryColor={category.color}
        />
      </DragOverlay>,
      document.body,
    );
  }, [activeId, activeItemIndex, category, project?.projectByPk, projectId]);

  const starredDuration = useMemo(
    () => getTotalDurationOfStarredActions(project?.projectByPk.actions || []),
    [project?.projectByPk.actions],
  );

  const allActionsDuration = useMemo(
    () => getTotalDurationOfActionsExtendedFormat(project?.projectByPk.actions || []),
    [project?.projectByPk.actions],
  );

  const onClickAddAction = useCallback(
    (blockId: string | null, title?: string) => {
      setActionModalOpen(true, {
        title,
        projectId,
        blockId: blockId ?? undefined,
        categoryId: project?.projectByPk.categoryId,
        readonlyCategory: true,
        showProjectSelector: false,
        withPlaceholder: true,
        onClose: () => {
          setTempBlockId(null);
          setTempResult(null);
          setActionModalOpen(false, undefined);
        },
        location: 'project',
      });
    },
    [setActionModalOpen, project?.projectByPk.categoryId, projectId],
  );

  const onCreateActionFromKeyResult = useCallback(
    (keyResultName: string) => {
      setTempResult(keyResultName);
      onClickAddAction(null, keyResultName);
    },
    [onClickAddAction],
  );

  const onClickEvent = useCallback(
    (eventId: string) => {
      if (!project?.projectByPk) {
        return;
      }

      const action = project?.projectByPk.actions.find((action) => action.id === eventId);

      if (!action) {
        return;
      }

      if (action?.blockId && project?.projectByPk?.blocks) {
        action.block = getBlockById(project.projectByPk.blocks, action.blockId) ?? null;
      }

      getCurrentActionData(action);

      setActionModalOpen(true, {
        readonlyCategory: true,
        showProjectSelector: false,
        onClose: () => {
          setActionModalOpen(false, undefined);
          setTempBlockId(null);
          setTempResult(null);
        },
      });
    },
    [project?.projectByPk, setActionModalOpen, getCurrentActionData],
  );

  const onSubmitResult = useCallback(
    (value: string) => {
      if (!project?.projectByPk) {
        return;
      }

      if (value !== project?.projectByPk.result && value.length > 0) {
        onEditProject({ ...project.projectByPk, result: value });
        return;
      }

      setResult(project?.projectByPk.result);
    },
    [onEditProject, project?.projectByPk],
  );

  const onSubmitPurpose = useCallback(
    (value: string) => {
      if (!project?.projectByPk) {
        return;
      }

      if (value !== project?.projectByPk.purpose && value.length > 0) {
        onEditProject({ ...project.projectByPk, purpose: value });
        return;
      }

      setPurpose(project?.projectByPk.purpose);
    },
    [onEditProject, project?.projectByPk],
  );

  useEffect(() => {
    if (isNil(categories) || isEmpty(project)) {
      return;
    }

    const category = getCategoryById(categories?.category, project?.projectByPk.categoryId);

    if (!category) {
      return;
    }

    updateSelectedCategory(category);
  }, [categories, categories?.category, project, updateSelectedCategory]);

  useEffect(() => {
    setResult(project?.projectByPk.result);
    setPurpose(project?.projectByPk.purpose);
  }, [project?.projectByPk.purpose, project?.projectByPk.result]);

  if (project === null) {
    return (
      <ProjectLayout
        onNavigate={() => navigate(RoutesList.ProjectsPage)}
        onOpenProjectImagesModal={onOpenProjectImagesModal}
        openUpdateProjectModal={openUpdateProjectModal}
        onOpenDeleteProjectModal={onOpenDeleteProjectModal}
        isMobileScreen={isMobileScreen}
      >
        <Flex alignItems="center" justifyContent="center" flexDirection="column" gap={5} marginTop={rem(128)}>
          Project not found, click below to go to project list
          <Button
            width="fit-content"
            height="full"
            onClick={() => navigate(RoutesList.ProjectsPage)}
            paddingInline={rem(8)}
            paddingY={rem(5)}
            size="sm"
            variant="secondary"
          >
            <Text textStyle="small">Project list</Text>
          </Button>
        </Flex>
      </ProjectLayout>
    );
  }

  if (isLoading || !project || !category || !categories) {
    return (
      <ProjectLayout
        onNavigate={() => navigate(RoutesList.ProjectsPage)}
        onOpenProjectImagesModal={onOpenProjectImagesModal}
        openUpdateProjectModal={openUpdateProjectModal}
        onOpenDeleteProjectModal={onOpenDeleteProjectModal}
        isMobileScreen={isMobileScreen}
      >
        <Flex alignItems="center" justifyContent="center" marginTop={rem(128)}>
          <Spinner size="xl" />
        </Flex>
      </ProjectLayout>
    );
  }

  return (
    <DashboardLayout paddingX="0" pageTitle="Project" paddingTop="0">
      <Flex
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
        position="sticky"
        zIndex="banner"
        top="0"
        alignItems="center"
        justifyContent="space-between"
        width="full"
        height={rem(44)}
        padding={`${rem(6)} ${rem(16)}`}
        boxShadow="0px 3px 10px 0px rgba(0,0,0,0.3)"
        backgroundColor="background-tertiary"
      >
        <HStack gap={rem(16)} height="full">
          <Button
            alignItems="center"
            flexDirection="row"
            gap={rem(12)}
            display="flex"
            onClick={() => navigate(RoutesList.ProjectsPage)}
            variant="unstyled"
          >
            <Icon as={IconChevronLeft} boxSize={rem(14)} />
            <Text textStyle="large">Projects</Text>
          </Button>
        </HStack>

        <HStack>
          <Button
            height={rem(32)}
            onClick={onOpenProjectImagesModal}
            paddingInline={rem(8)}
            size="sm"
            variant="secondary"
          >
            <HStack alignItems="center">
              <Icon as={IconMountain} boxSize={rem(14)} color="gray.300" />
              <Text textStyle="small" color="text-primary" fontWeight={900}>
                {!isMobileScreen && `Change `}Cover Image
              </Text>
            </HStack>
          </Button>

          <ThreeDotsProjectButton onClickEdit={openUpdateProjectModal} onClickDelete={onOpenDeleteProjectModal} />
        </HStack>
      </Flex>

      <HeroImage category={category} imageUrl={project?.projectByPk?.imageUrl} fadeIn>
        <Stack justifyContent="center" width="full" minHeight={rem(300)} paddingY={rem(90)}>
          <HStack width="fit-content" padding={rem(8)} borderRadius={4} backgroundColor="gray.50">
            <Flex
              alignItems="center"
              justifyContent="center"
              width={rem(24)}
              height={rem(24)}
              borderRadius="full"
              backgroundColor={category?.color}
            >
              <Icon
                as={CATEGORIES_ICONS?.[category?.icon ?? 'uncategorized']}
                width={rem(14)}
                height={rem(14)}
                color={getColorFromToken(category?.color, undefined, '800')} //
              />
            </Flex>
            <Text textStyle="smallBlack" color="blue.1000" textTransform="uppercase">
              {fixUncategorizedName(category?.name)}
            </Text>
          </HStack>

          <Text
            sx={{
              '@media print': {
                marginTop: 0,
              },
            }}
            textStyle="h2BarSB"
            maxWidth="90%"
            marginTop={rem(40)}
            textTransform="uppercase"
            noOfLines={2}
          >
            {project.projectByPk.name}
          </Text>

          <HStack
            alignItems="stretch"
            flexDirection={{ base: 'column', xl: 'row' }}
            width="full"
            maxWidth={{ base: '45%', '2xl': '40%' }}
            marginTop={rem(48)}
            spacing={rem(32)}
          >
            <ProjectProgress
              color={category?.color ?? ''}
              icon={IconStar}
              title="Starred Actions"
              totalDuration={starredDuration}
              completedActionsPercentage={getPercentageOfCompletedStarredActions(project.projectByPk.actions)}
            />
            <ProjectProgress
              color={category?.color ?? ''}
              icon={IconClock}
              title="All Actions"
              totalDuration={allActionsDuration}
              completedActionsPercentage={getPercentageOfCompletedAllActions(project.projectByPk.actions)}
            />
          </HStack>
        </Stack>
      </HeroImage>

      <Box position="relative" zIndex={6} paddingX={[4, 8, 16]}>
        <DndContainer onDragStart={onDragStart} onDragEnd={onDragEnd} onDragCancel={onDragCancel}>
          <HStack alignItems="stretch" gap={rem(16)}>
            <DetailsCard
              paddingX={rem(16)}
              paddingY={rem(16)}
              color={category?.color ?? ''}
              sectionTitle="Ultimate Result"
              icon={IconFire}
              flex="1"
              minWidth={0}
            >
              <Editable
                textStyle="h5SatBlack"
                overflow="auto"
                width="full"
                height="full"
                defaultValue={result}
                onChange={setResult}
                onSubmit={onSubmitResult}
                placeholder={project.projectByPk.result}
                value={result}
              >
                <EditablePreview width="full" height="full" color="text-tertiary" />
                <EditableTextarea
                  as={TextareaAutosize}
                  _focusVisible={{
                    backgroundColor: 'background-primary',
                    border: 'none',
                    borderRadius: 4,
                  }}
                  resize="none"
                  maxLength={300}
                />
              </Editable>
            </DetailsCard>
            <DetailsCard
              paddingX={rem(16)}
              paddingY={rem(16)}
              color={category?.color ?? ''}
              sectionTitle="Ultimate Purpose"
              icon={IconFire}
              flex="1"
              minWidth={0}
            >
              <Editable
                textStyle="h5SatBlack"
                overflow="auto"
                width="full"
                height="full"
                defaultValue={purpose}
                onChange={setPurpose}
                onSubmit={onSubmitPurpose}
                placeholder={project.projectByPk.purpose}
                value={purpose}
              >
                <EditablePreview width="full" height="full" color="text-tertiary" />
                <EditableTextarea
                  as={TextareaAutosize}
                  _focusVisible={{
                    backgroundColor: 'background-primary',
                    border: 'none',
                    borderRadius: 4,
                  }}
                  resize="none"
                  maxLength={300}
                />
              </Editable>
            </DetailsCard>
          </HStack>

          <Stack
            alignItems="stretch"
            direction={{ base: 'column', xl: 'row' }}
            minHeight={rem(184)}
            marginTop={rem(16)}
            spacing={rem(16)}
          >
            <>
              <KeyResults
                color={category?.color ?? ''}
                keyResults={project.projectByPk.key_results}
                projectId={project.projectByPk.id}
                onCreateAction={(name: string) => onCreateActionFromKeyResult(name)}
                onCreateBlock={(name: string) => onCreateBlockFromKeyResult(name)}
              />
              {KeyResultItemOverlay}
            </>

            <InspirationBoard
              color={category?.color ?? ''}
              data={project?.projectByPk?.inspiration_board_items}
              onOpenImagesModal={onOpenImagesModal}
              onDeleteInspirationBoardItem={onDeleteInspirationBoardItem}
              onOpenImageViewer={onOpenImageFullScreenModal}
              onEditNoteModal={onOpenNoteModal}
              onCreateNoteModal={onOpenNotesModal}
            />
          </Stack>
          <Stack
            alignItems="stretch"
            direction={{ base: 'column', xl: 'row' }}
            minHeight={rem(400)}
            marginTop={rem(16)}
            spacing={rem(16)}
          >
            <DetailsCard
              color={category?.color ?? ''}
              paddingX={rem(16)}
              paddingY={rem(16)}
              gap={0}
              flex="1"
              minWidth={0}
            >
              <Flex alignItems="center" justifyContent="space-between" width="full" marginBottom={rem(20)}>
                <Text textStyle="large">Capture List</Text>
                <AddActionButton
                  onExternalClick={() => onClickAddAction(null)}
                  width={rem(28)}
                  height={rem(28)}
                  variant="secondary"
                />
              </Flex>

              <DroppableContainer id="captureList" payload={{ containerId: 'captureList' }} height="full">
                <Box height="calc(100% - 1.875rem)">
                  <SortableContext items={incompleteActions}>
                    <ActionList
                      emptyListComponent={
                        <EmptyActionList
                          motivational
                          height="auto"
                          title="You have not added any actions yet"
                          message="Ignite your passion and begin crafting those transformative actions either right here or in the 'My Plan' section. The power to shape your destiny is right at your fingertips!"
                        />
                      }
                      isEmpty={isEmpty(incompleteActions)}
                    >
                      {incompleteActions.map((action, index) => (
                        <ActionListItem
                          key={action.id}
                          item={action}
                          index={index}
                          actionModalProps={{
                            readonlyCategory: true,
                            showProjectSelector: false,
                            withPlaceholder: true,
                          }}
                          onClickCreateBlock={onCreateBlockFromAction}
                          dndPayload={{ containerId: 'captureList', index }}
                          backgroundColor="background-primary"
                        />
                      ))}
                    </ActionList>
                  </SortableContext>

                  {(incompleteActions.length > 0 || completedActions.length > 0) && (
                    <ActionListCompletedActions
                      actions={completedActions}
                      onClickCreateBlock={onCreateBlockFromAction}
                    />
                  )}
                </Box>
              </DroppableContainer>
            </DetailsCard>

            <DetailsCard
              color={category?.color ?? ''}
              paddingX={rem(16)}
              paddingY={rem(16)}
              gap={0}
              flex="1"
              minWidth={0}
            >
              <HStack justifyContent="space-between" width="full" minHeight={rem(30)} marginBottom={rem(20)}>
                <Text textStyle="large" marginRight={rem(16)} color="text-primary">
                  RPM Blocks
                </Text>

                <AddActionButton
                  tooltipText="Add a new block"
                  onClick={openCreateBlockModal}
                  variant="secondary"
                  width={rem(28)}
                  height={rem(28)}
                />
              </HStack>
              <SortableContext items={filteredBlocks} strategy={verticalListSortingStrategy}>
                {filteredBlocks.sort(sortBlocksByCompletion).map((block, index) => (
                  <DnDBlockItem
                    sortable
                    actions={getActionsByBlockId(block.id, project?.projectByPk?.actions)}
                    block={block}
                    index={index}
                    key={block.id}
                    onCompleteBlock={onCompleteBlock}
                    openSetUncompletedActionsModal={openSetUncompletedActionsModal}
                    onSelectBlock={() =>
                      setSelectedBlock({
                        ...block,
                        actions: getActionsByBlockId(block.id, project?.projectByPk?.actions),
                      })
                    }
                    onUncompleteBlock={onUncompleteBlock}
                    onClickAddAction={() => onClickAddAction(block.id)}
                    onClickRemoveFromBlock={handleRemoveActionFromBlock}
                    openEditBlockModal={openEditBlockModal}
                    onDeleteBlock={handleDeleteBlock}
                    collapsedByDefault={block.isCompleted}
                    openDuplicateBlockModal={openDuplicatedBlockModal}
                  />
                ))}
              </SortableContext>
              {filteredBlocks?.length === 0 && (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  gap={rem(8)}
                  paddingY={rem(16)}
                >
                  <Text textStyle="smallBlack" color="text-tertiary" textTransform="uppercase">
                    Focus your energy into the areas that matter the most!
                  </Text>
                  <Text textStyle="medium" color="text-primary" textAlign="center">
                    By grouping actions into RPM Blocks, you can combine important actions into discrete and dedicated
                    blocks of time that you can plan.
                  </Text>
                </Flex>
              )}
            </DetailsCard>
          </Stack>

          {CaptureListBlocksItemOverlay}

          <Box marginTop={rem(56)}>
            <PlannerControls />
            <Box width="full" marginTop={rem(8)}>
              <WeeklyCalendar eventsList={formattedEvents} onClickEvent={onClickEvent} />
            </Box>
          </Box>
        </DndContainer>
      </Box>

      {isOpenUpdateProjectModal && (
        <ManageProjectModal
          isOpen={isOpenUpdateProjectModal}
          isLoading={isUpdatingProject}
          onSubmit={onEditProject}
          onDelete={onDeleteProject}
          onClose={onCloseUpdateProjectModal}
          project={project.projectByPk}
        />
      )}

      {isOpenDuplicateBlockModal && !isNull(selectedBlock) && (
        <DuplicateBlockModal
          block={selectedBlock}
          isOpen={isOpenDuplicateBlockModal}
          onClose={onCloseDuplicatedBlockModal}
        />
      )}

      {isOpenCreateBlockModal && (
        <CreateBlockModal
          isOpen={isOpenCreateBlockModal}
          onClose={onCloseCreateNewBlockModal}
          categoryId={project.projectByPk.categoryId}
          actionId={tempBlockActionId ?? ''}
          projectId={project.projectByPk.id}
          result={tempResult ?? ''}
          readyOnlyCategory
          readOnlyProject
        />
      )}

      {isOpenEditBlockModal && !isNull(selectedBlock) && (
        <EditBlockModal
          isOpen
          block={{ ...selectedBlock, project: project.projectByPk }}
          onUpdateBlock={onUpdateBlock}
          onDeleteBlock={handleDeleteBlock}
          onCancel={onCloseEditBlockModal}
        />
      )}

      {isOpenDeleteBlockModal && (
        <DeleteBlockModal isOpen={isOpenDeleteBlockModal} onClose={onCloseDeleteBlockModal} onSubmit={onDeleteBlock} />
      )}

      {isOpenConfirmDeleteBlockModal && (
        <ConfirmDeleteBlockModal
          isOpen={isOpenConfirmDeleteBlockModal}
          onClose={onCloseConfirmDeleteBlockModal}
          onSubmit={onDeleteBlock}
        />
      )}

      {isOpenSetUncompletedActionsModal && selectedBlock && (
        <UncompletedActionsModal
          blockId={selectedBlock.id}
          completedActions={
            getActionsByBlockId(selectedBlock.id, project?.projectByPk?.actions ?? [])?.filter(
              (action) => action.progressStatus === COMPLETE,
            ) ?? []
          }
          isOpen={isOpenSetUncompletedActionsModal}
          onClose={onCloseSetUncompletedActionsModal}
          onDeleteBlock={({ id }) => deleteBlock({ blockId: id, projectId })}
          onCompleteBlockActions={onCompleteBlockActions}
          onRemoveBlockActions={onRemoveBlockActions}
          onCompleteBlock={onCompleteBlock}
        />
      )}

      <CoverImageModal
        isImagesModalOpen={isImagesModalOpen}
        onCloseImagesModal={onCloseImagesModal}
        onInsertInspirationImage={onInsertInspirationImage}
        isSavingImage={isSavingImage}
        setIsSavingImage={setIsSavingImage}
      />

      {/* For project image */}
      <CoverImageModal
        isImagesModalOpen={isProjectImagesModalOpen}
        onCloseImagesModal={onCloseProjectImagesModal}
        onInsertInspirationImage={onProjectImageUploadProject}
        isSavingImage={isUpdatingProjectImage}
        setIsSavingImage={setIsSavingImage}
      />

      <NotesModal
        isNotesModalOpen={isNotesModalOpen}
        onCloseNotesModal={onCloseNoteModal}
        onInsertInspirationNote={onInsertInspirationNote}
        onDeleteInspirationBoardItem={onDeleteInspirationBoardItem}
        selectedNoteData={selectedNoteData}
        onEditNote={onEditNote}
        isEditingNote={isEditingNote}
        isLoadingInsertNote={isLoadingInsertNote}
        iseDeletingInspirationBoardItem={iseDeletingInspirationBoardItem}
      />

      {selectedImage && (
        <ImageViewerModal
          isImageViewerOpen={isImageViewerOpen}
          onCloseImageViewer={onCloseImageFullScreenModal}
          imageUrl={selectedImage}
        />
      )}

      {isDeleteProjectModalOpen && (
        <DialogModal
          cancelText="Cancel"
          confirmText="Delete Project"
          confirmVariant="danger"
          isOpen={isDeleteProjectModalOpen}
          message="Do you really want to remove this project?"
          onCancel={onCloseDeleteProjectModal}
          onConfirm={onDeleteProject}
          title="Confirm"
        />
      )}
    </DashboardLayout>
  );
}

export default ProjectDetail;
