import DialogModal from '@/components/DialogModal';

type Props = {
  isOpen: boolean;
  onCloseConfirmRepeatActionModal: () => void;
  onConfirm: () => void;
  repeatTimes: number;
};

const ConfirmRepeatActionModal = ({ isOpen, repeatTimes, onCloseConfirmRepeatActionModal, onConfirm }: Props) => {
  return (
    <DialogModal
      isOpen={isOpen}
      title={`Are you sure you want to create ${repeatTimes} action${repeatTimes > 1 ? 's' : ''}?`}
      message="By confirming this action, if you change your mind, you will have the option to delete a single action, all linked actions, or from the selected action onwards"
      onCancel={onCloseConfirmRepeatActionModal}
      onConfirm={onConfirm}
      confirmText={`Confirm create ${repeatTimes} action${repeatTimes > 1 ? 's' : ''}`}
    />
  );
};

export default ConfirmRepeatActionModal;
