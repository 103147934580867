import {
  DELETE_CATEGORY_EVENT,
  GET_CATEGORY_EVENTS,
  INSERT_CATEGORY_EVENT,
  UPDATE_CATEGORY_EVENT,
} from '@/gql/categoryEvent';
import {
  CreateCategoryEventPayload,
  CreateCategoryEventResponse,
  DeleteCategoryEventPayload,
  DeleteCategoryEventResponse,
  GetCategoryEventResponse as GetCategoryEventsResponse,
  UpdateCategoryEventPayload,
  UpdateCategoryEventResponse,
} from '@/gql/categoryEvent/types';
import { keys } from '@/gql/global/keys';
import { useCronofySyncEvent } from '@/services/cronofy/hooks';
import { fetchData } from '@/services/graphql';
import { invalidateQueries } from '@/utils/tanStackQuery';
import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useCategoryEvents = (fromDate: Date, toDate: Date) => {
  return useQuery({
    ...keys.categoryEvents.list(fromDate, toDate),
    queryFn: () => fetchData<GetCategoryEventsResponse>(GET_CATEGORY_EVENTS, { fromDate, toDate }),
  });
};

export const useCreateCategoryEvent = () => {
  const queryClient = useQueryClient();
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();
  const toast = useToast();

  return useMutation({
    mutationFn: (payload: CreateCategoryEventPayload) =>
      fetchData<CreateCategoryEventResponse>(INSERT_CATEGORY_EVENT, payload),
    onSuccess: (data) => {
      invalidateQueries([keys.categoryEvents.list._def]);
      cronofySyncEvent({ eventId: data.insertCategoryEventOne.id, eventType: 'CategoryEvent' });
    },
    onError: (error: any, payload) => {
      toast({
        title: error?.response?.errors?.[0]?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      // Rollback the optimistic update, removing the category event id just added
      queryClient.setQueriesData<GetCategoryEventsResponse>(keys.categoryEvents.list._def, (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          categoryEvent: oldData.categoryEvent.filter((item) => item.id !== payload.id),
        };
      });
    },
  });
};

export const useUpdateCategoryEvent = () => {
  const queryClient = useQueryClient();
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();
  const toast = useToast();

  return useMutation({
    mutationFn: (payload: UpdateCategoryEventPayload) =>
      fetchData<UpdateCategoryEventResponse>(UPDATE_CATEGORY_EVENT, payload),
    onMutate: () => {
      // First fetch all the category events time-frames queried
      const prevData = queryClient.getQueriesData<GetCategoryEventsResponse>({
        queryKey: keys.categoryEvents.list._def,
        exact: false,
      });

      return { prevData };
    },
    onSuccess: (data) => {
      invalidateQueries([keys.categoryEvents.list._def]);
      cronofySyncEvent({ eventId: data.updateCategoryEventByPk.id, eventType: 'CategoryEvent' });
    },
    onError: (error: any, payload, context) => {
      toast({
        title: error?.response?.errors?.[0]?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      // Rollback the optimistic update
      if (!context?.prevData) return;
      for (const [key, data] of context.prevData) {
        queryClient.setQueryData(key, data);
      }
    },
  });
};

export const useDeleteCategoryEvent = () => {
  const queryClient = useQueryClient();
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();
  const toast = useToast();

  return useMutation({
    mutationFn: (payload: DeleteCategoryEventPayload) =>
      fetchData<DeleteCategoryEventResponse>(DELETE_CATEGORY_EVENT, payload),
    onMutate: () => {
      // First fetch all the category events time-frames queried
      const prevData = queryClient.getQueriesData<GetCategoryEventsResponse>({
        queryKey: keys.categoryEvents.list._def,
        exact: false,
      });

      return { prevData };
    },
    onSuccess: (data) => {
      invalidateQueries([keys.categoryEvents.list._def]);
      cronofySyncEvent({ eventId: data.deleteCategoryEventByPk.id, eventType: 'CategoryEvent', deleted: true });
    },
    onError: (error: any, payload, context) => {
      toast({
        title: error?.response?.errors?.[0]?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      // Rollback the optimistic update
      if (!context?.prevData) return;
      for (const [key, data] of context.prevData) {
        queryClient.setQueryData(key, data);
      }
    },
  });
};
