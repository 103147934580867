import { create } from 'zustand';

const useSettingsStore = create((set: any) => ({
  showDeclinedEvents: JSON.parse(localStorage.getItem('showDeclinedEvents') || 'false'),
  toggleShowDeclinedEvents: () =>
    set((state: any) => {
      const newValue = !state.showDeclinedEvents;
      localStorage.setItem('showDeclinedEvents', JSON.stringify(newValue));
      return { showDeclinedEvents: newValue };
    }),
}));

export default useSettingsStore;
