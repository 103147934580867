import { keys } from '@/gql/global/keys';
import { APIError } from '@/gql/global/types';
import { GET_USER_INFO, UPDATE_USER_BY_PK } from '@/gql/user';
import { GetUserInfoResponse, UpdateUserByPkPayload, UpdateUserByPkResponse } from '@/gql/user/types';
import { fetchData } from '@/services/graphql';
import { invalidateQueries } from '@/utils/tanStackQuery';
import { trackUser } from '@/utils/tracking';
import { useToast } from '@chakra-ui/react';
import { UseMutationOptions, useMutation, useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

export const SIGN_IN_URL = new URL('/auth/login', import.meta.env.VITE_RRI_AUTH_URL);
SIGN_IN_URL.searchParams.append('client_id', import.meta.env.VITE_RRI_AUTH_CLIENT_ID);
SIGN_IN_URL.searchParams.append('redirect_uri', window.location.origin);
// The values below should not be needed, but unfortunately RRI SSO requires them
// TODO: tell RRI to stop requiring these fields
SIGN_IN_URL.searchParams.append('response_type', 'code');
SIGN_IN_URL.searchParams.append('state', 'some-state');
SIGN_IN_URL.searchParams.append('scope', 'some-scope');

const updateIntervalMillis = parseInt(import.meta.env.VITE_RRI_AUTH_PING_MINUTES) * 60 * 1000;

export type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

class SessionExpiredError extends Error {}

export const useUser = () => {
  const toast = useToast();

  const userQuery = useQuery({
    ...keys.user.current,
    queryFn: async () => {
      const url = new URL('/auth/cookie/userinfo', import.meta.env.VITE_RRI_AUTH_URL);
      const response = await fetch(url, {
        credentials: 'include',
      });

      if ([400, 401].includes(response.status))
        throw new SessionExpiredError('🔒 Session expired, redirecting to sign in page');
      if (!response.ok) throw new Error('Error fetching user info');

      const data = await response.json();

      const result: User = {
        id: data.api_ext_id__c,
        // According to RRI the following fields should be always present,
        // but added some safeguards anyway for extra safety
        email: data.email || '',
        firstName: data.given_name || '',
        lastName: data.family_name || '',
        // The server returns also a "name" field, but RRI said it's just a
        // concatenation of given and family name.
      };

      return result;
    },
    retry: (failureCount, error) => {
      // Don't retry session expired errors
      if (error instanceof SessionExpiredError) return false;
      // In case of other errors retry up to 3 times (React Query default)
      return failureCount < 3;
    },
    onError: (error) => {
      if (error instanceof SessionExpiredError) {
        window.location.assign(SIGN_IN_URL);
      } else {
        toast({
          title: String(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    },

    // Because of limitations of the current auth setup, we need to ping the
    // SSO periodically to keep the session alive, and ensure it's still valid.
    // TODO: improve setup or move session keepalive to the backend
    refetchInterval: updateIntervalMillis,
    refetchIntervalInBackground: true,
    staleTime: updateIntervalMillis,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
  });

  // Start tracking with Amplitude as soon as user email is avaliable
  useEffect(() => {
    const email = userQuery.data?.email;
    if (email) trackUser(email);
  }, [userQuery.data?.email]);

  return userQuery;
};

export const useUpdateUserInfo = (
  options?: Omit<UseMutationOptions<UpdateUserByPkResponse, APIError, UpdateUserByPkPayload>, 'onSuccess'>,
) => {
  return useMutation({
    mutationFn: (payload: UpdateUserByPkPayload) => fetchData<UpdateUserByPkResponse>(UPDATE_USER_BY_PK, payload),
    ...options,

    onSuccess: (_, payload) => {
      invalidateQueries([keys.user.info.queryKey]);
    },
  });
};

export const useUserInfo = () => {
  return useQuery({
    queryKey: keys.user.info.queryKey,
    queryFn: () => fetchData<GetUserInfoResponse>(GET_USER_INFO),
  });
};
