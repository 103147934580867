import { ActionRelation } from '@/constants/action';
import {
  ActionPromiseResponse,
  LeverageRequestStatus,
  UpdatePromiseWithLevereageRequestResponse,
} from '@/gql/actions/types';
import { keys } from '@/gql/global/keys';
import { IconCheckedMark, IconDeclined, IconPending, IconProgress } from '@/theme/icons';

import { endOfTheMonth, startOfTheMonth } from './calendar';
import { invalidateQueries } from './tanStackQuery';

export function getJoinedPromisesByPersonName(
  promises: Omit<ActionPromiseResponse, 'id' | 'personId' | 'actionId'>[],
  type: ActionRelation,
) {
  return promises
    .filter((promise) => promise.kind === type)
    .map((promise) => promise.person.name)
    .join(', ');
}

export function refetchPromise() {
  invalidateQueries([keys.people.all.queryKey]);
}

export function refetchPromiseWithLeverageToPerson() {
  invalidateQueries([keys.people.all.queryKey]);
}

export function refetchPromiseWithLeverageToLeverageCommitted() {
  invalidateQueries([keys.actions.leveragedAndCommitted.queryKey]);
}

export function refetchPromiseWithLevereageToAction(data: UpdatePromiseWithLevereageRequestResponse) {
  const cacheKey = [];
  cacheKey.push(keys.actions.all._ctx.week(data.updatePromiseByPk.action.weeklyPlanId ?? '').queryKey);
  cacheKey.push(keys.actions.all._ctx.categoryManager(data.updatePromiseByPk.action.categoryId ?? '').queryKey);

  if (data.updatePromiseByPk.action.scheduledDate) {
    const startMonth = startOfTheMonth(new Date(data.updatePromiseByPk.action.scheduledDate));
    const endMonth = endOfTheMonth(new Date(data.updatePromiseByPk.action.scheduledDate));
    cacheKey.push(keys.actions.all._ctx.month(startMonth, endMonth).queryKey);
  }

  invalidateQueries(cacheKey);
}

export function refetchPromiseWithLeverageToProjectDetail(data: UpdatePromiseWithLevereageRequestResponse) {
  invalidateQueries([keys.project.detail(data.updatePromiseByPk.action.projectId ?? '').queryKey]);
}

export function getUserIdFromUrl(urlString = window.location.href) {
  // Create a URL object
  const url = new URL(urlString);

  // Access the pathname and split it to get the last part
  const matches = url.toString().match(/\/[\w-]+\/([0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12})/);

  if (matches) {
    const UUID = matches[1];

    return UUID;
  } else {
    return null;
  }
}

export function getLeverageStatusIcon(status: LeverageRequestStatus) {
  switch (status) {
    case 'pending':
      return IconPending;
    case 'accepted':
      return IconProgress;
    case 'completed':
      return IconCheckedMark;
    case 'declined':
      return IconDeclined;

    default:
      console.error('Unexpected leverage request status:', status);
      return undefined;
  }
}
