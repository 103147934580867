import { BlockEvent } from '@/gql/blockEvent/types';
import { CategoryEvent } from '@/gql/categoryEvent/types';

import { Action } from './actions';

export type Duration = {
  starred: string;
  total: string;
};

export enum EventStatus {
  DECLINED = 'declined',
  UNANSWERED = 'needs_action',
}

export type MyEvent = {
  id: string;
  eventId?: string;
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
  description: string;
  duration: string;
  noScheduledTime?: boolean;
  status?: string;
  participationStatus?: EventStatus;
  isDraft?: boolean;
  isExternal?: boolean;
  resource: {
    color: string;
    isExternal: boolean;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null;
    planned: boolean;
    provider?: string;
  };
  dateOfStarring: Date | null;
  isLocked: boolean;
  __metadata?: {
    action?: Action;
    categoryEvent?: CategoryEvent;
    blockEvent?: BlockEvent;
  };
};

export type DayWeek = 'day' | 'week';
