import { memoizedRegenerateCookie } from '@/services/graphql';
import { SIGN_IN_URL } from '@/services/user/hooks';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

const axiosClient = axios.create({
  withCredentials: true,
});

axiosClient.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as ExtendedAxiosRequestConfig;

    if (
      originalRequest &&
      (error.response?.status === 401 || error.response?.status === 403) &&
      !originalRequest._retry
    ) {
      console.log('🔒 Session expired or missing cookie, attempting to regenerate cookie...');
      originalRequest._retry = true;

      const success = await memoizedRegenerateCookie();
      if (success) {
        try {
          return axiosClient.request(originalRequest);
        } catch (retryError) {
          console.error('🔒 Session is still invalid after regenerating the cookie');
          window.location.assign(SIGN_IN_URL);
          return Promise.reject(retryError);
        }
      } else {
        console.log('🔒 Failed to regenerate the cookie, redirecting to sign-in page');
        window.location.assign(SIGN_IN_URL);
        return Promise.reject(new Error('Cookie regeneration failed'));
      }
    }

    return Promise.reject(error);
  },
);

export async function fetchRestData<T = any>(endpoint: string, options: AxiosRequestConfig = {}) {
  try {
    const response = await axiosClient({
      url: endpoint,
      method: options.method || 'GET',
      ...options,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
