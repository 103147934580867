import { ADD_ACTIONS_TO_BLOCK, GET_ONLY_BLOCKS, TOGGLE_BLOCK_COMPLETE } from '@/gql/block';
import {
  AddActionsToBlockPayload,
  AddActionsToBlockResponse,
  GetBlocksResponse,
  UpdateBlockProgressStatusPayload,
  UpdateBlockResponse,
} from '@/gql/block/types';
import { keys } from '@/gql/global/keys';
import { APIError } from '@/gql/global/types';
import { useCategories } from '@/services/categories/hooks';
import { fetchData } from '@/services/graphql';
import { getWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { addCategoryToBlocks } from '@/services/project/hooks';
import { getSelectedDate } from '@/stores/useCalendar';
import { invalidateQueries, refetchQueries } from '@/utils/tanStackQuery';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { pick } from 'lodash';

export const useBlocks = (options?: UseQueryOptions<GetBlocksResponse, APIError>) => {
  const { data: categoriesList, status } = useCategories();

  return useQuery({
    ...keys.blocks.all,
    queryFn: async () => {
      const response = await fetchData<GetBlocksResponse>(GET_ONLY_BLOCKS);

      return {
        block: addCategoryToBlocks(response.block, categoriesList?.category ?? []),
      } satisfies GetBlocksResponse;
    },
    ...options,
    enabled: status === 'success',
  });
};

export const useToggleBlockCompletion = (
  options?: UseMutationOptions<UpdateBlockResponse, APIError, UpdateBlockProgressStatusPayload>,
) => {
  return useMutation({
    mutationFn: ({ id, isCompleted }: UpdateBlockProgressStatusPayload) =>
      fetchData<UpdateBlockResponse>(TOGGLE_BLOCK_COMPLETE, {
        id,
        isCompleted,
      }),

    ...options,
  });
};

export const useAddActionsToBlock = (
  options?: UseMutationOptions<AddActionsToBlockResponse, APIError, AddActionsToBlockPayload>,
) => {
  return useMutation({
    mutationFn: (payload: AddActionsToBlockPayload) =>
      fetchData<AddActionsToBlockResponse>(
        ADD_ACTIONS_TO_BLOCK,
        pick(payload, ['actionIds', 'blockId', 'blockOrders']),
      ),

    onSuccess: (data, { projectId, categoryId }) => {
      const selectedDate = getSelectedDate();
      const weeklyPlanId = getWeeklyPlanId(selectedDate);
      const cacheKeys = [];
      weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(weeklyPlanId).queryKey);
      categoryId && cacheKeys.push(keys.actions.all._ctx.categoryManager(categoryId).queryKey);
      projectId && cacheKeys.push(keys.project.detail(projectId).queryKey);
      invalidateQueries(cacheKeys);
      refetchQueries([keys.blocks.all.queryKey]);
    },

    ...options,
  });
};
