import { DiscussionPoint } from '@/types/discussionPoints';
import { calculateTotalMinutesBasedOnDuration } from '@/utils/action';
import { orderBy } from 'lodash';

export function updateDiscussionPointsOrder(discussionPoints: DiscussionPoint[]): DiscussionPoint[] {
  return discussionPoints.map((action, index) => {
    return {
      ...action,
      order: index + 1,
    };
  });
}

export function removeCurrentFromDiscussionPointsList(discussionPoints: DiscussionPoint[], currentId: string) {
  return discussionPoints.filter((d) => d.id !== currentId);
}

function updateItemsOrders(actions: DiscussionPoint[], nearestIncompleteItemOrder: number) {
  return actions.map((item) => {
    if (item.order > nearestIncompleteItemOrder) {
      return { ...item, order: item.order + 1 };
    }
    return item;
  });
}

export function getIncompleteDiscussionPoints(discussionPoints: DiscussionPoint[]) {
  return discussionPoints.filter((item) => !item.isCompleted);
}

function findNearestIncompleteItem(discussionPoints: DiscussionPoint[], updatedDiscussionPointOrder: number) {
  return discussionPoints.reduce((nearestItem, currentItem) => {
    const currentItemOrder = currentItem.order;
    const nearestItemOrder = nearestItem.order;

    if (currentItemOrder < updatedDiscussionPointOrder && currentItemOrder > nearestItemOrder) {
      return currentItem;
    }
    return nearestItem;
  }, discussionPoints[0]);
}

function findIndexById(discussionPoints: DiscussionPoint[], discussionPointId: string) {
  return discussionPoints.findIndex((item) => item.id === discussionPointId);
}

export function updateOrderOnIncompleteDiscussionPoint(
  discussionPoints: DiscussionPoint[],
  updatedDiscussionPoint: DiscussionPoint,
) {
  const incompleteItems = getIncompleteDiscussionPoints(discussionPoints);

  if (incompleteItems.length === 0) {
    let modifiedItems = updateItemsOrders(
      removeCurrentFromDiscussionPointsList(discussionPoints, updatedDiscussionPoint.id),
      0,
    );
    modifiedItems = orderBy([...modifiedItems, { ...updatedDiscussionPoint, order: 1 }], 'order', 'asc');

    return updateDiscussionPointsOrder(modifiedItems);
  }

  const nearestIncompleteItem = findNearestIncompleteItem(incompleteItems, updatedDiscussionPoint.order);

  const nearestIncompleteItemOrder = nearestIncompleteItem.order ?? 0;

  const modifiedItems = updateItemsOrders(discussionPoints, nearestIncompleteItemOrder);

  const updatedItemIndex = findIndexById(discussionPoints, updatedDiscussionPoint.id);

  modifiedItems[updatedItemIndex] = {
    ...updatedDiscussionPoint,
    order: nearestIncompleteItemOrder + 1,
  };

  return updateDiscussionPointsOrder(orderBy(modifiedItems, 'order', 'asc'));
}

export function calculateDurationOfIncompleteDiscussionPoints(discussionPoints: DiscussionPoint[]) {
  const incompleteDiscussionPoints = getIncompleteDiscussionPoints(discussionPoints);

  return incompleteDiscussionPoints.reduce((acc, discussionPoint) => {
    return acc + calculateTotalMinutesBasedOnDuration(discussionPoint.duration);
  }, 0);
}
