import { CronofyProfile } from '@/gql/cronofy/types';
import { Block } from '@/types/block';
import { ActionEventType, BlockListItemType, ExternalEventType } from '@/types/myPlan';
import { checkIfIsAllDay, externalEventFormatDuration, getEventProvider, providerIcon } from '@/utils/calendar';
import { differenceInMinutes } from 'date-fns';
import { isUndefined } from 'lodash';

export const sortBlocksByCompletion = (blockA: BlockListItemType | Block, blockB: BlockListItemType | Block) => {
  if (blockA.isCompleted && !blockB.isCompleted) return 1;
  if (!blockA.isCompleted && blockB.isCompleted) return -1;
  return 0;
};

export const createExternalActionAdapter = (
  event: ExternalEventType,
  cronofyProfiles?: CronofyProfile[],
): ActionEventType => {
  const allDay = checkIfIsAllDay(event.start, event.end);

  const isExternal = getEventProvider(event, cronofyProfiles);

  return {
    id: event.event_uid,
    actionId: '',
    action: {
      id: event.event_uid,
      title: event.summary,
      scheduledDate: event?.start,
      scheduledTime: null,
      block: null,
      blockId: null,
      allDay,
      duration: externalEventFormatDuration(differenceInMinutes(new Date(event?.end), new Date(event?.start))),
      totalDuration: '',
      starredCategories: '',
      categoryOrder: null,
      blockOrder: null,
      dateOfStarring: null,
      notes: '',
      weeklyPlanId: null,
      timezone: '',
      gmtOffset: '',
      isLocked: false,
      weekly_plan: {
        dateFrom: '',
        dateTo: '',
      },
      event: {
        id: '',
      },
      isExternal: !isUndefined(isExternal),
      icon: !isUndefined(isExternal) ? providerIcon.get(isExternal?.[0]?.provider) : null,
      category: {
        id: 'external',
        color: 'gray.300',
        icon: null,
        name: 'External',
        isExternal: true,
        order: null,
      },
      categoryId: 'external',
      typeName: 'Action',
      modifiedAt: new Date(),
    },
    typeName: 'Event',
  };
};
