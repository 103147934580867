import AddActionButton from '@/components/AddActionButton';
import CaptureList from '@/components/CaptureList';
import { DroppableContainer } from '@/components/DragAndDrop';
import EmptyActionList from '@/components/EmptyActionList';
import MasonryContainer from '@/components/Masonry';
import useCaptureListData from '@/hooks/useCaptureListData';
import { Action } from '@/types/actions';
import { Category } from '@/types/category';
import rem from '@/utils/rem';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useCallback } from 'react';

type CategoryWithActions = {
  completedActions: Action[];
  incompleteActions: Action[];
} & Category;

type EmptyCategoriesProps = {
  draggable?: boolean;
  categories?: Category[];
  viewportRef: React.MutableRefObject<HTMLDivElement | null>;
  columnCount?: number;
  openCreateBlockModal?: () => void;
  setActionModalOpen: (isOpen: boolean, props?: any) => void;
  setTempCategoryId?: (categoryId: string) => void;
  showCompletedActions?: boolean;
};

const EmptyCategories = ({
  categories,
  viewportRef,
  columnCount = 1,
  openCreateBlockModal,
  setActionModalOpen,
  setTempCategoryId,
  draggable,
}: EmptyCategoriesProps) => {
  const { emptyCategories: captureListEmptyCategories } = useCaptureListData();
  const emptyCategories = categories ? categories : captureListEmptyCategories;

  const SingleCategoryContainer = useCallback(
    (category: CategoryWithActions) => (
      <DroppableContainer
        id={category?.id}
        key={category?.id}
        payload={{
          categoryId: category?.id,
        }}
        as={VStack}
        alignItems="flex-start"
        width="full"
        marginBottom={rem(16)}
        borderRadius={rem(16)}
      >
        <CaptureList
          key={`${category.id}`}
          category={category}
          showDurationTimers={false}
          marginBottom={rem(16)}
          draggableCategory={draggable}
        >
          <EmptyActionList
            title="No current actions"
            message="Click the + to add an action to this empty category."
            motivational="small"
          />
          <HStack justifyContent="flex-end" width="100%">
            <AddActionButton
              marginTop={rem(8)}
              backgroundColor={category?.color}
              withActionBlockCreationMenu={!!openCreateBlockModal && !!setTempCategoryId}
              onExternalClick={() => {
                setActionModalOpen(true, { categoryId: category?.id, location: 'empty categories' });
              }}
              onCreateNewBlock={() => {
                !!setTempCategoryId && setTempCategoryId(category.id);
                !!openCreateBlockModal && openCreateBlockModal();
              }}
              color="text-primary"
              textColor="text-primary"
              iconSize={rem(10)}
              minWidth={rem(24)}
              width={rem(24)}
              height={rem(24)}
            />
          </HStack>
        </CaptureList>
      </DroppableContainer>
    ),
    [openCreateBlockModal, setActionModalOpen, setTempCategoryId],
  );

  return (
    <AccordionItem marginTop={rem(24)}>
      <motion.div
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 0.5 }}
        viewport={{ root: viewportRef }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
      >
        <Flex alignItems="center" justifyContent="space-between" minHeight={rem(32)} marginTop={rem(21)}>
          <AccordionButton flex="1" padding="0">
            <Text textStyle="large">Empty Categories</Text>
            <AccordionIcon marginLeft={rem(8)} />
          </AccordionButton>
        </Flex>
      </motion.div>

      <AccordionPanel marginTop={rem(24)} padding="0">
        <MasonryContainer
          dataArray={emptyCategories}
          columnCount={columnCount}
          renderChild={(category) => SingleCategoryContainer(category as CategoryWithActions)}
        />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default EmptyCategories;
