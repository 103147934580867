// UnsplashSearchForm.tsx
import Input from '@/components/Input';
import { IconClear } from '@/theme/icons';
import rem from '@/utils/rem';
import { Box, Button, IconButton, InputGroup, InputRightElement, Stack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { UnsplashPhoto } from '.';

const UnsplashModalFormSchema = z.object({
  query: z.string().trim().min(1, { message: 'Name requires at least 1 character' }).max(100),
});

type FormData = z.infer<typeof UnsplashModalFormSchema>;

interface UnsplashSearchFormProps {
  searchQuery: string;
  onSubmit: (data: FormData) => void;
  setQuery: (query: string) => void;
  fetchRandomUnsplashPhotos: () => Promise<{
    results: UnsplashPhoto[];
    total_pages: number;
  }>;
  isImagesModalOpen: boolean;
}

const UnsplashSearchForm = ({
  searchQuery,
  onSubmit,
  setQuery,
  fetchRandomUnsplashPhotos,
  isImagesModalOpen,
}: UnsplashSearchFormProps) => {
  const {
    control,
    handleSubmit,
    reset,
    setFocus,
    formState: { errors, isValid },
  } = useForm<FormData>({
    defaultValues: {
      query: '',
    },
    resolver: zodResolver(UnsplashModalFormSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (isImagesModalOpen) {
      // Does not work without setTimeout
      // must be removed after focus trap is enabled
      // (lookup the comments in the codebase by "RRI-2918")
      const timeoutId = setTimeout(() => setFocus('query'), 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isImagesModalOpen, setFocus]);

  const handleClearSearch = useCallback(() => {
    reset();
    fetchRandomUnsplashPhotos();
    setQuery('');
  }, [reset]);

  return (
    <Box paddingY={3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" width="full" marginTop={rem(30)} paddingRight={10} paddingLeft={6} spacing={8}>
          <Controller
            name="query"
            control={control}
            render={({ field: { value, onChange, ref } }) => (
              <InputGroup width="full">
                <Input
                  ref={ref}
                  label="Search Unsplash photos by topics or colors*"
                  error={errors.query?.message}
                  onChange={onChange}
                  value={value}
                  formControlWidth="100%"
                  fontWeight="normal"
                  fontSize={rem(12)}
                  autoComplete="off"
                />

                {!!searchQuery.length && (
                  <InputRightElement>
                    <IconButton
                      minWidth={rem(16)}
                      height={rem(16)}
                      padding={rem(3)}
                      border="none"
                      borderRadius="full"
                      aria-label="Clear search"
                      backgroundColor="transparent"
                      icon={<IconClear />}
                      onClick={handleClearSearch}
                    />
                  </InputRightElement>
                )}
              </InputGroup>
            )}
          />
          <Button alignSelf="end" height={rem(50)} isDisabled={!isValid} size="lg" type="submit" variant="primary">
            Search
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default UnsplashSearchForm;
