import AddEmailToPersonModal from '@/components/AddEmailToPersonModal';
import CategoriesMenu from '@/components/CategoriesMenu';
import CreateLeverageRequestModal, { LeverageRequestPromise } from '@/components/CreateLeverageRequestModal';
import DeleteActionModal from '@/components/DeleteActionModal';
import DeleteDuplicatedActionModal from '@/components/DeleteActionModal/DeleteDuplicatedActionModal';
import IconProgressState from '@/components/IconProgressState';
import IconStarred from '@/components/IconStarred';
import Input from '@/components/Input';
import InputDuration from '@/components/InputDuration';
import LeverageRequestModal from '@/components/LeverageRequestModal';
import ManageActionRelations from '@/components/ManageActionRelations';
import ProjectsMenu from '@/components/ProjectsMenu';
import RPMDateTimePickerWithPlaceholder from '@/components/RPMDateTimePicker/RPMDateTimePickerWithPlaceholder';
import StyledModal from '@/components/StyledModal';
import { ActionRelation, INCOMPLETE } from '@/constants/action';
import { DAY, WEEK } from '@/constants/calendar';
import { useSoundEffects } from '@/contexts/SoundEffects';
import { UPDATE_ACTION_ONE } from '@/gql/actions';
import { keys } from '@/gql/global/keys';
import useUpdateBlockAction from '@/hooks/useBlockActions';
import useIsDarkMode from '@/hooks/useIsDarkMode';
import { RoutesList } from '@/routes/routesList';
import {
  useCreateAction,
  useCreatePromises,
  useDeleteAction,
  useDeleteAllActions,
  useDeleteCurrentAndFutureActions,
  useDeletePromisesByAction,
  useReplacePromises,
  useUpdateActionStatus,
  useUpdatePromise,
  useUpdatePromiseRemoveLeverageRequest,
} from '@/services/action/hooks';
import { useCategories } from '@/services/categories/hooks';
import { useCronofySyncEvent } from '@/services/cronofy/hooks';
import { useCreateEvent } from '@/services/events/hooks';
import { fetchData } from '@/services/graphql';
import { queryClient } from '@/services/graphql/queryClient';
import { usePeople } from '@/services/people/hooks';
import useIsDailyPlanPage from '@/services/plans/hooks/useIsDailyPlanPage';
import { getWeeklyPlanId, useLazyWeeklyPlan, useWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { useProjects } from '@/services/project/hooks';
import { useActionModal } from '@/stores/useActionModal';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { IconTrash, IconWarning } from '@/theme/icons';
import { Action, ProgressStatus, UpdateActionResponse } from '@/types/actions';
import { DayWeek } from '@/types/calendar';
import { Category } from '@/types/category';
import { ActionEventType } from '@/types/myPlan';
import { ProjectType } from '@/types/project';
import { refetchActions, useHandleDeleteDuplicatedAction } from '@/utils/action';
import {
  formatDateToString,
  formatStringToDate,
  getGMTOffset,
  localToGivenTimezoneDateToUTC,
  startOfTheWeek,
  utcToLocalDate,
} from '@/utils/calendar';
import { getCategoryById } from '@/utils/category';
import { checkIfOnlySpaces } from '@/utils/index';
import rem from '@/utils/rem';
import {
  EventLocation,
  trackActionCreated,
  trackActionProgress,
  trackActionStarred,
  trackCommitmentMade,
  trackCreateActionStarted,
} from '@/utils/tracking';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  HStack,
  Icon,
  IconButton,
  ModalBody,
  ModalFooter,
  Text,
  Textarea,
  VStack,
  useBoolean,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { format, isSameDay } from 'date-fns';
import { difference, isEmpty, isEqual, isNull, isUndefined } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { ITimezone } from 'react-timezone-select';
import { useTimeout } from 'usehooks-ts';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

const ActionFormSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: 'Action name requires at least 1 character' })
    .max(300, { message: 'Action name must be 300 characters or less' }),
  time: z
    .string()
    .trim()
    .refine((value) => !!value, {
      message: 'Set a minimum duration in the format hh:mm',
    })
    .refine(
      (value) => {
        const [hours, minutes] = value.split(':').map(Number);
        if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
          return false;
        }
        return true;
      },
      {
        message: 'Invalid time format',
      },
    )
    .refine(
      (value) => {
        const [hours, minutes] = value.split(':').map(Number);

        if (hours === 0 && minutes < 5) {
          return false;
        }
        return true;
      },
      {
        message: 'The minimum duration is 5 minutes',
      },
    ),
  notes: z.string().optional(),
  createLeverageRequest: z.boolean().optional(),
});

type ActionFormData = z.infer<typeof ActionFormSchema>;

export type ActionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onCreateAction?: (action: Action) => void;
  onDeleteAction?: (action: Action) => void;
  onCreateEvent?: (event: ActionEventType) => void;
  readonlyCategory?: boolean;
  readonlyTooltipLabel?: string;
  showProjectSelector?: boolean;
  blockId?: string;
  categoryId?: string;
  initialRelation?: ActionRelation;
  personId?: string;
  projectId?: string;
  title?: string;
  readonlyProject?: boolean;
  withPlaceholder?: boolean;
  location?: EventLocation;
  preSelectedDate?: Date;
  defaultActionDuration?: string;
  preSelectedTime?: Date;
};

const ActionModal: FC<ActionModalProps> = ({
  isOpen,
  onClose,
  onCreateAction,
  onDeleteAction,
  onCreateEvent,
  readonlyCategory = false,
  readonlyTooltipLabel = undefined,
  showProjectSelector = true,
  readonlyProject,
  withPlaceholder,
  categoryId,
  initialRelation = ActionRelation.None,
  personId,
  projectId,
  blockId,
  location: eventLocation,
  title,
  preSelectedDate,
  defaultActionDuration = '00:05',
  preSelectedTime,
}) => {
  const toast = useToast();
  const location = useLocation();
  const { data: people } = usePeople();
  const isDailyPlanPage = useIsDailyPlanPage();
  const isDarkMode = useIsDarkMode();
  const createEvent = useCreateEvent({
    onSuccess: ({ insertEventOne }) => onCreateEvent && onCreateEvent(insertEventOne),
  });

  const createAction = useCreateAction();
  const deletePromises = useDeletePromisesByAction();
  const createPromises = useCreatePromises();
  const updatePromise = useUpdatePromise();
  const replacePromises = useReplacePromises();
  const updatePromiseRemoveLeverageRequest = useUpdatePromiseRemoveLeverageRequest();

  const currentActionData = useActionModal((state) => state.action);

  const initialTabIndex = currentActionData?.scheduledDate || preSelectedDate || isDailyPlanPage ? DAY : WEEK;

  const isEditingAction = !!currentActionData?.id;

  const initialized = useRef(false);

  useEffect(() => {
    if (!isEditingAction && !initialized.current && eventLocation) {
      trackCreateActionStarted(eventLocation);
      initialized.current = true;
    }
  }, [isEditingAction, eventLocation]);

  const [isStarred, setIsStarred] = useState<Date | null>(null);
  const [completionStatus, setCompletionStatus] = useState<ProgressStatus>(
    currentActionData?.progressStatus ?? 'incomplete',
  );
  const [isDeleteModal, setIsDeleteModal] = useBoolean();
  const [isDeletedDuplicatedActionModal, setIsDeletedDuplicatedActionModal] = useBoolean();

  const { isOpen: isDatePickerOpen, onOpen: onDatePickerOpen, onClose: onDatePickerClose } = useDisclosure();

  const {
    isOpen: isOpenLeverageRequestModal,
    onOpen: openLeverageRequestModal,
    onClose: onCloseLeverageRequestModal,
  } = useDisclosure();

  const { isOpen: isAddEmailModalOpen, onOpen: onAddEmailModalOpen, onClose: onAddEmailModalClose } = useDisclosure();

  const {
    onGetCurrentActionData: getCurrentActionData,
    setCreateLeverageModalOpen,
    createLeverageModalOpen,
  } = useActionModal();

  const [selectedCategory, setSelectedCategory] = useState<Category>();

  const { data: projects } = useProjects();

  const [selectedProject, setSelectedProject] = useState<ProjectType | null>(null);

  const [visibleProjectSection, setVisibleProjectSection] = useState(false);

  const showProjectSection = () => setVisibleProjectSection(true);

  useTimeout(showProjectSection, 1500);

  const [tabIndex, setTabIndex] = useState<typeof DAY | typeof WEEK>(initialTabIndex);

  const [dateRange, setDateRange] = useState(tabIndex === WEEK);

  const [relation, setRelation] = useState<ActionRelation>(
    isEditingAction ? (currentActionData?.promises?.[0]?.kind ?? ActionRelation.None) : initialRelation,
  );

  const [selectedPeople, setSelectedPeople] = useState<string[]>(() =>
    isEditingAction
      ? currentActionData?.promises?.map((promise) => promise.personId) || []
      : personId
        ? [personId]
        : [],
  );

  const [leverageRequestPromise, setLeverageRequestPromise] = useState<LeverageRequestPromise | null>(null);

  const isUnplanned = !currentActionData?.weeklyPlanId;

  const actionDate = !isUnplanned
    ? formatStringToDate(
        (currentActionData?.scheduledDate
          ? currentActionData?.scheduledDate
          : currentActionData?.weekly_plan?.dateFrom) as string,
      )
    : null;

  const selectedDate = useCalendarMonthlyStore((state) => state.selectedDate);

  const [scheduledDate, setScheduledDate] = useState<Date | null>(
    isEditingAction ? actionDate : withPlaceholder ? null : (preSelectedDate ?? selectedDate),
  );

  const selectedPerson = useMemo(() => {
    if (people?.person && selectedPeople.length) {
      return people.person.find((person) => person.id === selectedPeople[0]);
    }
  }, [people?.person, selectedPeople]);

  const leverageStatus = currentActionData?.promises?.length
    ? currentActionData?.promises[0].leverageRequestStatus
    : undefined;
  // a leverage request can be created if there is no leverageRequestStatus on the current promise
  // or if the user has changed the person to which the promise was previously assigned
  const currentlyLeveragedPersonId = currentActionData?.promises?.[0]?.personId;
  const willBeNewPersonLeveraged =
    currentlyLeveragedPersonId !== selectedPerson?.id && relation === ActionRelation.Leverage;
  const allowCreateLeverageRequest = !!leverageStatus === false || willBeNewPersonLeveraged;

  const hasDateChanged = useMemo(() => {
    if (!scheduledDate) {
      return false;
    }

    return !isSameDay(
      utcToLocalDate(format(scheduledDate, 'yyyy-MM-dd'), null),
      tabIndex === DAY ? scheduledDate : startOfTheWeek(scheduledDate),
    );
  }, [scheduledDate, tabIndex]);

  const currentView = isDailyPlanPage ? DAY : WEEK;
  const hasChangedTabs = tabIndex !== currentView;

  const [scheduledTime, setScheduledTime] = useState<Date | null>(preSelectedTime ?? null);
  const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const updateActionStatus = useUpdateActionStatus();

  const { playCompleteAudio } = useSoundEffects();

  const { data: categoriesList } = useCategories();

  const timeZone = typeof selectedTimezone === 'string' ? selectedTimezone : selectedTimezone.value;

  const scheduledDateWithTimeFormatted =
    scheduledTime && localToGivenTimezoneDateToUTC(scheduledTime, timeZone).scheduledDate;
  const scheduledDateFormatted = scheduledDate && format(scheduledDate, 'yyyy-MM-dd');

  const scheduledDateUpdated =
    tabIndex === DAY ? (scheduledDateWithTimeFormatted ?? scheduledDateFormatted) : currentActionData?.scheduledDate;

  const readOnlyTooltip = currentActionData?.blockId
    ? 'The category of this action cannot be changed because it is included in a block.'
    : readonlyTooltipLabel;

  // always get the weeklyplan for the scheduledDateUpdated if defined, this also means we account
  // for the selected timezone (which could take the date into a different week)
  const weeklyPlanDate =
    scheduledDateUpdated && tabIndex === DAY ? formatStringToDate(scheduledDateUpdated) : (scheduledDate ?? new Date());

  const { data: weeklyPlanId } = useWeeklyPlanId(weeklyPlanDate);
  const { getWeeklyPlan, weeklyPlanQueryResult } = useLazyWeeklyPlan({
    selectedDate: weeklyPlanDate,
  });

  const hasNewWeeklyPlan = !isUndefined(weeklyPlanId) && currentActionData?.weeklyPlanId !== weeklyPlanId;

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setFocus,
    setValue,
    formState: { errors },
  } = useForm<ActionFormData>({
    defaultValues: {
      name: title ?? currentActionData?.title ?? '',
      time: currentActionData?.duration ?? defaultActionDuration,
      notes: currentActionData?.notes ?? '',
      createLeverageRequest: !!leverageStatus,
    },
    resolver: zodResolver(ActionFormSchema),
    mode: 'onChange',
  });

  const actionDataWatcher = watch('name');
  const timeDataWatcher = watch('time');
  const notesDataWatcher = watch('notes');
  const createLeverageRequestWatcher = watch('createLeverageRequest');

  const onHandlePlansIds = useCallback(() => {
    onDatePickerClose();
    if (scheduledDate !== currentActionData?.scheduledDate || hasChangedTabs) {
      getWeeklyPlan();
    }
  }, [onDatePickerClose, scheduledDate, currentActionData?.scheduledDate, hasChangedTabs, getWeeklyPlan]);

  const onSetCategory = useCallback(() => {
    let currentCategory;

    if (isEditingAction) {
      currentCategory = categoriesList?.category?.find((el: Category) => el?.id === currentActionData?.categoryId);
    } else {
      if (categoriesList?.category && categoryId) {
        currentCategory = getCategoryById(categoriesList?.category, categoryId);
      } else {
        currentCategory = currentCategory = categoriesList?.category?.find(
          (category) => category.name.toLowerCase() === 'uncategorized',
        );
      }
    }
    if (currentCategory) {
      setSelectedCategory(currentCategory);
    }
  }, [categoriesList?.category, currentActionData?.categoryId, categoryId, isEditingAction]);

  const onSetProject = useCallback(() => {
    const currentProject = projects?.project.find(
      (el: ProjectType) => el?.id === (isEditingAction ? currentActionData?.projectId : projectId),
    );

    setSelectedProject(currentProject || null);
  }, [projects?.project, isEditingAction, projectId, currentActionData?.projectId]);

  const { mutate: cronofySyncEvent } = useCronofySyncEvent();
  const updateBlockAction = useUpdateBlockAction();

  const onUpdateActionOne = useMutation({
    mutationFn: () =>
      fetchData<UpdateActionResponse>(UPDATE_ACTION_ONE, {
        duration: timeDataWatcher,
        title: actionDataWatcher,
        categoryId: selectedCategory?.id,
        actionId: currentActionData?.id,
        blockId: currentActionData?.projectId !== (selectedProject?.id ?? null) ? null : currentActionData?.blockId,
        projectId: selectedProject?.id,
        blockOrder:
          currentActionData?.projectId !== (selectedProject?.id ?? null) ? null : (currentActionData?.blockOrder ?? 1),
        dateOfStarring: isStarred,
        isLocked: currentActionData?.isLocked ?? false,
        notes: notesDataWatcher,
        weeklyPlanId: scheduledDate === null ? null : hasNewWeeklyPlan ? weeklyPlanId : currentActionData?.weeklyPlanId,
        progressStatus: completionStatus,
        categoryOrder: currentActionData?.categoryOrder,
        scheduledDate:
          scheduledDate === null ? null : hasDateChanged && tabIndex === WEEK ? null : scheduledDateUpdated,
        scheduledTime: scheduledTime ? localToGivenTimezoneDateToUTC(scheduledTime, timeZone).scheduledTime : null,
        timezone: timeZone ?? currentActionData?.timezone,
        globalCommitOrder: currentActionData?.globalCommitOrder,
        globalLeverageOrder: currentActionData?.globalLeverageOrder,
        gmtOffset: getGMTOffset(timeZone) ?? currentActionData?.gmtOffset,
      }),

    onSuccess: async (data) => {
      cronofySyncEvent({ eventId: data.updateActionByPk.id, eventType: 'Action' });

      if (data.updateActionByPk.blockId) {
        updateBlockAction(data.updateActionByPk);
      } else {
        refetchActions(data.updateActionByPk, currentActionData?.weeklyPlanId);
      }

      toast({
        title: 'Action updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      isAddEmailModalOpen && onAddEmailModalClose();

      if (createLeverageRequestWatcher && allowCreateLeverageRequest) {
        setCreateLeverageModalOpen(true);
        onClose();
      } else {
        onCloseModal();
      }
    },
  });

  const deleteAllActions = useDeleteAllActions({
    onSuccess: (data) => {
      if (isNull(currentActionData)) {
        return;
      }

      if (onDeleteAction) {
        data.deleteAction.returning.forEach((element) => {
          onDeleteAction(element);
        });
      }

      refetchActions(currentActionData);

      toast({
        title: 'All actions deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onCloseModal();
      handleCloseDeleteDuplicatedAction();
    },
  });

  const deleteFutureActions = useDeleteCurrentAndFutureActions({
    onSuccess: (data) => {
      if (isNull(currentActionData)) {
        return;
      }

      refetchActions(currentActionData);

      toast({
        title: 'Deleted this and all following actions',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onCloseModal();
      handleCloseDeleteDuplicatedAction();
    },
  });

  const deleteAction = useDeleteAction({
    onSuccess: () => {
      if (isNull(currentActionData)) {
        return;
      }

      refetchActions(currentActionData);

      getCurrentActionData(null);

      if (location.pathname.includes(RoutesList.PeoplePage)) {
        queryClient.refetchQueries(keys.people.all.queryKey);
        queryClient.refetchQueries(keys.actions.leveragedAndCommitted);
      }

      if (currentActionData?.blockId) {
        updateActionStatus.mutate({
          new: null,
          old: {
            id: currentActionData?.id,
            block_id: currentActionData?.blockId,
          },
        });
      }

      toast({
        title: 'Action deleted',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onCloseModal();
      handleCloseDelete();
    },
  });

  const handleCloseDelete = useCallback(() => {
    setIsDeleteModal.off();
  }, [setIsDeleteModal]);

  const handleDelete = useCallback(() => {
    if (!currentActionData?.id) return;
    deleteAction.mutate({ actionId: currentActionData.id });
  }, [deleteAction, currentActionData?.id]);

  const { handleDeleteDuplicatedAction } = useHandleDeleteDuplicatedAction(
    deleteAction,
    deleteFutureActions,
    deleteAllActions,
    currentActionData,
  );

  const handleCloseDeleteDuplicatedAction = useCallback(() => {
    setIsDeletedDuplicatedActionModal.off();
  }, [setIsDeletedDuplicatedActionModal]);

  const handleScheduledTime = useCallback(
    (date: Date | null) => {
      if (!date) {
        setScheduledTime(null);
        return;
      }

      if (scheduledDate) {
        scheduledDate.setHours(date.getHours());
        scheduledDate.setMinutes(date.getMinutes());
      }

      setScheduledTime(scheduledDate);
    },
    [scheduledDate],
  );

  const onChangeTabs = useCallback(
    (index: string | number) => {
      if ((index as DayWeek) === WEEK) {
        setScheduledTime(null);
      }

      if ((index as DayWeek) === DAY && currentActionData?.scheduledDate) {
        setScheduledDate(formatStringToDate(currentActionData?.scheduledDate as string));
      }

      setTabIndex(index as DayWeek);
      setDateRange((index as DayWeek) === WEEK);
    },
    [currentActionData?.scheduledDate],
  );

  const handleUpdatePromises = useCallback(async () => {
    if (currentActionData) {
      const originalPromises = currentActionData.promises;
      const isToCreate = !originalPromises?.length && relation !== ActionRelation.None && !!selectedPeople.length;
      const isToDelete = !!originalPromises?.length && !selectedPeople.length;
      const isToUpdateOne =
        originalPromises?.length === 1 &&
        selectedPeople.length === 1 &&
        (originalPromises[0].kind !== relation || originalPromises[0].personId !== selectedPeople[0]);
      const isNewLeverageRequest =
        originalPromises?.length && !originalPromises[0].leverageRequestStatus && createLeverageRequestWatcher;

      const originalIds = originalPromises?.map((promise) => promise.personId) || [];
      const arrayLeftDiff = difference(originalIds, selectedPeople);
      const arrayRightDiff = difference(selectedPeople, originalIds);

      if (isToCreate) {
        const objects = selectedPeople.map((person) => ({
          id: uuidv4(),
          actionId: currentActionData.id,
          personId: person,
          kind: relation,
        }));

        const promises = await createPromises.mutateAsync({ objects });

        if (relation === ActionRelation.Commit) {
          trackCommitmentMade(promises.insertPromise.returning[0].id);
        }

        setLeverageRequestPromise(promises.insertPromise.returning[0]);
        return promises;
      }

      if (isToDelete) {
        return deletePromises.mutateAsync({ actionId: currentActionData.id });
      }

      if (isToUpdateOne) {
        const promises = await updatePromise.mutateAsync({
          personId: selectedPeople[0],
          kind: relation,
          id: originalPromises[0].id,
        });

        if (relation === ActionRelation.Commit) {
          trackCommitmentMade(promises.updatePromiseByPk.id);
        }

        // remove old leverage request from promise if the person or relation has changed
        if (
          originalPromises[0].leverageRequestStatus &&
          (willBeNewPersonLeveraged || relation !== ActionRelation.Leverage)
        ) {
          await updatePromiseRemoveLeverageRequest.mutateAsync({
            id: originalPromises[0].id,
          });
        }
        setLeverageRequestPromise(promises.updatePromiseByPk);
        return promises;
      }

      if (arrayLeftDiff.length || arrayRightDiff.length) {
        const objects = selectedPeople.map((person) => ({
          id: uuidv4(),
          actionId: currentActionData.id,
          personId: person,
          kind: relation,
        }));
        return replacePromises.mutateAsync({
          actionId: currentActionData.id,
          objects,
        });
      }

      // if the user has decided to send a leverage request after having already chosen a person
      // and saving the action, but is now editing the action
      if (isNewLeverageRequest && !leverageRequestPromise) {
        setLeverageRequestPromise(originalPromises[0]);
      }
    }
  }, [
    createPromises,
    currentActionData,
    deletePromises,
    relation,
    replacePromises,
    selectedPeople,
    updatePromise,
    createLeverageRequestWatcher,
    updatePromiseRemoveLeverageRequest,
    willBeNewPersonLeveraged,
    leverageRequestPromise,
  ]);

  const onSubmitEditAction = useCallback(async () => {
    if (!currentActionData?.id) {
      return;
    }

    if (isStarred) {
      trackActionStarred();
    }

    if (!currentActionData?.event?.id && scheduledDate) {
      await createEvent.mutateAsync({
        id: uuidv4(),
        actionId: currentActionData?.id,
      });
    }
    await handleUpdatePromises();

    onUpdateActionOne.mutate();
  }, [
    createEvent,
    currentActionData?.event?.id,
    currentActionData?.id,
    handleUpdatePromises,
    onUpdateActionOne,
    scheduledDate,
    isStarred,
  ]);

  const onStarringAction = () => {
    setIsStarred((prevState) => {
      return prevState ? null : new Date(Date.now());
    });
  };

  const onClickProgressStatus = (progressStatus: ProgressStatus) => {
    setCompletionStatus(progressStatus);
    currentActionData && trackActionProgress(progressStatus, currentActionData);

    if (progressStatus === 'complete') {
      playCompleteAudio();
    }
  };

  const onCloseModal = useCallback(() => {
    onClose();
    setSelectedProject(null);
    onDatePickerClose();
    getCurrentActionData(null);
    initialized.current = false;
  }, [onClose, onDatePickerClose, getCurrentActionData]);

  const onSubmitCreateAction = useCallback(
    (form: ActionFormData) => {
      let currentWeeklyPlan: string | undefined = undefined;

      if (scheduledDate) {
        currentWeeklyPlan = weeklyPlanId;
      }

      if (!currentWeeklyPlan && scheduledDate) {
        const cachedWeeklyPlanId = getWeeklyPlanId(scheduledDate);

        if (cachedWeeklyPlanId) {
          currentWeeklyPlan = cachedWeeklyPlanId;
        }
      }

      let newScheduledTZDate = scheduledDate && formatDateToString(scheduledDate);

      if (scheduledDate && scheduledTime) {
        newScheduledTZDate = localToGivenTimezoneDateToUTC(scheduledDate, timeZone).scheduledDate;
      }

      createAction.mutate(
        {
          blockId,
          categoryId: selectedCategory?.id ?? '',
          dateOfStarring: isStarred,
          duration: form.time,
          id: uuidv4(),
          isLocked: false,
          notes: form.notes ?? '',
          progressStatus: INCOMPLETE,
          projectId: selectedProject?.id ?? null,
          scheduledDate: tabIndex === DAY ? newScheduledTZDate : null,
          scheduledTime: scheduledTime ? localToGivenTimezoneDateToUTC(scheduledTime, timeZone).scheduledTime : null,
          timezone: timeZone,
          title: form.name,
          weeklyPlanId: scheduledDate ? currentWeeklyPlan : undefined,
          gmtOffset: getGMTOffset(timeZone),
        },
        {
          onSuccess: async (data) => {
            const { id: actionId } = data.insertActionOne;

            if (data.insertActionOne.scheduledDate) {
              const event = await createEvent.mutateAsync({
                id: uuidv4(),
                actionId,
              });

              if (event?.insertEventOne) {
                data.insertActionOne.event = {
                  id: event.insertEventOne.id,
                  typeName: 'Event',
                };
              }
            }

            if (relation !== ActionRelation.None && selectedPeople.length) {
              const objects = selectedPeople.map((person) => ({
                id: uuidv4(),
                actionId,
                personId: person,
                kind: relation,
              }));

              const { insertPromise } = await createPromises.mutateAsync({ objects });
              // we are only supporting one "promise" creation (leverage request) at a time right now
              setLeverageRequestPromise(insertPromise.returning[0]);

              data.insertActionOne.promises = insertPromise.returning;

              if (insertPromise.returning[0].kind === ActionRelation.Commit) {
                trackCommitmentMade(insertPromise.returning[0].id);
              }
            }

            if (isStarred) {
              trackActionStarred();
            }

            refetchActions(data.insertActionOne);

            toast({ title: 'Action created successfully', status: 'success', duration: 3000, isClosable: true });

            if (createLeverageRequestWatcher) {
              onClose();
              setCreateLeverageModalOpen(true);
            } else {
              onCloseModal();
            }
          },
        },
      );
    },
    [
      onClose,
      scheduledDate,
      scheduledTime,
      createAction,
      blockId,
      selectedCategory?.id,
      isStarred,
      selectedProject?.id,
      tabIndex,
      weeklyPlanId,
      relation,
      selectedPeople,
      onCreateAction,
      toast,
      onCloseModal,
      createEvent,
      createPromises,
      createLeverageRequestWatcher,
      setCreateLeverageModalOpen,
      timeZone,
    ],
  );

  const openEmailModalOrSubmit = useCallback(
    (data: ActionFormData) => {
      if (createLeverageRequestWatcher && allowCreateLeverageRequest && !selectedPerson?.email) {
        onAddEmailModalOpen();
      } else if (isEditingAction) {
        onSubmitEditAction();
      } else {
        onSubmitCreateAction(data);
        eventLocation && trackActionCreated(eventLocation);
      }
    },
    [
      onAddEmailModalOpen,
      onSubmitEditAction,
      onSubmitCreateAction,
      isEditingAction,
      createLeverageRequestWatcher,
      selectedPerson?.email,
      allowCreateLeverageRequest,
      eventLocation,
    ],
  );

  const onClickUnplan = useCallback(() => {
    setScheduledDate(null);
    onDatePickerClose();
  }, [onDatePickerClose]);

  useEffect(() => {
    if (!isDatePickerOpen && (hasDateChanged || hasChangedTabs)) {
      getWeeklyPlan();
    }
  }, [getWeeklyPlan, hasDateChanged, hasChangedTabs, isDatePickerOpen]);

  // @TODO: Improve accessibility for modal and datepicker
  // Manually control close on esc for date picker
  useEffect(() => {
    const closeOnEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onCloseModal();
      }
    };
    if (isDatePickerOpen) {
      window.addEventListener('keydown', closeOnEsc, true);
    }
    return () => window.removeEventListener('keydown', closeOnEsc, true);
  }, [getCurrentActionData, isDatePickerOpen, onDatePickerClose, onCloseModal]);

  useEffect(() => {
    if (isEditingAction) {
      if (currentActionData?.title) setValue('name', currentActionData?.title);
      if (currentActionData?.duration)
        setValue('time', currentActionData?.duration?.substring(0, currentActionData?.duration.length - 3));
      if (currentActionData?.dateOfStarring) setIsStarred(currentActionData?.dateOfStarring);
      if (currentActionData?.notes) setValue('notes', currentActionData?.notes);

      if (currentActionData?.scheduledDate && currentActionData?.scheduledTime) {
        const scheduledDateWithTime = utcToLocalDate(
          currentActionData.scheduledDate,
          currentActionData.scheduledTime,
          currentActionData.timezone,
          currentActionData.gmtOffset,
        );
        setTabIndex(DAY);
        setDateRange(false);
        setScheduledTime(scheduledDateWithTime);
        setScheduledDate(scheduledDateWithTime);
      }

      if (currentActionData?.promises) {
        setRelation(currentActionData?.promises[0]?.kind ?? ActionRelation.None);
        setSelectedPeople(() => currentActionData?.promises?.map((promise) => promise.personId) || []);
      }
    }
    return () => {
      reset();
      setIsStarred(null);
    };
  }, [
    reset,
    setValue,
    isEditingAction,
    currentActionData?.title,
    currentActionData?.duration,
    currentActionData?.dateOfStarring,
    currentActionData?.notes,
    currentActionData?.scheduledTime,
    currentActionData?.scheduledDate,
    currentActionData?.promises,
  ]);

  useEffect(() => {
    if (isOpen) {
      onSetCategory();
      onSetProject();
    }
  }, [isOpen, onSetCategory, onSetProject]);

  useEffect(() => {
    if (scheduledDate || !isDatePickerOpen) {
      return;
    }

    if (isDatePickerOpen) {
      setScheduledDate(new Date());
      return;
    }
  }, [isDatePickerOpen, scheduledDate]);

  useEffect(() => {
    if (relation !== ActionRelation.Leverage) {
      setValue('createLeverageRequest', false);
    }
  }, [relation, setValue]);

  const changingLeverage = useMemo(
    () =>
      leverageStatus &&
      (!isEqual([currentlyLeveragedPersonId], selectedPeople) || relation !== ActionRelation.Leverage),
    [leverageStatus, currentlyLeveragedPersonId, selectedPeople, relation],
  );

  useEffect(() => {
    if (
      leverageStatus &&
      !willBeNewPersonLeveraged &&
      selectedPeople?.length > 0 &&
      relation === ActionRelation.Leverage
    ) {
      setValue('createLeverageRequest', true);
    } else if (changingLeverage) {
      setValue('createLeverageRequest', false);
    }
  }, [willBeNewPersonLeveraged, leverageStatus, setValue, selectedPeople, relation, changingLeverage]);

  useEffect(() => {
    if (isOpen) {
      // Does not work without setTimeout
      // must be removed after focus trap is enabled
      // (lookup the comments in the codebase by "RRI-2918")
      const timeoutId = setTimeout(() => setFocus('name'), 100);
      return () => clearTimeout(timeoutId);
    }
  }, [isOpen, setFocus]);

  return (
    <>
      <StyledModal isOpen={isOpen} onClose={onCloseModal}>
        <form onSubmit={handleSubmit(openEmailModalOrSubmit)}>
          <ModalBody>
            <HStack alignItems="flex-start" paddingTop={rem(24)}>
              <Box flex="1" overflow="auto">
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { ref, value, onChange } }) => (
                    <Input
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      error={errors.name?.message}
                      color="text-primary"
                      fontWeight="medium"
                      fontSize={rem(20)}
                      autoComplete="off"
                      _hover={{
                        boxShadow: 'none',
                      }}
                      _focusVisible={{
                        boxShadow: 'none',
                        borderColor: 'cyan.400 !important',
                      }}
                      borderBottomWidth={rem(1)}
                      borderBottomStyle="solid"
                      borderBottomColor="stroke-primary"
                      placeholder="Title"
                      _placeholder={{ color: 'gray.400', fontSize: rem(24) }}
                    />
                  )}
                />
              </Box>
              {isEditingAction && (
                <IconProgressState
                  dimension={rem(40)}
                  state={currentActionData?.progressStatus ?? 'incomplete'}
                  onClick={onClickProgressStatus}
                  position="relative"
                  bottom={rem(-6)}
                />
              )}
            </HStack>

            <FormControl isInvalid={!!errors.notes?.message}>
              <Controller
                name="notes"
                control={control}
                render={({ field: { ref, value, onChange } }) => (
                  <Textarea
                    ref={ref}
                    minHeight={rem(70)}
                    marginTop={rem(30)}
                    padding={rem(16)}
                    border={`${rem(1)} solid`}
                    borderColor="stroke-primary"
                    borderRadius={rem(4)}
                    _hover={{
                      boxShadow: 'none',
                    }}
                    _focusVisible={{
                      borderColor: 'cyan.400',
                    }}
                    _placeholder={{ color: 'gray.400', fontSize: rem(16) }}
                    resize="none"
                    onChange={onChange}
                    placeholder="Add notes"
                    value={value}
                  />
                )}
              />
            </FormControl>

            <Flex justifyContent="space-between" width="full" marginTop={rem(16)}>
              <HStack position="relative" flexWrap="wrap" width="full">
                <CategoriesMenu
                  category={selectedCategory}
                  onSelectCategory={setSelectedCategory}
                  shouldCreateCategory={false}
                  readonly={!isEmpty(currentActionData?.project) || !!currentActionData?.blockId || readonlyCategory}
                  readonlyTooltipLabel={readOnlyTooltip}
                />

                <VStack align="start">
                  <Controller
                    name="time"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <InputDuration value={value} onChange={onChange} variant="bordered" />
                    )}
                  />
                  {errors.time && (
                    <Box as="span" position="absolute" bottom={rem(-25)} color="red.500" fontSize="sm">
                      {errors.time.message}
                    </Box>
                  )}
                </VStack>

                <IconStarred
                  starred={Boolean(isStarred)}
                  onClick={onStarringAction}
                  _hover={{ backgroundColor: 'background-quaternary' }}
                  minWidth={rem(28)}
                  minHeight={rem(28)}
                  height={rem(28)}
                  width={rem(28)}
                  border={`${rem(1)} solid var(--chakra-colors-stroke-primary)`}
                />

                <RPMDateTimePickerWithPlaceholder
                  isDatePickerOpen={isDatePickerOpen}
                  scheduledTime={scheduledTime}
                  tabIndex={tabIndex}
                  onDatePickerOpen={onDatePickerOpen}
                  onClickUnplan={onClickUnplan}
                  dateRange={dateRange}
                  onChangeTabs={onChangeTabs}
                  onSave={onHandlePlansIds}
                  onCancel={onDatePickerClose}
                  scheduledDate={scheduledDate}
                  updateScheduledTime={handleScheduledTime}
                  updateSelectedDate={setScheduledDate}
                  withTimePicker={tabIndex === DAY}
                  selectedTimezone={selectedTimezone}
                  setSelectedTimezone={setSelectedTimezone}
                  variant="input"
                  border={`${rem(1)} solid var(--chakra-colors-stroke-primary)`}
                />
              </HStack>
            </Flex>

            <Divider marginTop={errors.time ? rem(32) : rem(16)} opacity="1" borderColor="whiteAlpha.300" />

            {showProjectSelector && (
              <>
                <HStack gap={rem(16)} marginTop={rem(11)}>
                  <Text textStyle="small">Project</Text>
                  <ProjectsMenu
                    variant="bordered"
                    categoryId={selectedCategory?.id}
                    project={selectedProject}
                    setSelectedProject={setSelectedProject}
                    readonly={readonlyProject}
                  />
                </HStack>

                {currentActionData?.projectId !== (selectedProject?.id ?? null) &&
                  currentActionData?.isLastBlockAction &&
                  visibleProjectSection && (
                    <HStack alignItems="center" marginTop={rem(11)}>
                      <Icon as={IconWarning} boxSize={rem(12)} color="amber.400" />
                      <Text color="amber.400" fontSize="xs" fontWeight="normal">
                        Changing the project will remove this action from the block and consequently delete the block.
                      </Text>
                    </HStack>
                  )}

                <Divider marginTop={rem(11)} opacity="1" borderColor="whiteAlpha.300" />
              </>
            )}

            {currentActionData?.block?.result && (
              <VStack alignItems="flex-start" gap="0">
                <HStack gap={rem(16)} paddingY={rem(12)}>
                  <Text textStyle="small">Block</Text>
                  <Text fontSize="sm">{currentActionData.block.result}</Text>
                </HStack>
                <Divider opacity="1" borderColor="whiteAlpha.300" />
              </VStack>
            )}

            <Flex justifyContent="space-between" width="full" marginTop={rem(11)}>
              <HStack gap={rem(16)}>
                <Text textStyle="small">Leverage/Commit</Text>
                <ManageActionRelations
                  initialRelation={currentActionData?.promises?.[0]?.kind ?? relation}
                  onRelationChange={setRelation}
                  onSelectPeople={setSelectedPeople}
                  selectedPeople={selectedPeople}
                  leverageStatus={
                    !willBeNewPersonLeveraged && relation === ActionRelation.Leverage ? leverageStatus : undefined
                  }
                  onPersonAvatarClick={
                    !willBeNewPersonLeveraged && relation === ActionRelation.Leverage && leverageStatus
                      ? openLeverageRequestModal
                      : undefined
                  }
                  variant="bordered"
                />
              </HStack>

              <Controller
                name="createLeverageRequest"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Checkbox
                    sx={{
                      '.chakra-checkbox__control': {
                        background: isDarkMode ? 'gray.100' : 'gray.200 !important',
                      },
                    }}
                    isChecked={value}
                    isDisabled={
                      !(relation === ActionRelation.Leverage && selectedPeople.length) || !allowCreateLeverageRequest
                    }
                    onChange={onChange}
                    size="sm"
                    variant="round"
                  >
                    <Text as="span" textStyle="large">
                      Create Leverage Request
                    </Text>
                  </Checkbox>
                )}
              />
            </Flex>

            {changingLeverage && (
              <HStack alignItems="center" marginTop={rem(11)}>
                <Icon as={IconWarning} boxSize={rem(12)} color="amber.400" />
                <Text color="amber.400" fontSize="xs" fontWeight="normal">
                  Changing this person will cancel any existing leverage requests and send a request to the new contact.
                </Text>
              </HStack>
            )}

            <Divider marginTop={rem(11)} opacity="1" borderColor="whiteAlpha.300" />
          </ModalBody>
          <ModalFooter justifyContent={isEditingAction ? 'space-between' : 'flex-end'} gap={rem(16)} display="flex">
            {isEditingAction && (
              <IconButton
                aria-label="Delete Action"
                icon={<Icon as={IconTrash} boxSize={rem(20)} />}
                onClick={() =>
                  currentActionData?.seriesId ? setIsDeletedDuplicatedActionModal.on() : setIsDeleteModal.on()
                }
                variant="secondary"
              />
            )}
            <HStack gap={rem(16)}>
              <Button onClick={onCloseModal} size="lg" variant="secondary">
                Cancel
              </Button>
              <Button
                isDisabled={
                  onUpdateActionOne.isLoading ||
                  checkIfOnlySpaces(actionDataWatcher) ||
                  weeklyPlanQueryResult?.isFetching
                }
                size="lg"
                type="submit"
                variant="primary"
              >
                Save Action
              </Button>
            </HStack>
          </ModalFooter>
        </form>
      </StyledModal>

      {isDeleteModal && (
        <DeleteActionModal
          isOpen={isDeleteModal}
          onCloseDeleteActionModal={handleCloseDelete}
          onDeleteAction={handleDelete}
        />
      )}

      {isDeletedDuplicatedActionModal && (
        <DeleteDuplicatedActionModal
          isOpen={isDeletedDuplicatedActionModal}
          onCancel={handleCloseDeleteDuplicatedAction}
          onConfirm={handleDeleteDuplicatedAction}
        />
      )}

      {selectedPerson && (
        <AddEmailToPersonModal
          isOpen={isAddEmailModalOpen}
          onClose={() => onAddEmailModalClose()}
          person={selectedPerson}
          onSuccess={() => {
            if (isEditingAction) {
              onSubmitEditAction();
            } else {
              handleSubmit(onSubmitCreateAction);
            }
          }}
        />
      )}

      {selectedPerson && leverageRequestPromise && (
        <CreateLeverageRequestModal
          isOpen={createLeverageModalOpen}
          onClose={() => {
            setCreateLeverageModalOpen(false);
            getCurrentActionData(null);
            reset();
          }}
          title={actionDataWatcher}
          person={selectedPerson}
          dueDate={scheduledDate}
          tabIndex={tabIndex}
          leverageRequestPromise={leverageRequestPromise}
        />
      )}

      {currentActionData?.promises?.[0] && (
        <LeverageRequestModal
          promise={currentActionData.promises[0]}
          title={currentActionData.title}
          isOpen={isOpenLeverageRequestModal}
          onClose={onCloseLeverageRequestModal}
          onSuccess={() => {
            const newPromises = currentActionData.promises?.map((promise) => ({
              ...promise,
              leverageRequestDueDate: null,
              leverageRequestRepliedAt: null,
              leverageRequestReplyText: null,
              leverageRequestStatus: null,
              leverageRequestText: null,
              leverageRequestedAt: null,
            }));
            getCurrentActionData({ ...currentActionData, promises: newPromises });
            setValue('createLeverageRequest', false);
          }}
        />
      )}
    </>
  );
};

export default ActionModal;
