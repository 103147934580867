import { RoutesList } from '@/routes/routesList';
import { ReactNode, createContext, useContext } from 'react';

export const pagesNew = {
  [RoutesList.HomePage]: 0,
  [RoutesList.Calendar]: 0,
  [RoutesList.WeeklyCapturePage]: 0,
  [RoutesList.MyPlanPage]: 1,
  [RoutesList.MyPlanDailyPage]: 1,
  [RoutesList.SchedulePage]: 1,
  [RoutesList.CelebratePage]: 0,
  [RoutesList.PeoplePage]: 0,
  [RoutesList.ProjectsPage]: 0,
  [RoutesList.CategoryManagerPage]: 0,
};

export interface AnimationDirection {
  pageNavigationEffect:
    | {
        initial: {
          opacity: number;
          x?: string | number;
        };
        in: {
          opacity: number;
          x?: string | number;
        };
        out: {
          opacity: number;
          x?: string | number;
        };
      }
    | undefined;
  setCurrentIndex: (val: number) => void;
}

export interface AnimatePlanPagesProviderProps {
  children: ReactNode;
}

export const AnimatePlanPagesContext = createContext<AnimationDirection>({
  pageNavigationEffect: undefined,
  setCurrentIndex: () => null,
});

export const useAnimatePlanPages = (): AnimationDirection => {
  const context = useContext(AnimatePlanPagesContext);

  if (!context) {
    throw new Error('useAnimatePlanPages should be used within a AnimatePlanPagesProvider.');
  }

  return context;
};
