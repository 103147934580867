import { IconClose } from '@/theme/icons';
import { ImageInfo } from '@/types/inspirations';
import rem from '@/utils/rem';
import { AspectRatio, Button, Flex, Icon, Image } from '@chakra-ui/react';
import { useState } from 'react';

type Props = {
  imageInfo: {
    id: string;
    imageUrl: string | null;
  };
  onDeleteInspirationBoardItem: (itemId: string) => void;
  onOpenImageViewer: (imageInfo: Pick<ImageInfo, 'imageUrl'>) => void;
};

const ImageCard = ({ imageInfo, onDeleteInspirationBoardItem, onOpenImageViewer }: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <AspectRatio overflow="visible !important" maxWidth="100%" height={rem(130)} ratio={1}>
      <Flex
        position="relative"
        overflow="visible !important"
        _hover={{
          cursor: 'pointer',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isHovered && (
          <Button
            position="absolute"
            top={rem(-8)}
            left={rem(-8)}
            alignItems="center"
            justifyContent="center"
            display="flex"
            width={rem(20)}
            minWidth={rem(20)}
            height={rem(20)}
            borderWidth={rem(1)}
            borderStyle="solid"
            borderColor="white.500"
            borderRadius="full"
            cursor="pointer"
            backgroundColor="blue.1000"
            onClick={() => onDeleteInspirationBoardItem(imageInfo?.id)}
            variant="unstyled"
          >
            <Icon as={IconClose} boxSize={rem(12)} />
          </Button>
        )}
        <Image
          boxSize="100%"
          borderRadius="4"
          fit="cover"
          onClick={() =>
            onOpenImageViewer({
              imageUrl: imageInfo?.imageUrl ?? '',
            })
          }
          src={`${imageInfo?.imageUrl ?? ''}${imageInfo?.imageUrl && imageInfo?.imageUrl.includes('images.unsplash.com') ? '&w=200&dpr=2&q=65' : ''}`}
        />
      </Flex>
    </AspectRatio>
  );
};
export default ImageCard;
