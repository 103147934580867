import { ActionRelation } from '@/constants/action';
import { ActionPromiseResponse, LeverageRequestStatus } from '@/gql/actions/types';
import { Category } from '@/types/category';
import { ProjectType } from '@/types/project';
import { FC, SVGProps } from 'react';

import { Block } from './block';

export type ProgressStatus = 'incomplete' | 'in-progress' | 'complete';

export type Action = {
  id: string;
  title: string;
  duration?: string | null;
  totalDuration?: string | null;
  categoryId: string | null;
  blockId: string | null;
  block: Partial<Block> | null;
  icon?: FC<SVGProps<SVGSVGElement>>;
  category: Category;
  starredCategories?: string;
  categoryOrder: number | null;
  blockOrder: number | null;
  dateOfStarring: Date | null;
  progressStatus?: ProgressStatus;
  notes: string;
  scheduledDate: string | null;
  scheduledTime: string | null;
  weeklyPlanId: string | null;
  timezone: string;
  weekly_plan: {
    dateFrom: string;
    dateTo: string;
  } | null;
  event: {
    id: string;
    typeName?: 'Event';
  } | null;
  isLocked: boolean;
  isExternal?: boolean;
  projectId?: string | null;
  projectOrder?: number | null;
  project?: ProjectType | null;
  promises?: ActionPromiseResponse[];
  globalCommitOrder?: number | null;
  globalLeverageOrder?: number | null;
  isLastBlockAction?: boolean;
  modifiedAt: Date;
  gmtOffset: string;
  seriesId?: string;
  createdAt?: Date;
};

export type MutationFunction<TVariables> = {
  mutate: (variables: TVariables) => void;
};

export type DeleteActionVariables = {
  actionId: string;
};

export type DeleteFutureActionsVariables = {
  seriesId: string;
  scheduledDate?: string | null;
};

export type DeleteAllActionsVariables = {
  seriesId: string;
};

export type PlannedAction = Action & {
  scheduledDate: NonNullable<Action['scheduledDate']>;
  event: NonNullable<Action['event']>;
};

export function isPlannedAction(action: Action): action is PlannedAction {
  return action.scheduledDate !== null && action.event !== null;
}

export type ActionResponse = {
  action: Action[];
};

export type ActionsItems = {
  [key: string]: {
    items: Action[];
    category: Category;
    starredCategories: string;
    totalDuration: string;
  };
};

export type ActionUpdate = {
  _set: Partial<Action>;
  where: unknown;
};

export type UpdateActionPayload = {
  actionId: string;
  set: Partial<Action>;
};

export type UpdateActionResponse = {
  updateActionByPk: Action;
};

export type ActionDeletePayload = {
  actionId: string;
};

export type DeleteCurrentAndFutureSeriesPayload = {
  seriesId: string;
  scheduleDate?: Date;
};

export type DeleteCurrentAndFutureSeriesResponse = {
  deleteAction: {
    returning: Action[];
    affectedRows: number;
  };
};

export type DeleteAllSeriesResponse = {
  deleteAction: {
    returning: Action[];
    affectedRows: number;
  };
};

export type ActionUpdateWhere = {
  _set: {
    categoryId?: string;
    blockOrder?: number | null;
    blockId?: string | null;
  };
  where: {
    id: {
      _eq: string;
    };
  };
};

export type ActionPromise = {
  id: string;
  actionId: string;
  personId: string;
  kind: Omit<ActionRelation, ActionRelation.None>;
};

export type ActionWithPeopleIds = {
  promises?: {
    id: string;
    kind: ActionRelation.Commit | ActionRelation.Leverage;
    personId: string;
    person: {
      name: string;
    };
    commitOrder?: number | null | undefined;
    leverageOrder?: number | null | undefined;
    leverageRequestDueDate: Date | null;
    leverageRequestRepliedAt: Date | null;
    leverageRequestReplyText: string | null;
    leverageRequestStatus: LeverageRequestStatus;
    leverageRequestText: string | null;
    leverageRequestedAt: Date | null;
  }[];
} & Action;

export type ActionStatusDeleteResponse = {
  success: boolean;
};

export type ActionData = {
  new: { id: string } | null;
  old: {
    id: string;
    block_id?: string;
    created_by_id?: string;
  } | null;
};
export type ActionStatusDeletePayload = ActionData;
