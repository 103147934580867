import { SIGN_IN_URL, useUpdateUserInfo, useUser, useUserInfo } from '@/services/user/hooks';
import useSettingsStore from '@/stores/useSettings';
import { IconChevron, IconLogout } from '@/theme/icons';
import rem from '@/utils/rem';
import { signOutTracking, trackFeedbackSelected } from '@/utils/tracking';
import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Heading,
  IconButton,
  Link,
  Switch,
  Text,
  VStack,
  useToken,
} from '@chakra-ui/react';
import { ChangeEvent, useCallback, useLayoutEffect, useMemo, useState } from 'react';

import CalendarsSettings from './CalendarsSettings';

type SettingsSidebarProps = {
  isOpen: boolean;
  onClose: () => void;
};

function SettingsSidebar({ isOpen, onClose }: SettingsSidebarProps) {
  const { data: user } = useUser();
  const { data: userInfo } = useUserInfo();

  const { showDeclinedEvents, toggleShowDeclinedEvents } = useSettingsStore();

  const handleShowDeclinedEventsToggle = useCallback(() => {
    toggleShowDeclinedEvents();
  }, [toggleShowDeclinedEvents]);

  const [strokePrimary, backgroundDefault] = useToken('colors', ['stroke-primary', 'background-default']);

  const separatorColor = `color-mix(in srgb, ${strokePrimary} 70%, transparent)`;

  const handleLogout = async () => {
    signOutTracking();
    const url = new URL('/auth/cookie/logout', import.meta.env.VITE_RRI_AUTH_URL);
    await fetch(url, { credentials: 'include' });
    window.location.assign(SIGN_IN_URL);
  };

  const [chatbotWidth, setChatbotWidth] = useState(0);

  useLayoutEffect(() => {
    // When opening this sidebar, Safari prints this error:
    //   Blocked a frame with origin "https://local.rpmplanner.com:3000" from accessing a frame with origin "https://chat.rpmplanner.com". Protocols, domains, and ports must match.
    // The error is not caused by the code in this effect though, but by the focus-lock library used by Chakra UI.
    // I think Chakra tries to access the iframe to not make it focusable while the sidebar is open.
    // Not worth trying to fix the error, it doesn't break anything at all.
    const chatbot = document.getElementById('chat-iframe');
    if (chatbot) setChatbotWidth(chatbot.offsetWidth);
  }, []);

  const playSoundEffects = useMemo(() => userInfo?.me?.soundsEnabled, [userInfo?.me?.soundsEnabled]);
  const updateUserInfo = useUpdateUserInfo();

  const handleSoundEffectsToggle = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!user?.id) {
        return;
      }

      //!TODO: we should change this mutation so we don't need to also pass in toThrive
      updateUserInfo.mutate({
        id: user.id,
        soundsEnabled: !!event.target.checked,
        toThrive: userInfo?.me?.toThrive ?? '',
      });
    },
    [updateUserInfo, user?.id, userInfo?.me],
  );

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right">
      <DrawerOverlay backgroundColor={`color-mix(in srgb, ${backgroundDefault} 80%, transparent)`} />
      <DrawerContent
        flexDirection="column"
        display="flex"
        boxShadow={`${rem(-6)} 0 ${rem(8)} 0 rgba(0, 0, 0, 0.10)`}
        whiteSpace="nowrap"
        backgroundColor="background-default"
      >
        <HStack
          alignItems="center"
          justifyContent="space-between"
          paddingTop={rem(28)}
          paddingBottom={rem(24)}
          color="text-primary"
          paddingX={rem(16)}
        >
          <Heading fontSize="2xl">Settings</Heading>
          <IconButton aria-label="Close" icon={<IconChevron />} onClick={onClose} size="xs" variant="secondary" />
        </HStack>

        <VStack alignItems="stretch" gap={0} overflow="hidden" overflowY="scroll" height="full">
          <VStack alignItems="stretch" paddingX={rem(16)} paddingY={rem(24)}>
            <Heading fontSize="lg">Audio</Heading>

            <HStack justifyContent="space-between" gap={rem(16)}>
              <Text>Play sound effects</Text>
              <Switch isChecked={playSoundEffects} onChange={handleSoundEffectsToggle} />
            </HStack>
            <Heading fontSize="lg">Calendar</Heading>

            <HStack justifyContent="space-between" gap={rem(16)}>
              <Text>Show declined events</Text>
              <Switch isChecked={showDeclinedEvents} onChange={handleShowDeclinedEventsToggle} />
            </HStack>
          </VStack>

          <Heading fontSize="lg" paddingX={rem(16)}>
            My Calendars
          </Heading>
          <CalendarsSettings separatorColor={separatorColor} />

          <Box
            paddingBottom={rem(16)}
            color="text-secondary"
            fontSize="md"
            fontWeight={500}
            lineHeight={rem(24)}
            paddingX={rem(16)}
            paddingY={rem(24)}
          >
            <Box as="dl">
              <Text as="dt">Profile Name</Text>
              <Text as="dd" color="cyan.400">
                {user?.firstName} {user?.lastName}
              </Text>
              <Text as="dt" paddingTop={rem(16)}>
                Email
              </Text>
              <Text as="dd" color="cyan.400">
                {user?.email}
              </Text>
            </Box>

            <Button
              justifyContent="space-between"
              // Add a bit of padding to the button for better hover/focus
              // state, but keep children aligned with the parent border
              // Update: realised that focus state is disabled on tertiary variant
              // but kept this detail anyway because one day must be re-enabled
              width={`calc(100% + ${rem(16)})`}
              marginTop={rem(32)}
              marginLeft={rem(-8)}
              fontSize="inherit"
              fontWeight="inherit"
              onClick={handleLogout}
              paddingX={rem(8)}
              rightIcon={<IconLogout width={rem(20)} height={rem(20)} />}
              variant="tertiary"
            >
              Log out
            </Button>
          </Box>
          <Button
            as={Link}
            flexShrink={0}
            marginBottom={rem(16)}
            href="mailto:rpmfeedback@tonyrobbins.com"
            marginX={rem(16)}
            onClick={() => {
              trackFeedbackSelected();
            }}
            target="_blank"
            variant="secondary"
          >
            Submit Feedback
          </Button>
        </VStack>

        <HStack
          textStyle="small"
          justifyContent="space-evenly"
          gap="none"
          marginTop="auto"
          marginRight={rem(chatbotWidth)}
          color="stroke-primary"
          borderTopWidth={rem(1)}
          borderTopStyle="solid"
          borderTopColor={separatorColor}
          paddingY={rem(16)}
        >
          <Link href="mailto:customerservice@tonyrobbins.com" target="_blank">
            Email Support
          </Link>
          <Link href="https://www.tonyrobbins.com/privacy-policy/" target="blank">
            Privacy Policy
          </Link>
        </HStack>
      </DrawerContent>
    </Drawer>
  );
}

export default SettingsSidebar;
