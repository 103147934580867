import RPMDateTimePickerWithPlaceholder from '@/components/RPMDateTimePicker/RPMDateTimePickerWithPlaceholder';
import StyledModal from '@/components/StyledModal';
import { ActionRelation } from '@/constants/action';
import { DAY, WEEK } from '@/constants/calendar';
import { DUPLICATE_BLOCK_WITH_ACTIONS_AND_EVENTS } from '@/gql/block';
import { DuplicateBlockWithActionsAndEventsResponse } from '@/gql/block/types';
import { fetchData } from '@/services/graphql';
import { queryClient } from '@/services/graphql/queryClient';
import { useWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { Block } from '@/types/block';
import { DayWeek } from '@/types/calendar';
import { formatDateToString } from '@/utils/calendar';
import { trackActionAddedToBlock, trackActionStarred, trackBlockCreated, trackCommitmentMade } from '@/utils/tracking';
import { Button, ModalBody, ModalFooter, useDisclosure, useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

export type ScheduleEventDateTime = { scheduledDate: string; scheduledTime: string };

type ScheduleEventModalProps = {
  isOpen: boolean;
  onClose: () => void;
  block: Block;
};

function DuplicateBlockModal({ isOpen, onClose, block }: ScheduleEventModalProps) {
  const toast = useToast();
  const [tabIndex, setTabIndex] = useState<typeof DAY | typeof WEEK>(DAY);
  const [dateRange, setDateRange] = useState(tabIndex === WEEK);
  const [scheduledTime, setScheduledTime] = useState<Date | null>(null);
  const [scheduledDate, setScheduledDate] = useState<Date | null>(null);
  const [ready, setReady] = useState<boolean>(false);
  const { data: weeklyPlanId } = useWeeklyPlanId(scheduledDate ?? new Date());

  const {
    isOpen: isDatePickerOpen,
    onOpen: onDatePickerOpen,
    onClose: onDatePickerClose,
  } = useDisclosure({ id: 'datepicker' });

  const handleScheduledDate = useCallback(
    (date: Date) => {
      setScheduledDate(date);
    },
    [scheduledTime],
  );

  useEffect(() => {
    if (scheduledDate || !isDatePickerOpen) {
      return;
    }

    if (isDatePickerOpen) {
      setScheduledDate(new Date());
      return;
    }
  }, [isDatePickerOpen, scheduledDate]);

  const onChangeTabs = useCallback((index: string | number) => {
    if ((index as DayWeek) === WEEK) {
      setScheduledTime(null);
    }

    setTabIndex(index as DayWeek);
    setDateRange((index as DayWeek) === WEEK);
  }, []);

  const onClickUnplan = useCallback(() => {
    setScheduledDate(null);
    setReady(false);
    onDatePickerClose();
  }, [onDatePickerClose]);

  const onDatePickerSave = useCallback(() => {
    setReady(true);
    onDatePickerClose();
  }, [onDatePickerClose]);

  const duplicateBlockWithActionsAndEvents = useMutation({
    mutationFn: (variables: { blockId: string; weeklyPlanId: string; scheduledDate: string | null }) =>
      fetchData<DuplicateBlockWithActionsAndEventsResponse>(DUPLICATE_BLOCK_WITH_ACTIONS_AND_EVENTS, variables),

    onSuccess: async (data) => {
      trackBlockCreated();

      data.duplicateBlockWithActionsAndEvents.actions.forEach((action) => {
        if (action.dateOfStarring) {
          trackActionStarred();
        }
        trackActionAddedToBlock(!!action.dateOfStarring);
      });

      data.duplicateBlockWithActionsAndEvents.promises.forEach((promise) => {
        if (promise.kind === ActionRelation.Commit) {
          trackCommitmentMade(promise.id);
        }
      });

      const projectId = data.duplicateBlockWithActionsAndEvents.block.project?.id;

      queryClient.invalidateQueries(['blocks', 'all']);
      queryClient.invalidateQueries(['actions', 'all']);
      queryClient.invalidateQueries(['project', 'detail', projectId]);

      onClose();

      toast({
        title: 'Block duplicated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const handleOnSave = () => {
    duplicateBlockWithActionsAndEvents.mutate({
      blockId: block.id,
      weeklyPlanId: String(weeklyPlanId) ?? undefined,
      scheduledDate: scheduledDate && !dateRange ? formatDateToString(scheduledDate) : null,
    });
  };

  return (
    <>
      <StyledModal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        title="What Day or Week would you like to plan this Block for?"
      >
        <ModalBody>
          <RPMDateTimePickerWithPlaceholder
            scheduledTime={scheduledTime}
            onDatePickerOpen={onDatePickerOpen}
            isDatePickerOpen={isDatePickerOpen}
            onSave={onDatePickerSave}
            onCancel={onClickUnplan}
            placeholder="Choose Date"
            onClickUnplan={onClickUnplan}
            scheduledDate={scheduledDate}
            tabIndex={tabIndex}
            showDayWeekSelector
            onChangeTabs={onChangeTabs}
            dateRange={dateRange}
            updateSelectedDate={handleScheduledDate}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} size="lg" variant="secondary">
            Cancel
          </Button>
          <Button
            isDisabled={(!ready && (!scheduledDate || !weeklyPlanId)) || isDatePickerOpen}
            onClick={handleOnSave}
            size="lg"
            variant="primary"
          >
            Save
          </Button>
        </ModalFooter>
      </StyledModal>
    </>
  );
}

export default DuplicateBlockModal;
