import { DELETE_BLOCK_EVENT, GET_BLOCK_EVENTS, INSERT_BLOCK_EVENT, UPDATE_BLOCK_EVENT } from '@/gql/blockEvent';
import {
  CreateBlockEventPayload,
  CreateBlockEventResponse,
  DeleteBlockEventPayload,
  DeleteBlockEventResponse,
  GetBlockEventResponse as GetBlockEventsResponse,
  UpdateBlockEventPayload,
  UpdateBlockEventResponse,
} from '@/gql/blockEvent/types';
import { keys } from '@/gql/global/keys';
import { useCronofySyncEvent } from '@/services/cronofy/hooks';
import { fetchData } from '@/services/graphql';
import { invalidateQueries } from '@/utils/tanStackQuery';
import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useBlockEvents = (fromDate: Date, toDate: Date) => {
  return useQuery({
    ...keys.blockEvents.list(fromDate, toDate),
    queryFn: () => fetchData<GetBlockEventsResponse>(GET_BLOCK_EVENTS, { fromDate, toDate }),
  });
};

export const useCreateBlockEvent = () => {
  const queryClient = useQueryClient();
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();
  const toast = useToast();

  return useMutation({
    mutationFn: (payload: CreateBlockEventPayload) => fetchData<CreateBlockEventResponse>(INSERT_BLOCK_EVENT, payload),
    onSuccess: (data) => {
      invalidateQueries([keys.blockEvents.list._def]);
      cronofySyncEvent({ eventId: data.insertBlockEventOne.id, eventType: 'BlockEvent' });
    },
    onError: (error: any, payload) => {
      toast({
        title: error?.response?.errors?.[0]?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      // Rollback the optimistic update, removing the block event id just added
      queryClient.setQueriesData<GetBlockEventsResponse>(keys.blockEvents.list._def, (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          blockEvent: oldData.blockEvent.filter((item) => item.id !== payload.id),
        };
      });
    },
  });
};

export const useUpdateBlockEvent = () => {
  const queryClient = useQueryClient();
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();
  const toast = useToast();

  return useMutation({
    mutationFn: (payload: UpdateBlockEventPayload) => fetchData<UpdateBlockEventResponse>(UPDATE_BLOCK_EVENT, payload),
    onMutate: (payload) => {
      // First fetch all the block events timeframes queried
      const prevData = queryClient.getQueriesData<GetBlockEventsResponse>({
        queryKey: keys.blockEvents.list._def,
        exact: false,
      });

      return { prevData };
    },
    onSuccess: (data) => {
      invalidateQueries([keys.blockEvents.list._def]);
      cronofySyncEvent({ eventId: data.updateBlockEventByPk.id, eventType: 'BlockEvent' });
    },
    onError: (error: any, payload, context) => {
      toast({
        title: error?.response?.errors?.[0]?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      // Rollback the optimistic update
      if (!context?.prevData) return;
      for (const [key, data] of context.prevData) {
        queryClient.setQueryData(key, data);
      }
    },
  });
};

export const useDeleteBlockEvent = () => {
  const queryClient = useQueryClient();
  const { mutate: cronofySyncEvent } = useCronofySyncEvent();
  const toast = useToast();

  return useMutation({
    mutationFn: (payload: DeleteBlockEventPayload) => fetchData<DeleteBlockEventResponse>(DELETE_BLOCK_EVENT, payload),
    onMutate: (payload) => {
      // First fetch all the block events timeframes queried
      const prevData = queryClient.getQueriesData<GetBlockEventsResponse>({
        queryKey: keys.blockEvents.list._def,
        exact: false,
      });

      return { prevData };
    },
    onSuccess: (data) => {
      invalidateQueries([keys.blockEvents.list._def]);
      cronofySyncEvent({ eventId: data.deleteBlockEventByPk.id, eventType: 'BlockEvent', deleted: true });
    },
    onError: (error: any, payload, context) => {
      toast({
        title: error?.response?.errors?.[0]?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      // Rollback the optimistic update
      if (!context?.prevData) return;
      for (const [key, data] of context.prevData) {
        queryClient.setQueryData(key, data);
      }
    },
  });
};
