import { IconLaptop, IconSearch } from '@/theme/icons';
import rem from '@/utils/rem';
import { Flex, Tab, TabList, Tabs, Text } from '@chakra-ui/react';

const ImageTabs = ({ tabIndex, setTabIndex }: { tabIndex: number; setTabIndex: (index: number) => void }) => (
  <Tabs zIndex={1} onChange={(index) => setTabIndex(index)}>
    <TabList margin={rem(-8)} borderBottomColor="gray.700">
      <Tab marginRight={6} marginLeft={8} color="cyan.400" paddingX={0}>
        <Flex alignItems="center" gap={2} color="gray.50">
          <IconSearch width={rem(13)} aria-hidden />
          <Text textStyle="large">Unsplash Photos</Text>
        </Flex>
      </Tab>
      <Tab color="cyan.400" paddingX={0}>
        <Flex alignItems="center" gap={2} color="text-primary">
          <IconLaptop width={rem(13)} aria-hidden />
          <Text textStyle="large">From Computer</Text>
        </Flex>
      </Tab>
    </TabList>
  </Tabs>
);

export default ImageTabs;
