import {
  CRONOFY_DISCONNECT_PROFILE,
  CRONOFY_GET_PROFILES,
  CRONOFY_INITIALIZE_CONNECT,
  CRONOFY_SET_INBOUND_SYNC,
  CRONOFY_SET_OUTBOUND_SYNC,
  CRONOFY_SYNC_EVENT,
} from '@/gql/cronofy';
import {
  CronofyDisconnectProfilePayload,
  CronofyGetProfilesResponse,
  CronofySetInboundSyncPayload,
  CronofySetOutboundSyncPayload,
  CronofySyncEventPayload,
} from '@/gql/cronofy/types';
import { keys } from '@/gql/global/keys';
import { fetchData } from '@/services/graphql';
import { invalidateQueries } from '@/utils/tanStackQuery';
import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

export const useCronofyAuthUrl = () =>
  useQuery({
    queryKey: keys.cronofy.authUrl.queryKey,
    queryFn: () => fetchData(CRONOFY_INITIALIZE_CONNECT),
    select: (data) => data?.cronofyInitializeConnect?.auth_url,
  });

export const useCronofyProfiles = () => {
  const queryClient = useQueryClient();

  // Detect if there's at least one profile that Cronofy is still initializing
  const profiles = queryClient.getQueryData<CronofyGetProfilesResponse>(
    keys.cronofy.profiles.queryKey,
  )?.cronofySyncProfiles;
  const initializing = useMemo(() => profiles?.some((profile) => profile.initializing), [profiles]);

  return useQuery({
    ...keys.cronofy.profiles,
    queryFn: () => fetchData<CronofyGetProfilesResponse>(CRONOFY_GET_PROFILES),
    select: (data) => data?.cronofySyncProfiles,
    // If a profile is still syncing, poll faster until it's done
    refetchInterval: initializing ? 5 * 1000 : undefined,
  });
};

export const useCronofyDisconnectProfile = () => {
  return useMutation({
    mutationFn: (payload: CronofyDisconnectProfilePayload) => fetchData(CRONOFY_DISCONNECT_PROFILE, payload),
  });
};

export const useCronofySetInboundSync = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: CronofySetInboundSyncPayload) => fetchData(CRONOFY_SET_INBOUND_SYNC, payload),
    onMutate: () => {
      const prevData = queryClient.getQueryData<CronofyGetProfilesResponse>(keys.cronofy.profiles.queryKey);

      return { prevData };
    },
    onSuccess: () => {
      invalidateQueries([keys.cronofy.profiles.queryKey]);
    },
    onError: (error: any, payload, context) => {
      toast({
        title: error?.response?.errors?.[0]?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      // Rollback the optimistic update
      if (!context?.prevData) return;
      queryClient.setQueryData(keys.cronofy.profiles.queryKey, context.prevData);
    },
  });
};

export const useCronofySetOutboundSync = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: CronofySetOutboundSyncPayload) => fetchData(CRONOFY_SET_OUTBOUND_SYNC, payload),
    onMutate: () => {
      const prevData = queryClient.getQueryData<CronofyGetProfilesResponse>(keys.cronofy.profiles.queryKey);

      return { prevData };
    },
    onSuccess: () => {
      invalidateQueries([keys.cronofy.profiles.queryKey]);
    },
    onError: (error: any, payload, context) => {
      toast({
        title: error?.response?.errors?.[0]?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      // Rollback the optimistic update
      if (!context?.prevData) return;
      queryClient.setQueryData(keys.cronofy.profiles.queryKey, context.prevData);
    },
  });
};

export const useCronofySyncEvent = () => {
  const { data: cronofyProfiles } = useCronofyProfiles();

  // Check if there's at least one Cronofy profile with outbound sync enabled
  const outboundSyncEnabled = useMemo(
    () => cronofyProfiles?.some((profile) => profile.outboundSyncCalendarId !== null) || false,
    [cronofyProfiles],
  );

  return useMutation({
    mutationFn: async (payload: CronofySyncEventPayload) => {
      if (!outboundSyncEnabled) return;
      return await fetchData<void>(CRONOFY_SYNC_EVENT, {
        ...payload,
        // TODO: deleted is optional in GQL, but backend returns error without it, fix on backend
        deleted: Boolean(payload.deleted),
      });
    },
  });
};
