import ActionListCompletedActions from '@/components/ActionListCompletedActions';
import ActionRow from '@/components/ActionRow';
import AddActionButton from '@/components/AddActionButton';
import CaptureList from '@/components/CaptureList';
import CategorySelector from '@/components/CategorySelector';
import DndContainer from '@/components/DndContainer';
import { ActionList, DroppableContainer } from '@/components/DragAndDrop';
import ActionListItem from '@/components/DragAndDrop/ActionListItem';
import EmptyActionList from '@/components/EmptyActionList';
import EmptyCategories from '@/components/EmptyCategories';
import EmptyCategoryCallout from '@/components/EmptyCategoryCallout';
import MasonryContainer from '@/components/Masonry';
import ThreeDotsLaterButton from '@/components/ThreeDotsLaterButton';
import { WEEK } from '@/constants/calendar';
import { useAnimatePlanPages } from '@/contexts/AnimatedPlanPages';
import { actionsKeys } from '@/gql/actions/keys';
import { keys } from '@/gql/global/keys';
import { useActions, useUpdateAction, useUpdateActions } from '@/services/action/hooks';
import { useBlocks } from '@/services/block/hooks';
import { useCategories } from '@/services/categories/hooks';
import { queryClient } from '@/services/graphql/queryClient';
import { useDeleteBlock, useUpdateActionsCategoryOrder } from '@/services/myPlan/hooks';
import useWeeklyPlan, { getWeeklyPlanId, useWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { useActionModal } from '@/stores/useActionModal';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { IconCheckmarkSingle, IconClose } from '@/theme/icons';
import { Action, ActionUpdate } from '@/types/actions';
import { Category } from '@/types/category';
import {
  getActionById,
  getActionsByBlockId,
  getActionsByCategoryId,
  getCompletedActions,
  getIncompleteActions,
  getTotalDurationOfIncompleteActions,
  getTotalDurationOfPreferredIncompleteActions,
  getWeeklyActions,
} from '@/utils/action';
import { getBlockById } from '@/utils/block';
import { formatDateToString, isFutureWeek, isPastWeek, utcToLocalDate } from '@/utils/calendar';
import { isUncategorized } from '@/utils/index';
import { pageTransition } from '@/utils/pageAnimation';
import rem from '@/utils/rem';
import { invalidateQueries } from '@/utils/tanStackQuery';
import { trackBulkActionCompleted, trackMarkAllAsCompleted, trackMoveAllToNextInterval } from '@/utils/tracking';
import {
  Accordion,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Spinner,
  Text,
  VStack,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { DragEndEvent, DragOverlay, DragStartEvent, UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { addWeeks, isThisWeek } from 'date-fns';
import { motion } from 'framer-motion';
import { isEmpty, orderBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

type CategoryWithActions = {
  completedActions: Action[];
  incompleteActions: Action[];
} & Category;

function Capture() {
  const toast = useToast();
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const columnCount = useBreakpointValue(
    {
      base: 1,
      xl: 2,
    },
    {
      fallback: 'xl',
    },
  );

  const selectedDate = useCalendarMonthlyStore((state) => state.selectedDate);
  const { data: weeklyPlanId } = useWeeklyPlanId(selectedDate);

  const { setActionModalOpen } = useActionModal();

  const { pageNavigationEffect } = useAnimatePlanPages();

  const { data: categoriesList, isLoading: isLoadingCategories } = useCategories();

  const { data: actions, isLoading: isLoadingActions } = useActions();
  const { data: blocks } = useBlocks();

  const deleteBlock = useDeleteBlock();

  const updateActionsCategoryOrder = useUpdateActionsCategoryOrder();

  const updateActionCategory = useUpdateAction({
    onError: (error): void => {
      toast({
        title: error?.response?.errors?.[0]?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    },
  });

  const onDragStart = ({ active }: DragStartEvent) => {
    setActiveId(active.id);
    setActiveItemIndex(active?.data?.current?.index);
  };

  const updateCaptureListActionsOrder = useCallback(
    (actions: Action[]) => {
      const mutationPayload = actions.map((action, index) => ({
        _set: {
          categoryOrder: index + 1,
        },
        where: {
          id: {
            _eq: action.id,
          },
        },
      }));

      updateActionsCategoryOrder.mutate(mutationPayload);
    },
    [updateActionsCategoryOrder],
  );

  const handleActionNewCategory = useCallback(
    (actionId: string, categoryId: string, blockId: string | null) => {
      const actionsByCategory = getActionsByCategoryId(actions?.action ?? [], categoryId);

      let shouldDeleteBlock = false;

      if (blockId) {
        const blockActions = getActionsByBlockId(blockId, actions?.action ?? []);

        if (blockActions?.length === 1) {
          shouldDeleteBlock = true;
        }
      }

      updateActionCategory.mutate({
        actionId,
        set: { categoryId, categoryOrder: actionsByCategory.length + 1, blockId: null, blockOrder: null },
      });

      if (shouldDeleteBlock && blockId) {
        deleteBlock.mutate({ blockId });
      }
    },
    [actions?.action, deleteBlock, updateActionCategory],
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    const overId = over?.id;

    if (overId == null) {
      setActiveId(null);
      setActiveItemIndex(0);
      return;
    }

    const activeIndex = active?.data?.current?.index;
    const overIndex = over?.data?.current?.index;

    const activeContainerId = active?.data?.current?.containerId;
    const overContainerId = over?.data?.current?.containerId;

    const activeCategoryId = active?.data?.current?.categoryId;
    const overCategoryId = over?.data?.current?.categoryId;

    const activeBlockId = active?.data?.current?.blockId;

    const activeProjectId = active?.data?.current?.projectId;
    const overProjectId = over?.data?.current?.projectId;

    const notNullProjectId = ![null, undefined].includes(activeProjectId, overProjectId);

    if (notNullProjectId && activeProjectId !== overProjectId && activeCategoryId !== overCategoryId) {
      setActiveItemIndex(0);

      return;
    }

    if (activeContainerId === overContainerId) {
      const actions = getIncompleteActions(getSortedActionsByCategoryId(active?.data?.current?.categoryId));
      updateCaptureListActionsOrder(arrayMove(actions, activeIndex, overIndex));
    }

    if (activeContainerId !== overContainerId) {
      handleActionNewCategory(active.id.toString(), overCategoryId, activeBlockId);
    }

    setActiveId(null);
    setActiveItemIndex(0);
  };

  const onDragCancel = () => {
    setActiveId(null);
    setActiveItemIndex(0);
  };

  const getSortedActionsByCategoryId = useCallback(
    (categoryId: string) => {
      if (!actions?.action) {
        return [];
      }

      const categoryActions = getActionsByCategoryId(actions.action, categoryId);

      let response = getWeeklyActions(categoryActions, weeklyPlanId ?? '');

      response = response.map((action) => ({
        ...action,
        block: action?.blockId && blocks?.block ? (getBlockById(blocks.block, action.blockId) ?? null) : null,
      }));

      return orderBy(response, 'categoryOrder', 'asc');
    },
    [actions?.action, weeklyPlanId, blocks?.block],
  );

  const onClickAddAction = useCallback(
    (category: Category) => {
      setActionModalOpen(true, {
        categoryId: category?.id,
        location: 'capture',
      });
    },
    [setActionModalOpen],
  );

  const allCategories = useMemo(() => {
    if (!categoriesList?.category) {
      return [];
    }

    return categoriesList?.category.map((category) => {
      const actions = getSortedActionsByCategoryId(category.id);

      return {
        ...category,
        incompleteActions: getIncompleteActions(actions),
        completedActions: getCompletedActions(actions, 'modifiedAt'),
        blocks: [], // required for category selector
      };
    });
  }, [categoriesList?.category, getSortedActionsByCategoryId]);

  const { notEmptyCategories, emptyCategories } = useMemo(() => {
    const notEmptyCategories = allCategories.filter(
      (category) =>
        isUncategorized(category) || !isEmpty(category.incompleteActions) || !isEmpty(category.completedActions),
    );

    const emptyCategories = allCategories.filter(
      (category) =>
        !isUncategorized(category) && isEmpty(category.incompleteActions) && isEmpty(category.completedActions),
    );

    return { notEmptyCategories, emptyCategories };
  }, [allCategories]);

  const actionsContainerRef = useRef<HTMLDivElement | null>(null);

  const ItemOverlay = useMemo(() => {
    if (!activeId || !actions?.action) {
      return <></>;
    }

    const item = getActionById(actions.action, activeId.toString());

    if (!item) {
      return <></>;
    }

    return createPortal(
      <DragOverlay dropAnimation={{ easing: 'ease-in' }}>
        <ActionRow action={item} idx={activeItemIndex + 1} />
      </DragOverlay>,
      document.body,
    );
  }, [actions?.action, activeId, activeItemIndex]);

  const [doItLaterId, setDoItLaterId] = useState<string>();
  const [showConfirmMessage, setShowConfirmMessage] = useState<'move' | 'complete' | false>(false);
  const [isLoading, setIsLoading] = useState(false);

  const doItLater = useMemo(
    () => notEmptyCategories.find((cat) => cat.id === doItLaterId),
    [notEmptyCategories, doItLaterId],
  );

  // add next weeks weeklyPlanId into the cache incase it's needed for the "do it later" functionality
  useWeeklyPlan({
    selectedDate: isPastWeek(selectedDate) ? new Date() : addWeeks(selectedDate, 1),
  });

  const buttonLabel = useMemo(() => {
    return isPastWeek(selectedDate) ? 'This Week' : 'Next Week';
  }, [selectedDate]);

  const updateActions = useUpdateActions();

  const onHandleMarkAllCompleted = useCallback(async () => {
    if (!doItLater) return;

    setIsLoading(true);

    const actionUpdates: ActionUpdate[] = doItLater.incompleteActions.map((action) => ({
      _set: {
        progressStatus: 'complete',
      },
      where: {
        id: {
          _eq: action.id,
        },
      },
    }));

    doItLater.incompleteActions.forEach((action) => trackBulkActionCompleted(action));

    await updateActions.mutateAsync(actionUpdates);

    showConfirmMessage && setShowConfirmMessage(false);
    setIsLoading(false);
    setDoItLaterId(undefined);

    // !TODO: need to investigate if/why this is needed (inherited code from old implementation)
    queryClient.invalidateQueries({
      queryKey: [actionsKeys.actionsAndBlock, selectedDate],
      type: 'all',
    });

    trackMarkAllAsCompleted(WEEK);
  }, [selectedDate, updateActions, showConfirmMessage, doItLater]);

  const actionMassiveNextUpdates = useCallback(
    (scheduledDate: string | null, scheduledTime: string | null, timeZone?: string, gmtOffset?: string) => {
      const whichWeek = isPastWeek(selectedDate) ? new Date() : addWeeks(selectedDate, 1);

      const cachedWeeklyPlanId = getWeeklyPlanId(whichWeek);

      return {
        weeklyPlanId: cachedWeeklyPlanId,
        scheduledDate:
          scheduledDate && isThisWeek(utcToLocalDate(scheduledDate, scheduledTime, timeZone, gmtOffset))
            ? formatDateToString(addWeeks(utcToLocalDate(scheduledDate, scheduledTime, timeZone, gmtOffset), 1))
            : null,
        scheduledTime: null,
      };
    },
    [selectedDate],
  );

  const onHandleMoveAllNext = useCallback(async () => {
    if (!doItLater) return;

    setIsLoading(true);
    const actionUpdates: ActionUpdate[] = doItLater.incompleteActions.map((action) => ({
      _set: actionMassiveNextUpdates(action?.scheduledDate, action?.scheduledTime, action?.timezone, action?.gmtOffset),
      where: {
        id: {
          _eq: action.id,
        },
        progressStatus: {
          _neq: 'complete',
        },
      },
    }));

    await updateActions.mutateAsync(actionUpdates);

    invalidateQueries([keys.actions.all._ctx.week(weeklyPlanId).queryKey]);

    showConfirmMessage && setShowConfirmMessage(false);
    setIsLoading(false);
    setDoItLaterId(undefined);

    trackMoveAllToNextInterval(WEEK);
  }, [actionMassiveNextUpdates, updateActions, showConfirmMessage, doItLater]);

  // reset do it later if date changed
  useEffect(() => {
    setDoItLaterId(undefined);
  }, [selectedDate]);

  // reset do it later if all actions are marked complete or moved
  useEffect(() => {
    if (doItLaterId && (!doItLater?.incompleteActions || doItLater?.incompleteActions.length === 0)) {
      setDoItLaterId(undefined);
    }
  }, [doItLater?.incompleteActions, doItLaterId]);

  const SingleCategoryContainer = useCallback(
    (category: CategoryWithActions) => {
      if (!category) return <></>;
      const showDoItLater = !isFutureWeek(selectedDate) && !isEmpty(category?.incompleteActions);

      return (
        <DroppableContainer
          id={category?.id}
          key={category?.id}
          payload={{
            categoryId: category?.id,
          }}
          as={VStack}
          alignItems="flex-start"
          width="full"
          marginBottom={rem(16)}
          borderRadius={rem(16)}
        >
          <CaptureList
            category={category}
            className={`${category?.id}-completed-actions`}
            totalDurationOfIncompleteActions={getTotalDurationOfIncompleteActions(category?.incompleteActions)}
            totalDurationOfPreferredIncompleteActions={getTotalDurationOfPreferredIncompleteActions(
              category?.incompleteActions,
            )}
            threeDotsButton={showDoItLater && <ThreeDotsLaterButton onClick={() => setDoItLaterId(category.id)} />}
          >
            <SortableContext items={category?.incompleteActions}>
              <ActionList
                width="full"
                emptyListComponent={
                  <EmptyActionList
                    title={
                      isEmpty(category?.completedActions)
                        ? 'No current actions'
                        : 'All actions in this category have been completed'
                    }
                    message={
                      isEmpty(category?.completedActions)
                        ? 'Click the + to add an action to this empty category.'
                        : undefined
                    }
                    motivational="small"
                  />
                }
                isEmpty={isEmpty(category?.incompleteActions)}
              >
                {category?.incompleteActions.map((action, index) => (
                  <ActionListItem
                    key={action.id}
                    item={action}
                    index={index}
                    dndPayload={{
                      containerId: `captureList-${category?.id}`,
                      categoryId: action.categoryId,
                      category,
                      index,
                      projectId: action?.projectId,
                      blockId: action?.blockId,
                    }}
                    isCompleteMode={category.id === doItLaterId}
                  />
                ))}
              </ActionList>
              {!(category.id === doItLaterId) && (
                <>
                  <HStack justifyContent="flex-end" width="100%">
                    <AddActionButton
                      marginTop={rem(10)}
                      padding={`${rem(6)} ${rem(7)}`}
                      borderWidth={rem(1)}
                      borderStyle="solid"
                      borderColor="gray.700"
                      borderRadius="4"
                      marginBottom={rem(6)}
                      _hover={{ backgroundColor: 'transparent' }}
                      color="text-primary"
                      fontSize={rem(14)}
                      textStyle="smallBlack"
                      onExternalClick={() => onClickAddAction(category)}
                      iconSize={rem(10)}
                      minWidth={rem(24)}
                      width={rem(24)}
                      height={rem(24)}
                    />
                  </HStack>
                  <ActionListCompletedActions
                    className={`${category?.id}-completed-actions`}
                    actions={category.completedActions}
                  />
                </>
              )}
            </SortableContext>
            {category.id === doItLaterId && (
              <HStack gap={rem(16)} marginTop={rem(16)}>
                {showConfirmMessage ? (
                  <>
                    <Text textStyle="medium">
                      {showConfirmMessage === 'complete' &&
                        `Are you sure you want to complete all of the current actions in this category?`}
                      {showConfirmMessage === 'move' &&
                        `Are you sure you want to move all of the current actions in this category to ${buttonLabel.toLowerCase()}?`}
                    </Text>
                    <HStack height={rem(48)} marginLeft="auto">
                      <IconButton
                        aria-label="No"
                        icon={<IconClose height={rem(24)} width={rem(24)} />}
                        onClick={() => setShowConfirmMessage(false)}
                        variant="tertiary"
                      />
                      <IconButton
                        aria-label="Yes"
                        icon={<IconCheckmarkSingle height={rem(24)} width={rem(24)} />}
                        onClick={() =>
                          showConfirmMessage === 'complete' ? onHandleMarkAllCompleted() : onHandleMoveAllNext()
                        }
                        variant="tertiary"
                      />
                    </HStack>
                  </>
                ) : (
                  <>
                    <Button onClick={() => setDoItLaterId(undefined)} size="sm" variant="secondary">
                      Cancel
                    </Button>
                    <Button
                      marginLeft="auto"
                      isLoading={!weeklyPlanId || isLoading}
                      onClick={() => setShowConfirmMessage('move')}
                      size="sm"
                      variant="primary"
                    >
                      Move All To {buttonLabel}
                    </Button>

                    <Button onClick={() => setShowConfirmMessage('complete')} size="sm" variant="primary">
                      Complete All
                    </Button>
                  </>
                )}
              </HStack>
            )}
          </CaptureList>
        </DroppableContainer>
      );
    },
    [
      onClickAddAction,
      doItLaterId,
      buttonLabel,
      showConfirmMessage,
      isLoading,
      onHandleMarkAllCompleted,
      onHandleMoveAllNext,
      weeklyPlanId,
      selectedDate,
    ],
  );

  if (isLoadingActions || isLoadingCategories) {
    return (
      <>
        <Flex justifyContent="center" marginTop={rem(80)}>
          <Spinner size="xl" speed="0.65s" thickness={rem(4)} />
        </Flex>
      </>
    );
  }

  return (
    <>
      <CategorySelector />
      <DndContainer onDragStart={onDragStart} onDragEnd={onDragEnd} onDragCancel={onDragCancel}>
        <Flex
          ref={actionsContainerRef}
          flexDirection="column"
          overflowY="auto"
          width={`calc(100% + ${rem(16)})`}
          marginRight={rem(-16)}
          paddingRight={rem(24)}
        >
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageNavigationEffect}
            transition={pageTransition}
            style={{
              width: '100%',
            }}
          >
            {notEmptyCategories.length === 0 ? (
              <Box width={{ base: '100%', xl: `calc(50% - ${rem(16)})` }}>
                <EmptyCategoryCallout />
              </Box>
            ) : (
              <MasonryContainer
                dataArray={notEmptyCategories}
                columnCount={columnCount ?? 2}
                renderChild={(category) => SingleCategoryContainer(category as CategoryWithActions)}
              />
            )}
          </motion.div>

          <Accordion width="100%" allowToggle defaultIndex={[0]} reduceMotion>
            <EmptyCategories
              categories={emptyCategories}
              viewportRef={actionsContainerRef}
              setActionModalOpen={setActionModalOpen}
              columnCount={columnCount ?? 2}
            />
          </Accordion>
        </Flex>

        {ItemOverlay}
      </DndContainer>
    </>
  );
}

export default Capture;
