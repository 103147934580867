export type ValueLabelType = {
  value: number;
  label: string;
};

export const COMPLETE = 'complete';
export const IN_PROGRESS = 'in-progress';
export const INCOMPLETE = 'incomplete';

export enum ActionRelation {
  None = 'none',
  Leverage = 'leverage',
  Commit = 'commitment',
}

export const ALL_ACTIONS = 0;
export const UNSCHEDULED_ACTIONS = 1;
export const UNPLANNED_ACTIONS = 2;

export const SCHEDULE_LIST_SEGMENTED_BUTTON_OPTIONS: ValueLabelType[] = [
  {
    value: ALL_ACTIONS,
    label: 'All Actions',
  },
  {
    value: UNSCHEDULED_ACTIONS,
    label: 'Unscheduled',
  },
];

export const PLAN_LIST_SEGMENTED_BUTTON_OPTIONS: ValueLabelType[] = [
  {
    value: ALL_ACTIONS,
    label: 'All Actions',
  },
  {
    value: UNPLANNED_ACTIONS,
    label: 'Unplanned',
  },
];

export const THIS_WEEK_ACTIONS = 0;
export const PAST_WEEKS_ACTIONS = 1;

export const LOAD_ACTIONS_PERIOD: ValueLabelType[] = [
  {
    value: THIS_WEEK_ACTIONS,
    label: 'This Week Only',
  },
  {
    value: PAST_WEEKS_ACTIONS,
    label: 'Include Past Weeks',
  },
];
