import { ActionRelation } from '@/constants/action';
import { DAY, WEEK } from '@/constants/calendar';
import { Action, ProgressStatus } from '@/types/actions';
import { init, reset, setUserId, track } from '@amplitude/analytics-browser';
import { format } from 'date-fns';
import { capitalize } from 'lodash';

type Interval = typeof DAY | typeof WEEK;
export type EventLocation =
  | 'side bar'
  | 'calendar'
  | 'navigation menu'
  | 'project modal'
  | 'block modal'
  | 'block'
  | 'capture'
  | 'category manager'
  | 'home'
  | 'categories'
  | 'planner'
  | 'people'
  | 'project'
  | 'schedule';

export function initTracking(userId?: string) {
  init(import.meta.env.VITE_AMPLITUDE_API_KEY, userId, {
    defaultTracking: true,
    autocapture: {
      formInteractions: false,
    },
  });
}

export function trackUser(userId?: string) {
  setUserId(userId);
}

export function signOutTracking() {
  reset();
}

export function trackActionPlannedByDrag(action: Action) {
  if (!action.scheduledDate) return;

  track('Action Planned', {
    day_of_week: format(new Date(action.scheduledDate), 'EEEE'),
    stared_action: !!action.dateOfStarring,
    block_action: !!action.blockId,
  });
}

export function trackActionScheduledByDrag(action: Action) {
  if (!action.scheduledDate) return;

  track('Action Scheduled', {
    day_of_week: format(new Date(action.scheduledDate), 'EEEE'),
    stared_action: !!action.dateOfStarring,
    block_action: !!action.blockId,
  });
}

export function trackCreateEntitySelected(entity: string) {
  track('Create Entity Selected', { entity });
}

export function trackCreateCategorySelected(location: EventLocation) {
  track('Create Category Selected', { location });
}

export function trackCategoryCreated() {
  track('Category Created');
}

export function trackCalendarSelected(location: EventLocation) {
  track('Calendar Selected', { location });
}

export function trackMyWeekSelected(location: EventLocation) {
  track('My Week Selected', { location });
}

export function trackMyDaySelected(location: EventLocation) {
  track('My Day Selected', { location });
}

export function trackPeopleSelected() {
  track('People Selected');
}

export function trackProjectsSelected(location: EventLocation) {
  track('Projects Selected', { location });
}

export function trackCategoriesSelected(location: EventLocation) {
  track('Categories Selected', { location });
}

export function trackActionProgress(progressStatus: ProgressStatus, action: Action) {
  if (progressStatus === 'in-progress') {
    track('Action Marked In Progress', {
      leveraged_action: action?.promises?.[0]?.kind === ActionRelation.Leverage,
    });
  }

  if (progressStatus === 'complete') {
    track('Action Completed', {
      day_of_week: format(new Date(), 'EEEE'),
      stared_action: !!action.dateOfStarring,
      block_action: !!action.blockId,
      project_action: !!action.projectId,
    });
  }
}

export function trackBulkActionCompleted(action: Action) {
  track('Bulk Action Completed', {
    day_of_week: format(new Date(), 'EEEE'),
    stared_action: !!action.dateOfStarring,
    block_action: !!action.blockId,
    project_action: !!action.projectId,
  });
}

export function trackCompleteSelected(interval: Interval) {
  track(`Complete Selected`, { time_interval: interval });
}

export function trackMarkAllAsCompleted(interval: Interval) {
  track('Mark All As Completed Selected', { time_interval: interval });
}

export function trackMoveAllToNextInterval(interval: Interval) {
  track('Move All to Next Interval Selected ', { time_interval: interval });
}

export function trackActionUnplannedDuringComplete() {
  track('Action Unplanned');
}

export function trackActionMovedDuringComplete() {
  track('Action Moved');
}

export function trackReflectionEntered() {
  track('Reflection Entered');
}

export function trackLeverageRequestStatus(id: string, status: 'sent' | 'accepted' | 'completed' | 'declined') {
  track(`Leverage Request ${capitalize(status)}`, {
    request_id: id,
  });
}

export function trackBlockCreated() {
  track('RPM Block Created');
}

export function trackCompleteBlockSelected() {
  track('RPM Block Mark Complete Started');
}

export function trackBlockCompleted() {
  track('RPM Block Completed');
}

export function trackBlockActionsMovedOnComplete() {
  track('RPM Block Actions Moved');
}

export function trackBlockCompleteCanceled() {
  track('RPM Block Canceled');
}

export function trackCategoryTimeScheduled(interval: Interval) {
  track('Category Time Scheduled', { time_interval: interval });
}

export function trackCreateActionStarted(location: EventLocation) {
  track('Create Action Started', { location });
}

export function trackActionCreated(location: EventLocation) {
  track('Action Created', { location });
}

export function trackActionDeleted() {
  track('Action Deleted');
}

export function trackActionAddedToBlock(stared_action?: boolean) {
  track('Action Added to Block', { stared_action });
}

export function trackActionStarred() {
  track('Action Starred');
}

export function trackCommitmentMade(request_id: string) {
  track('Commitment Made', { request_id });
}

export function trackProjectDeleted() {
  track('Project Deleted');
}

export function trackScreenModeChanged() {
  track('Screen Mode Changed');
}

export function trackBlockTimeScheduled(interval: Interval) {
  track('Block Time Scheduled', { time_interval: interval });
}

export function trackFeedbackSelected() {
  track('Feedback Selected');
}

export function trackTimezoneSelected(selected_timezone: string) {
  track('Timezone Selected', {
    users_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    selected_timezone,
  });
}

export function trackImageFromComputerSelected() {
  track('Image from computer Selected');
}
