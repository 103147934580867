import rem from '@/utils/rem';
import { AspectRatio, Box, Button, Flex, Grid, HStack, Image, ModalBody, Spinner, Text } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import { Children, useState } from 'react';

import { UnsplashPhoto } from '.';

interface UnsplashImagesGridProps {
  data?: { pages: { results: UnsplashPhoto[] }[] };
  randomImages?: UnsplashPhoto[];
  error: any;
  isFetchingRandomImages: boolean;
  isFetchingNextPage: boolean;
  loadMoreButtonRef: (node?: Element | null) => void;
  onImageSelection: (photo: UnsplashPhoto) => void;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isSavingImage: boolean;
  setPage: (callback: (page: number) => number) => void;
}

const UnsplashImagesGrid = ({
  data,
  randomImages,
  error,
  isFetchingRandomImages,
  isFetchingNextPage,
  loadMoreButtonRef,
  onImageSelection,
  hasNextPage,
  fetchNextPage,
  isSavingImage,
  setPage,
}: UnsplashImagesGridProps) => {
  const [hoverPhotoId, setHoverPhotoId] = useState<string | null>(null);

  if (error) {
    return <Box>Error fetching photos</Box>;
  }

  const photos = data?.pages.flatMap((page) => page.results) || randomImages;

  return (
    <ModalBody>
      {(isFetchingNextPage || isFetchingRandomImages) && (
        <Flex alignItems="center" justifyContent="center" width="full" height="full">
          <Spinner color="white.500" aria-label="loading" />
        </Flex>
      )}
      <Grid
        position="relative"
        gridGap={6}
        gridTemplateColumns={`repeat(auto-fill, minmax(${rem(200)}, 1fr))`}
        width="full"
      >
        {isSavingImage && (
          <Flex
            position="absolute"
            zIndex={1}
            alignItems="center"
            justifyContent="center"
            width="full"
            height="full"
            backgroundColor="blackAlpha.800"
          >
            <Spinner color="white.500" />
          </Flex>
        )}
        {!isFetchingRandomImages && isEmpty(photos) && <Text paddingBottom={rem(20)}>No result found</Text>}
        {Children.toArray(
          photos?.map((photo) => (
            <AspectRatio maxWidth="100%" ratio={1}>
              <Box
                position="relative"
                cursor="pointer"
                onClick={() => onImageSelection(photo)}
                onMouseEnter={() => setHoverPhotoId(photo.id)}
                onMouseLeave={() => setHoverPhotoId(null)}
                role="group"
              >
                <Image boxSize="100%" fit="cover" src={photo?.urls?.small} />
                <AnimatePresence>
                  {hoverPhotoId === photo.id && (
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                      <Box
                        position="absolute"
                        bottom={0}
                        left={0}
                        style={{
                          display: 'flex',
                          flex: 1,
                          width: '100%',
                          height: '100%',
                          fontSize: 'small',
                          background: 'linear-gradient(0deg, rgba(0,0,0,0.62) 0%, rgba(255,255,255,0) 37%)',
                          alignItems: 'flex-end',
                          justifyContent: 'flex-start',
                          paddingLeft: rem(10),
                          paddingBottom: rem(8),
                        }}
                      >
                        <Text>
                          <a
                            style={{ textDecoration: 'underline' }}
                            target="_blank"
                            href={`${photo.user.links.html}?utm_source=${import.meta.env.VITE_UNSPLASH_APP_NAME}&utm_medium=referral`}
                          >
                            {photo?.user?.name}
                          </a>
                        </Text>
                      </Box>
                    </motion.div>
                  )}
                </AnimatePresence>
              </Box>
            </AspectRatio>
          )),
        )}
      </Grid>

      {hasNextPage && (
        <Flex ref={loadMoreButtonRef} justifyContent="center" width="full" marginTop={rem(15)}>
          {isFetchingNextPage ? (
            <HStack justifyContent="center" width="full">
              <Spinner />
            </HStack>
          ) : (
            <Button
              onClick={() => {
                fetchNextPage();
                setPage((prevState: number) => prevState + 1);
              }}
            >
              Load more
            </Button>
          )}
        </Flex>
      )}
    </ModalBody>
  );
};

export default UnsplashImagesGrid;
