import { keys } from '@/gql/global/keys';
import { APIError } from '@/gql/global/types';
import {
  COMPLETE_DISCUSSION_POINT,
  CREATE_DISCUSSION_POINT,
  CREATE_PERSON,
  DELETE_DISCUSSION_POINT,
  DELETE_PERSON,
  EDIT_DISCUSSION_POINT,
  EDIT_PERSON,
  GET_PEOPLE_LIST,
  GET_PERSON,
  UPDATE_COMMITTED_ACTIONS_TO_OTHER_ORDER,
  UPDATE_COMMITTED_ACTIONS_TO_SINGLE_USER_ORDER,
  UPDATE_DISCUSSION_POINTS_ORDER,
  UPDATE_LEVERAGED_ACTIONS_TO_OTHER_ORDER,
  UPDATE_LEVERAGED_ACTIONS_TO_SINGLE_USER_ORDER,
} from '@/gql/people';
import {
  CompleteDiscussionPointPayload,
  CompleteDiscussionPointResponse,
  CreateDiscussionPointPayload,
  CreateDiscussionPointResponse,
  CreatePersonPayload,
  CreatePersonResponse,
  DeleteDiscussionPointPayload,
  DeleteDiscussionPointResponse,
  DeletePersonPayload,
  DeletePersonResponse,
  EditDiscussionPointPayload,
  EditDiscussionPointResponse,
  EditPersonResponse,
  GetPeopleResponse,
  GetPersonResponse,
  UpdateCommittedActionsToOtherOrderPayload,
  UpdateCommittedActionsToOtherOrderResponse,
  UpdateCommittedActionsToSingleUserOrderPayload,
  UpdateCommittedActionsToSingleUserOrderResponse,
  UpdateDiscussionPointsOrderPayload,
  UpdateDiscussionPointsOrderResponse,
  UpdateLeveragedActionsToOtherOrderPayload,
  UpdateLeveragedActionsToOtherOrderResponse,
  UpdateLveragedActionsToSingleUserOrderPayload,
  UpdateLveragedActionsToSingleUserOrderResponse,
} from '@/gql/people/types';
import { fetchData } from '@/services/graphql';
import { getWeeklyPlanId } from '@/services/plans/hooks/useWeeklyPlan';
import { getSelectedDate } from '@/stores/useCalendar';
import { invalidateQueries, refetchQueries } from '@/utils/tanStackQuery';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

type UpdateDiscussionPointsOrderDTO = {
  id: string;
  order: number;
};

type UpdateCommittedActionsToOtherOrderDTO = {
  id: string;
  globalCommitOrder: number;
};

type UpdateLeveragedActionsOrderDTO = {
  id: string;
  globalLeverageOrder: number;
};

type UpdateCommittedActionsToSingleUserOrderDTO = {
  actionId?: string;
  commitOrder?: number | null;
};

type UpdateLeveragedActionsToSingleUserOrderDTO = {
  actionId?: string;
  leverageOrder?: number | null;
};

export const usePeople = (options?: UseQueryOptions<GetPeopleResponse, APIError, GetPeopleResponse>) => {
  return useQuery({
    queryKey: keys.people.all.queryKey,
    queryFn: () => fetchData(GET_PEOPLE_LIST),
    ...options,
  });
};

export const useCreatePerson = (
  options?: Omit<UseMutationOptions<CreatePersonResponse, APIError, CreatePersonPayload>, 'onSuccess'>,
) => {
  return useMutation({
    mutationFn: (payload: CreatePersonPayload) => fetchData<CreatePersonResponse>(CREATE_PERSON, payload),
    ...options,

    onSuccess: (response) => {
      if (!response.insertPersonOne) return;
      refetchQueries([keys.people.all.queryKey]);
    },
  });
};

export const usePerson = (id?: string, options?: UseQueryOptions<GetPersonResponse, APIError, GetPersonResponse>) => {
  return useQuery({
    queryKey: keys.people.detail(id ?? '').queryKey,
    queryFn: () => fetchData(GET_PERSON, { id }),
    ...options,
    onSuccess: (response) => {
      response.personByPk.discussion_items.sort((a, b) => a.order - b.order);
    },
  });
};

export const useEditPerson = (
  options?: Omit<UseMutationOptions<EditPersonResponse, APIError, CreatePersonPayload>, 'onSuccess'>,
) => {
  return useMutation({
    mutationFn: (payload: CreatePersonPayload) => fetchData<EditPersonResponse>(EDIT_PERSON, payload),
    ...options,

    onSuccess: (response) => {
      if (!response.updatePersonByPk) return;

      invalidateQueries([
        keys.people.detail(response.updatePersonByPk.id).queryKey,
        keys.people.all.queryKey,
        keys.actions.leveragedAndCommitted.queryKey,
      ]);
    },
  });
};

export const useDeletePerson = (
  options?: Omit<UseMutationOptions<DeletePersonResponse, APIError, DeletePersonPayload>, 'onSuccess'>,
) => {
  return useMutation({
    mutationFn: (payload: DeletePersonPayload) => fetchData<DeletePersonResponse>(DELETE_PERSON, payload),
    ...options,

    onSuccess: async (response) => {
      if (!response.deletePersonByPk) return;
      const selectedDate = getSelectedDate();
      const weeklyPlanId = getWeeklyPlanId(selectedDate);

      const cacheKeys = [];
      cacheKeys.push(keys.people.all.queryKey);
      weeklyPlanId && cacheKeys.push(keys.actions.all._ctx.week(weeklyPlanId).queryKey);
      cacheKeys.push(keys.actions.leveragedAndCommitted.queryKey);

      await invalidateQueries(cacheKeys);
    },
  });
};

export const useCreateDiscussionPoint = (
  options?: Omit<
    UseMutationOptions<CreateDiscussionPointResponse, APIError, CreateDiscussionPointPayload>,
    'onSuccess'
  >,
) => {
  return useMutation({
    mutationFn: (payload: CreateDiscussionPointPayload) =>
      fetchData<CreateDiscussionPointResponse>(CREATE_DISCUSSION_POINT, payload),
    ...options,

    onSuccess: (response) => {
      if (!response.insertDiscussionItemOne) return;

      invalidateQueries([
        keys.people.detail(response.insertDiscussionItemOne.personId).queryKey,
        keys.people.all.queryKey,
      ]);
    },
  });
};

export const useCompleteDiscussionPoint = (
  options?: Omit<
    UseMutationOptions<CompleteDiscussionPointResponse, APIError, CompleteDiscussionPointPayload[]>,
    'onSuccess'
  >,
) => {
  return useMutation({
    mutationFn: (payload: CompleteDiscussionPointPayload[]) =>
      fetchData<CompleteDiscussionPointResponse>(COMPLETE_DISCUSSION_POINT, {
        updates: payload,
      }),

    ...options,

    onSuccess: (response) => {
      const data = response.updateDiscussionItemMany.map((i) => i.returning).flat();

      const personId = data[0].personId;

      invalidateQueries([keys.people.detail(personId).queryKey, keys.people.all.queryKey]);
    },
  });
};

export const useEditDiscussionPoint = (
  options?: Omit<UseMutationOptions<EditDiscussionPointResponse, APIError, EditDiscussionPointPayload>, 'onSuccess'>,
) => {
  return useMutation({
    mutationFn: (payload: EditDiscussionPointPayload) =>
      fetchData<EditDiscussionPointResponse>(EDIT_DISCUSSION_POINT, payload),
    ...options,

    onSuccess: (response) => {
      if (!response.updateDiscussionItemByPk) return;

      invalidateQueries([
        keys.people.detail(response.updateDiscussionItemByPk.personId).queryKey,
        keys.people.all.queryKey,
      ]);
    },
  });
};

export const useDeleteDiscussionPoint = (
  options?: Omit<
    UseMutationOptions<DeleteDiscussionPointResponse, APIError, DeleteDiscussionPointPayload>,
    'onSuccess'
  >,
) => {
  return useMutation({
    mutationFn: (payload: DeleteDiscussionPointPayload) =>
      fetchData<DeleteDiscussionPointResponse>(DELETE_DISCUSSION_POINT, payload),
    ...options,

    onSuccess: (response) => {
      if (!response.deleteDiscussionItemByPk) return;

      invalidateQueries([
        keys.people.detail(response.deleteDiscussionItemByPk.personId).queryKey,
        keys.people.all.queryKey,
      ]);
    },
  });
};

export const useUpdateDiscussionPointsOrder = (
  options?: UseMutationOptions<UpdateDiscussionPointsOrderResponse, APIError, UpdateDiscussionPointsOrderPayload[]>,
) => {
  return useMutation({
    mutationFn: (updates: UpdateDiscussionPointsOrderPayload[]) =>
      fetchData<UpdateDiscussionPointsOrderResponse>(UPDATE_DISCUSSION_POINTS_ORDER, { updates }),

    ...options,
  });
};

export const useUpdateCommittedActionsToOtherOrder = (
  options?: UseMutationOptions<
    UpdateCommittedActionsToOtherOrderResponse,
    APIError,
    UpdateCommittedActionsToOtherOrderPayload[]
  >,
) => {
  return useMutation({
    mutationFn: (actionUpdates: UpdateCommittedActionsToOtherOrderPayload[]) =>
      fetchData<UpdateCommittedActionsToOtherOrderResponse>(UPDATE_COMMITTED_ACTIONS_TO_OTHER_ORDER, { actionUpdates }),

    ...options,

    onSuccess: () => {
      refetchQueries([keys.actions.leveragedAndCommitted.queryKey]);
    },
  });
};

export const useUpdateLeveragedActionsToOtherOrder = (
  options?: UseMutationOptions<
    UpdateLeveragedActionsToOtherOrderResponse,
    APIError,
    UpdateLeveragedActionsToOtherOrderPayload[]
  >,
) => {
  return useMutation({
    mutationFn: (actionUpdates: UpdateLeveragedActionsToOtherOrderPayload[]) =>
      fetchData<UpdateLeveragedActionsToOtherOrderResponse>(UPDATE_LEVERAGED_ACTIONS_TO_OTHER_ORDER, { actionUpdates }),

    ...options,

    onSuccess: () => {
      refetchQueries([keys.actions.leveragedAndCommitted.queryKey]);
    },
  });
};

export const useUpdateCommittedActionsToSingleUserOrder = (
  personId: string,
  options?: UseMutationOptions<
    UpdateCommittedActionsToSingleUserOrderResponse,
    APIError,
    UpdateCommittedActionsToSingleUserOrderPayload[]
  >,
) => {
  return useMutation({
    mutationFn: (actionUpdates: UpdateCommittedActionsToSingleUserOrderPayload[]) =>
      fetchData<UpdateCommittedActionsToSingleUserOrderResponse>(UPDATE_COMMITTED_ACTIONS_TO_SINGLE_USER_ORDER, {
        actionUpdates,
      }),

    ...options,

    onSuccess: () => {
      if (!personId) return;
      invalidateQueries([keys.actions.leveragedAndCommitted.queryKey]);
    },
  });
};

export const useUpdateLeveragedActionsToSingleUserOrder = (
  personId: string,
  options?: UseMutationOptions<
    UpdateLveragedActionsToSingleUserOrderResponse,
    APIError,
    UpdateLveragedActionsToSingleUserOrderPayload[]
  >,
) => {
  return useMutation({
    mutationFn: (actionUpdates: UpdateLveragedActionsToSingleUserOrderPayload[]) =>
      fetchData<UpdateLveragedActionsToSingleUserOrderResponse>(UPDATE_LEVERAGED_ACTIONS_TO_SINGLE_USER_ORDER, {
        actionUpdates,
      }),

    ...options,

    onSuccess: () => {
      if (!personId) return;
      refetchQueries([keys.actions.leveragedAndCommitted.queryKey]);
    },
  });
};
