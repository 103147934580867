import BlockItem from '@/components/BlockItem';
import { ActionList, DroppableContainer, SortableItemContainer } from '@/components/DragAndDrop';
import ActionListItem from '@/components/DragAndDrop/ActionListItem';
import EmptyActionList from '@/components/EmptyActionList';
import { keys } from '@/gql/global/keys';
import { onMutateBlockStatus } from '@/services/myPlan/hooks';
import useIsDailyPlanPage from '@/services/plans/hooks/useIsDailyPlanPage';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import { DnDBlockItemProps } from '@/types/dragAndDrop';
import {
  calculateTotalDuration,
  getCompletedActions,
  getDailyActions,
  getIncompleteActions,
  getWeeklyActions,
} from '@/utils/action';
import { getTotalDurationOfStarredActions } from '@/utils/events';
import { humanDuration, isOnProjectDetailPage } from '@/utils/index';
import { makeBlockItemKey } from '@/utils/myplan';
import rem from '@/utils/rem';
import { invalidateQueries } from '@/utils/tanStackQuery';
import { Text } from '@chakra-ui/react';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';

const DnDBlockItem = ({
  actions,
  block,
  sortable = false,
  index,
  weeklyPlanId,
  onCompleteBlock,
  onUncompleteBlock,
  openSetUncompletedActionsModal,
  onSelectBlock,
  actionModalProps,
  onClickAddAction,
  onClickRemoveFromBlock,
  showCompletedActions = true,
  withScheduleButton = false,
  openEditBlockModal,
  openDuplicateBlockModal,
  onDeleteBlock,
  draggable,
  collapsedByDefault = false,
}: DnDBlockItemProps) => {
  const selectedDate = useCalendarMonthlyStore((state) => state.selectedDate);
  const isDailyPlanPage = useIsDailyPlanPage();

  const key = makeBlockItemKey(block.id);

  const onUpdateBlock = useCallback(() => {
    if (isOnProjectDetailPage()) {
      if (!block.projectId) return;
      invalidateQueries([keys.project.detail(block.projectId).queryKey]);
    }
    onMutateBlockStatus(block.id, false);
  }, [block.id, block.projectId]);

  const blockActions = useMemo(() => {
    if (isDailyPlanPage) {
      return getDailyActions(actions, selectedDate);
    }

    if (weeklyPlanId) {
      return getWeeklyActions(actions, weeklyPlanId);
    }

    return actions;
  }, [actions, selectedDate, weeklyPlanId, isDailyPlanPage]);

  if (!blockActions?.length) {
    return <></>;
  }

  const incompleteActions = getIncompleteActions(blockActions, 'blockOrder');
  const completedActions = getCompletedActions(blockActions, 'modifiedAt');

  const blockItem = (
    <BlockItem
      item={block}
      totalDuration={humanDuration(calculateTotalDuration(incompleteActions) * 60)}
      starredActionsTotalDuration={getTotalDurationOfStarredActions(incompleteActions)}
      onDeleteBlock={onDeleteBlock}
      collapsedByDefault={collapsedByDefault}
      incompleteActions={
        <SortableContext items={incompleteActions} strategy={verticalListSortingStrategy}>
          <Text textStyle="xsmall" marginBottom={rem(16)} color="text-secondary" textTransform="uppercase">
            Massive Action Plan
          </Text>
          <ActionList
            key={key}
            emptyListComponent={
              <DroppableContainer
                id={key}
                payload={{
                  containerId: 'block-empty',
                  blockId: block.id,
                  category: block.category,
                  projectId: block.projectId,
                }}
              >
                <EmptyActionList
                  title={
                    isEmpty(completedActions) ? 'No current actions' : 'All actions in this block have been completed'
                  }
                  message={isEmpty(completedActions) ? 'Click the + to add an action to this empty block.' : undefined}
                  motivational="small"
                />
              </DroppableContainer>
            }
            isEmpty={isEmpty(incompleteActions)}
          >
            {incompleteActions.map((action, index) => (
              <ActionListItem
                key={action.id}
                item={{ ...action, block }}
                index={index}
                dndPayload={{
                  blockId: block.id,
                  containerId: `block-${block.id}`,
                  index,
                  projectId: action?.projectId,
                }}
                onUpdateBlock={onUpdateBlock}
                actionModalProps={actionModalProps}
                onClickRemoveFromBlock={(actionId) => onClickRemoveFromBlock(actionId, block.id)}
                withScheduleButton={withScheduleButton}
              />
            ))}
          </ActionList>
        </SortableContext>
      }
      completedActions={
        <SortableContext items={completedActions}>
          <ActionList
            key={key}
            emptyListComponent={<EmptyActionList message="No complete actions" />}
            isEmpty={isEmpty(completedActions)}
          >
            {completedActions.map((action, index) => (
              <ActionListItem
                key={action.id}
                item={{ ...action, block }}
                index={index}
                dndPayload={{
                  blockId: block.id,
                  containerId: `block-${block.id}`,
                  index,
                  projectId: action?.projectId,
                }}
                onUpdateBlock={onUpdateBlock}
                onClickRemoveFromBlock={(actionId) => onClickRemoveFromBlock(actionId, block.id)}
                readOnly
                withScheduleButton={withScheduleButton}
              />
            ))}
          </ActionList>
        </SortableContext>
      }
      numberOfCompletedActions={completedActions?.length}
      showCompletedActions={showCompletedActions}
      onUncompleteBlock={onUncompleteBlock}
      onCompleteBlock={onCompleteBlock}
      openSetUncompletedActionsModal={openSetUncompletedActionsModal}
      onSelectBlock={onSelectBlock}
      actions={actions}
      onClickAddAction={onClickAddAction}
      openEditBlockModal={openEditBlockModal}
      openDuplicateBlockModal={openDuplicateBlockModal}
      draggable={draggable}
    />
  );

  const itemProps = {
    id: block.id,
    payload: {
      blockId: block.id,
      category: block.category,
      containerId: 'blocks',
      index,
      projectId: block.projectId,
    },
  };

  return sortable ? (
    <SortableItemContainer {...itemProps}>{blockItem}</SortableItemContainer>
  ) : (
    <DroppableContainer {...itemProps}>{blockItem}</DroppableContainer>
  );
};

export default DnDBlockItem;
