import { IconThreeDotsVertical } from '@/theme/icons';
import { Block } from '@/types/block';
import rem from '@/utils/rem';
import { Icon, IconButton, Menu, MenuButton, MenuButtonProps, MenuItem, MenuList, Tooltip } from '@chakra-ui/react';
import { FunctionComponent, SVGProps, useCallback } from 'react';

type Item = {
  title: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  onClick: (block?: Block) => void;
  textColor?: string;
  iconColor?: string;
};

type MenuListItem = {
  item: Item;
  block?: Block;
};

export type ThreeDotsBlocksButtonProps = {
  items?: Item[];
  block?: Block;
} & MenuButtonProps;

const MenuListItem = ({ item, block }: MenuListItem) => {
  const onClickItem = useCallback(() => item.onClick(block), [block, item]);

  return (
    <MenuItem
      textStyle="small"
      marginBottom={rem(8)}
      padding={`${rem(4)} ${rem(8)}`}
      color={item.textColor}
      borderRadius="4"
      _hover={{ backgroundColor: 'background-secondary' }}
      backgroundColor="inherit"
      icon={<Icon as={item.icon} boxSize={rem(14)} color={item.iconColor ?? 'text-secondary'} />}
      iconSpacing={rem(8)}
      onClick={onClickItem}
    >
      {item.title}
    </MenuItem>
  );
};

const ThreeDotsBlocksButton = ({ items, block, ...menuButtonProps }: ThreeDotsBlocksButtonProps) => {
  return (
    <Menu isLazy>
      <Tooltip label="Block Menu" placement="top">
        <MenuButton
          as={IconButton}
          width="auto"
          height="auto"
          borderRadius={rem(6)}
          _hover={{ backgroundColor: 'background-secondary' }}
          _active={{
            backgroundColor: 'background-secondary',
          }}
          __css={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
          aria-label="Action Menu"
          icon={<Icon as={IconThreeDotsVertical} boxSize={rem(14)} margin="0 auto" color="text-primary" />}
          variant="unstyled"
          {...menuButtonProps}
        />
      </Tooltip>
      <MenuList
        zIndex="dropdown"
        width={rem(141)}
        minWidth="0"
        borderWidth={rem(1)}
        borderStyle="solid"
        borderColor="gray.700"
        borderRadius="4"
        backgroundColor="background-primary"
        paddingX={rem(8)}
      >
        {items?.map((item) => <MenuListItem key={item.title} item={item} block={block} />)}
      </MenuList>
    </Menu>
  );
};

export default ThreeDotsBlocksButton;
