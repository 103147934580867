import { COMPLETE } from '@/constants/action';
import { GetBlocksResponse } from '@/gql/block/types';
import { keys } from '@/gql/global/keys';
import { queryClient } from '@/services/graphql/queryClient';
import { onMutateBlockStatus, useIncompleteBlockStatus } from '@/services/myPlan/hooks';
import { Action } from '@/types/actions';
import { refetchActions } from '@/utils/action';
import { getBlockById } from '@/utils/block';
import { useCallback } from 'react';

const useUpdateBlockAction = () => {
  const incompleteBlockStatus = useIncompleteBlockStatus({
    onMutate: ({ id }) => onMutateBlockStatus(id),
  });

  const onUpdateBlockAction = useCallback(
    (updatedAction: Action) => {
      const blocks = queryClient.getQueryData<GetBlocksResponse>(keys.blocks.all.queryKey);

      const block = getBlockById(blocks?.block ?? [], updatedAction.blockId ?? '');

      if (block?.isCompleted && updatedAction.progressStatus !== COMPLETE) {
        incompleteBlockStatus.mutate({ id: updatedAction.blockId ?? '' });
      }

      refetchActions(updatedAction);
    },
    [incompleteBlockStatus],
  );

  return onUpdateBlockAction;
};

export default useUpdateBlockAction;
