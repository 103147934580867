import { ActionList } from '@/components/DragAndDrop';
import DnDBlockItem from '@/components/DragAndDrop/DnDBlockItem';
import EmptyActionList from '@/components/EmptyActionList';
import { IconChevronDown } from '@/theme/icons';
import { Action } from '@/types/actions';
import { Block } from '@/types/block';
import { DnDBlockItemProps } from '@/types/dragAndDrop';
import { getActionsByBlockId } from '@/utils/action';
import rem from '@/utils/rem';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Flex, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash';

type BlockListCompletedProps = {
  className?: string;
  blocks: Block[];
  allActionsOrderedByCreatedAt: Action[];
  onSelectedBlock: React.Dispatch<React.SetStateAction<Block | null>>;
} & Pick<
  DnDBlockItemProps,
  | 'onCompleteBlock'
  | 'openSetUncompletedActionsModal'
  | 'onUncompleteBlock'
  | 'openEditBlockModal'
  | 'onClickRemoveFromBlock'
  | 'onDeleteBlock'
  | 'openDuplicateBlockModal'
>;

function BlockListCompleted({
  blocks,
  className,
  allActionsOrderedByCreatedAt,
  onSelectedBlock,
  ...DnDBlockItemProps
}: BlockListCompletedProps) {
  return (
    <Accordion width="full" marginTop={rem(16)} allowToggle reduceMotion>
      <AccordionItem>
        <AccordionButton className={className} gap={rem(4)} padding="0">
          <Flex flex="1" textAlign="left">
            <Text textStyle="xsmall" color="text-secondary" textTransform="uppercase">
              {blocks.length} Completed RPM Blocks
            </Text>
          </Flex>
          <AccordionIcon as={IconChevronDown} width={rem(14)} height={rem(14)} color="text-primary" />
        </AccordionButton>
        <AccordionPanel marginTop={rem(8)} padding={0} paddingBottom={4}>
          <ActionList isEmpty={isEmpty(blocks)} emptyListComponent={<EmptyActionList message="No completed blocks" />}>
            {blocks.map((block, index) => (
              <DnDBlockItem
                key={block.id}
                actions={getActionsByBlockId(block.id, allActionsOrderedByCreatedAt)}
                block={block}
                index={index}
                weeklyPlanId=""
                onCompleteBlock={DnDBlockItemProps.onCompleteBlock}
                openSetUncompletedActionsModal={DnDBlockItemProps.openSetUncompletedActionsModal}
                onUncompleteBlock={DnDBlockItemProps.onUncompleteBlock}
                onSelectBlock={() => onSelectedBlock(block)}
                onClickRemoveFromBlock={DnDBlockItemProps.onClickRemoveFromBlock}
                withScheduleButton
                openEditBlockModal={DnDBlockItemProps.openEditBlockModal}
                onDeleteBlock={DnDBlockItemProps.onDeleteBlock}
                openDuplicateBlockModal={DnDBlockItemProps.openDuplicateBlockModal}
              />
            ))}
          </ActionList>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export default BlockListCompleted;
