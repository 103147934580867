import { LOAD_ACTIONS_PERIOD } from '@/constants/action';
import { create } from 'zustand';

type StateSelected = {
  selectedDate: Date;
  actionsPeriod: number;
};

type CalendarMonthly = {
  selectedDate: Date;
  updateSelectedDate: (selectedDate: StateSelected['selectedDate']) => void;
  actionsPeriod: number;
  updateActionsPeriod: (period: StateSelected['actionsPeriod']) => void;
};

const useCalendarMonthlyStore = create<CalendarMonthly>((set) => ({
  selectedDate: new Date(),
  updateSelectedDate: (selectedDate) => set((st) => ({ ...st, selectedDate })),
  actionsPeriod: LOAD_ACTIONS_PERIOD[0].value,
  updateActionsPeriod: (period) => set((st) => ({ ...st, actionsPeriod: period })),
}));

const getSelectedDate = (): Date => {
  return useCalendarMonthlyStore.getState().selectedDate;
};

export { useCalendarMonthlyStore, getSelectedDate };
