import CreateEntityButton from '@/components/CreateEntityButton';
import { pagesNew, useAnimatePlanPages } from '@/contexts/AnimatedPlanPages';
import { keys } from '@/gql/global/keys';
import { RoutesList } from '@/routes/routesList';
import { queryClient } from '@/services/graphql/queryClient';
import { useSelectPlanSchedulePage } from '@/stores/useSelectPlanSchedulePage';
import { IconCalendar, IconClose, IconDay, IconHamburger, IconLogo, IconLogoLight, IconWeek } from '@/theme/icons';
import rem from '@/utils/rem';
import {
  trackCalendarSelected,
  trackCategoriesSelected,
  trackMyDaySelected,
  trackMyWeekSelected,
  trackPeopleSelected,
  trackProjectsSelected,
  trackScreenModeChanged,
} from '@/utils/tracking';
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Link,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  Text,
  UnorderedList,
  useBreakpointValue,
  useColorMode,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import FocusLock from 'react-focus-lock';
import { BsMoonStars } from 'react-icons/bs';
import { FiSun } from 'react-icons/fi';
import { Link as NavLink, useLocation, useNavigate } from 'react-router-dom';

import RRINavLink, { RRINavItem } from './NavLink';
import DropDownNavLink from './NavLink/DropdownLink';
import SettingsButton from './SettingsButton';
import { dropdownNavList, navList } from './navList';

const NavbarGlobal = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { colorMode, toggleColorMode } = useColorMode();
  const isDarkMode = colorMode === 'dark';

  const { setCurrentIndex } = useAnimatePlanPages();

  const { isOpen, onToggle, onClose } = useDisclosure();
  const { isOpen: isDropdownOpen, onClose: onDropdownClose, onOpen: onDropdownOpen } = useDisclosure();

  const isWeekActive =
    location?.pathname?.includes('/my-plan/weekly/') || location?.pathname?.includes(RoutesList.WeeklyCapturePage);

  const updateSelectedPlanSchedulePageIndex = useSelectPlanSchedulePage(
    (state) => state.updateSelectedPlanSchedulePageIndex,
  );

  const resetToSpecificView = useCallback(
    async (e: React.MouseEvent<HTMLElement>, specificRoute: string) => {
      e.preventDefault();
      await setCurrentIndex(pagesNew[specificRoute]);
      await updateSelectedPlanSchedulePageIndex(0);
      trackCalendarSelected('navigation menu');
      navigate(specificRoute);
    },
    [navigate, setCurrentIndex, updateSelectedPlanSchedulePageIndex],
  );

  const resetToDailyView = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();

      updateSelectedPlanSchedulePageIndex(0);
      setCurrentIndex(pagesNew[RoutesList.MyPlanDailyPage]);
      trackMyDaySelected('navigation menu');
      navigate(RoutesList.MyPlanDailyPage);
    },
    [navigate, setCurrentIndex, updateSelectedPlanSchedulePageIndex],
  );

  // This is a possible solution to force the page re-render
  // if the user from the MyPlan -> Capture page (daily flow)
  // click again on the My Plan link
  // in this case:
  // we reset the view to WEEK
  // we reset the query in the cache to its initial state
  // automatically trigger a page render
  const forceCapturePageRender = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      const href = e.currentTarget.getAttribute('href');
      if (!href) return false;
      await updateSelectedPlanSchedulePageIndex(0);

      if (href === RoutesList.CapturePage && location?.pathname?.includes(RoutesList.CapturePage)) {
        queryClient.resetQueries(keys.actions.all.queryKey);
      }

      await setCurrentIndex(pagesNew[href]);

      if (href === RoutesList.MyPlanPage) {
        trackMyWeekSelected('navigation menu');
      }

      if (href === RoutesList.PeoplePage) {
        trackPeopleSelected();
      }

      if (href === RoutesList.ProjectsPage) {
        trackProjectsSelected('navigation menu');
      }

      if (href === RoutesList.CategoryManagerPage) {
        trackCategoriesSelected('navigation menu');
      }
      navigate(href);
    },
    [location?.pathname, navigate, setCurrentIndex, updateSelectedPlanSchedulePageIndex],
  );

  const isMobileScreen = useBreakpointValue({ base: true, lg: false });

  useEffect(() => {
    const closeOnEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (isDropdownOpen) {
          onDropdownClose();
        } else {
          onClose();
        }
      }
    };
    if (isOpen || isDropdownOpen) {
      window.addEventListener('keydown', closeOnEsc, true);
    }
    return () => {
      if (isOpen || isDropdownOpen) {
        window.removeEventListener('keydown', closeOnEsc, true);
      }
    };
  }, [isOpen, isDropdownOpen, onClose, onDropdownClose]);

  useEffect(() => {
    if (!isMobileScreen && isOpen) {
      onClose();
    }
  }, [isMobileScreen, isOpen, onClose]);

  return (
    <Flex
      position="relative"
      justifyContent="space-between"
      width="full"
      height={rem(50)}
      padding={`0 ${rem(16)}`}
      background="background-primary"
    >
      <Flex alignItems="center" justifyContent="start" gap={rem(16)} width="full">
        <Link
          as={NavLink}
          alignItems="center"
          gap={rem(8)}
          display="flex"
          height="full"
          _hover={{
            textDecoration: 'none',
          }}
          _focusVisible={{
            boxShadow: 'none',
            outline: '3px solid var(--chakra-colors-background-secondary)',
          }}
          onClick={(e) => resetToSpecificView(e, RoutesList.HomePage)}
        >
          <Icon as={isDarkMode ? IconLogo : IconLogoLight} boxSize={rem(26)} />
          <Text fontSize={rem(21)} fontWeight={900} lineHeight="initial">
            RPM
          </Text>
        </Link>

        <FocusLock disabled={isMobileScreen === false || !isOpen} returnFocus>
          <Box
            as="nav"
            position={{ base: 'absolute', lg: 'initial' }}
            zIndex="banner"
            top={{ base: rem(50), lg: 'initial' }}
            left="0"
            display={{ base: isOpen ? 'block' : 'none', lg: 'block' }}
            overflow="auto"
            width="100%"
            height={{ base: `calc(100vh - ${rem(50)})`, lg: 'auto' }}
            aria-label="main"
            backgroundColor={{ base: 'background-primary', lg: 'transparent' }}
          >
            <Flex as={UnorderedList} justifyItems="center" flexDirection={{ base: 'column', lg: 'row' }} margin={0}>
              <ListItem>
                <RRINavLink
                  href={RoutesList.Calendar}
                  onClick={(e) => resetToSpecificView(e, RoutesList.Calendar)}
                  label="Calendar"
                  icon={IconCalendar}
                />
              </ListItem>

              <ListItem>
                <Menu
                  closeOnBlur
                  closeOnSelect
                  isOpen={isDropdownOpen}
                  offset={[0, -9]}
                  onClose={onDropdownClose}
                  onOpen={onDropdownOpen}
                >
                  <MenuButton className={`global-menu ${isWeekActive ? 'active' : ''}`} width="full">
                    <RRINavItem
                      icon={IconWeek}
                      isActive={isWeekActive}
                      isDropdown
                      label="My Week"
                      backgroundColor={isDropdownOpen && { base: 'background-secondary', lg: 'background-primary' }}
                    />
                  </MenuButton>

                  <MenuList
                    marginTop={{ base: 0, lg: rem(6) }}
                    padding={{ base: 0, lg: rem(8) }}
                    borderWidth={{ base: 0, lg: rem(1) }}
                    borderStyle="solid"
                    borderColor="gray.700"
                    borderRadius="4"
                    boxShadow={{ base: 'none', lg: 'var(--menu-shadow)' }}
                    backgroundColor={{ base: 'background-secondary', lg: 'background-primary' }}
                    motionProps={{
                      initial: {
                        visibility: 'hidden',
                        opacity: 0,
                        scale: 0.8,
                      },
                      variants: {
                        enter: {
                          visibility: 'visible',
                          opacity: 1,
                          scale: 1,
                          transition: {
                            duration: isMobileScreen ? 0 : 0.3,
                            ease: [0.4, 0, 0.2, 1],
                          },
                        },
                        exit: {
                          transitionEnd: {
                            visibility: 'hidden',
                          },
                          opacity: 0,
                          scale: 0.8,
                          transition: {
                            duration: isMobileScreen ? 0 : 0.1,
                            easings: 'easeOut',
                          },
                        },
                      },
                    }}
                    rootProps={{
                      sx: isMobileScreen
                        ? {
                            position: 'static !important',
                            transform: 'none !important',
                            display: `${isDropdownOpen ? 'block' : 'none'} !important`,
                            scale: '1 !important',
                            transitionTimingFunction: 'linear !important',
                            '--popper-transform-origin': 'none !important',
                            transitionDelay: '0 !important',
                            opacity: '1 !important',
                          }
                        : {},
                    }}
                  >
                    {dropdownNavList.map(({ icon, label, href }) => (
                      <DropDownNavLink
                        key={label}
                        href={href}
                        icon={icon}
                        label={label}
                        onClick={forceCapturePageRender}
                      />
                    ))}
                  </MenuList>
                </Menu>
              </ListItem>

              <ListItem>
                <RRINavLink
                  icon={IconDay}
                  href={RoutesList.MyPlanDailyPage}
                  onClick={resetToDailyView}
                  label="My Day"
                />
              </ListItem>

              {navList.map(({ icon, label, href }) => (
                <ListItem key={label}>
                  <RRINavLink key={label} href={href} icon={icon} label={label} onClick={forceCapturePageRender} />
                </ListItem>
              ))}

              <ListItem display={{ lg: 'none' }} marginTop={rem(16)}>
                <SettingsButton showLabel width="100%" padding={rem(16)} />
              </ListItem>
            </Flex>
          </Box>
        </FocusLock>
      </Flex>

      <Flex alignItems="center" justifyContent="end" gap={rem(16)} width="full" height="full">
        <CreateEntityButton onClick={onClose} />

        <IconButton
          display={{ base: 'flex', lg: 'none' }}
          minWidth={rem(36)}
          height={rem(36)}
          aria-label={isOpen ? 'Close Menu' : 'Open Menu'}
          icon={<Icon as={isOpen ? IconClose : IconHamburger} boxSize={rem(isOpen ? 24 : 14)} />}
          onClick={onToggle}
          variant={isOpen ? 'secondary' : 'primary'}
        />

        <IconButton
          display={{ base: 'none', lg: 'flex' }}
          height={rem(36)}
          borderRadius={4}
          _hover={{
            backgroundColor: isDarkMode ? 'gray.300' : 'blue.950',
          }}
          aria-label="Toggle Mode"
          backgroundColor={isDarkMode ? 'gray.100' : 'blue.1000'}
          icon={<Icon as={isDarkMode ? FiSun : BsMoonStars} boxSize={rem(14)} color="text-primary-inverted" />}
          onClick={() => {
            toggleColorMode();
            trackScreenModeChanged();
          }}
        />

        <SettingsButton display={{ base: 'none', lg: 'flex' }} />
      </Flex>
    </Flex>
  );
};

export default NavbarGlobal;
