import rem from '@/utils/rem';
import { Box, Button, Flex, Image, Input, ModalBody, Spinner, Text } from '@chakra-ui/react';
import { ChangeEventHandler } from 'react';

interface FileUploadProps {
  handleFileChange: ChangeEventHandler<HTMLInputElement>;
  imageSrc: string | null | ArrayBuffer;
  imageError: string | null;
  handleTryAgain: () => void;
  handleApply: (param: boolean) => void;
  isUploading: boolean;
}

const ImageUpload = ({
  handleFileChange,
  imageSrc,
  imageError,
  handleTryAgain,
  handleApply,
  isUploading,
}: FileUploadProps) => (
  <>
    {!imageSrc && (
      <ModalBody position="relative">
        <Flex alignItems="center" justifyContent="center" flexDirection="column" height="full">
          <Button as="label" cursor="pointer" size="lg" variant="primary">
            Upload from your computer
            <Input display="none" accept="image/jpeg, image/png, image/webp" onChange={handleFileChange} type="file" />
          </Button>
          <Text textStyle="smallBlack" marginTop={2} color="red.500">
            {imageError}
          </Text>
        </Flex>
      </ModalBody>
    )}
    {!imageSrc && (
      <Flex alignItems="center" justifyContent="center">
        <Text textStyle="smallBlack" color="text-secondary">
          Supported files: jpeg, jpg, png, webp | Maximum file size: 5MB
        </Text>
      </Flex>
    )}
    {imageSrc && typeof imageSrc === 'string' && (
      <ModalBody
        justifyContent="space-between"
        flexDirection="column"
        gap={rem(32)}
        display="flex"
        paddingBottom={rem(25)}
        paddingX={8}
      >
        <Box height="88%" marginTop={8} background="blue.1000">
          {isUploading ? (
            <Flex alignItems="center" justifyContent="center" flexDirection="column" gap={2} width="100%" height="100%">
              <Spinner width={rem(26)} height={rem(26)} color="white.500" />
              <Text textStyle="mediumBlack" color="text-secondary">
                Uploading...
              </Text>
            </Flex>
          ) : (
            <Image width="100%" height="100%" alt="Uploaded" fit="contain" src={imageSrc} />
          )}
        </Box>
        <Flex flexDirection="row-reverse" gap={2}>
          <Button
            isDisabled={isUploading}
            isLoading={isUploading}
            onClick={() => handleApply(true)}
            size="lg"
            variant="primary"
          >
            Apply
          </Button>
          <Button isDisabled={isUploading} onClick={handleTryAgain} size="lg" variant="secondary">
            Change image
          </Button>
        </Flex>
      </ModalBody>
    )}
  </>
);

export default ImageUpload;
