import StyledModal from '@/components/StyledModal';
import { Image, ModalBody } from '@chakra-ui/react';

type Props = {
  imageUrl: string;
  isImageViewerOpen: boolean;
  onCloseImageViewer: () => void;
};

const ImageViewerModal = ({ imageUrl, isImageViewerOpen, onCloseImageViewer }: Props) => {
  const screenHeight = window.innerHeight;

  return (
    <StyledModal
      isOpen={isImageViewerOpen}
      onClose={onCloseImageViewer}
      contentProps={{
        maxHeight: { screenHeight },
      }}
    >
      <ModalBody alignItems="center" justifyContent="center" display="flex">
        <Image
          height="100%"
          objectFit="contain"
          src={`${imageUrl}${imageUrl.includes('images.unsplash.com') ? '&w=832&dpr=2&q=65' : ''}`}
        />
      </ModalBody>
    </StyledModal>
  );
};

export default ImageViewerModal;
