import ActionRow from '@/components/ActionRow';
import AddActionButton from '@/components/AddActionButton';
import DialogModal from '@/components/DialogModal';
import { ActionList, DroppableContainer } from '@/components/DragAndDrop';
import ActionListItem from '@/components/DragAndDrop/ActionListItem';
import EmptyActionList from '@/components/EmptyActionList';
import FullScreenModal from '@/components/FullScreenModal';
import Loader from '@/components/Loader';
import ManagePersonModal from '@/components/ManagePersonModal';
import PersonAvatar from '@/components/PersonAvatar';
import SquareIconButton from '@/components/SquareIconButton';
import { ActionRelation } from '@/constants/action';
import { keys } from '@/gql/global/keys';
import { RoutesList } from '@/routes/routesList';
import { useLeveragedAndCommittedActions } from '@/services/action/hooks';
import {
  useDeletePerson,
  usePerson,
  useUpdateCommittedActionsToSingleUserOrder,
  useUpdateDiscussionPointsOrder,
  useUpdateLeveragedActionsToSingleUserOrder,
} from '@/services/people/hooks';
import { useActionModal } from '@/stores/useActionModal';
import {
  IconChevronLeft,
  IconClose,
  IconCommitPersonOutline,
  IconExpand,
  IconLeveragePersonOutline,
  IconSRPencilDraw,
  IconTrash,
  IconUncategorized,
} from '@/theme/icons';
import { Action } from '@/types/actions';
import { DiscussionPoint } from '@/types/discussionPoints';
import { getActionById } from '@/utils/action';
import { EMPTY_STATE_MIN_HEIGHT, HEADER_HEIGHT, getDiscussionPointsById } from '@/utils/people';
import rem from '@/utils/rem';
import { invalidateQueries } from '@/utils/tanStackQuery';
import { Box, Button, Flex, HStack, Icon, Text, Tooltip, VStack, useDisclosure, useToast } from '@chakra-ui/react';
import {
  AutoScrollActivator,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { isEmpty, orderBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';

import DiscussionPointItem from './DiscussionPointItem';
import DiscussionPoints from './DiscussionPoints';
import PersonNotesForm from './PersonNotesForm';

type Props = {
  id: string;
};

function PersonDetailView({ id }: Props) {
  const toast = useToast();
  const navigate = useNavigate();

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [activeItemCameFrom, setActiveItemCameFrom] = useState('');
  const [isNoteExpanded, setIsNoteExpanded] = useState(false);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const { data, isLoading, isError } = usePerson(id);
  const { data: leveragedAndCommittedData } = useLeveragedAndCommittedActions();

  const deletePerson = useDeletePerson();

  const { mutate: updateCommittedActionsToSingleUserOrderMutation } = useUpdateCommittedActionsToSingleUserOrder(id);
  const { mutate: updateLeveragedActionsToSingleUserOrderMutation } = useUpdateLeveragedActionsToSingleUserOrder(id);

  const { isOpen: isEditOpen, onClose: onEditClose, onOpen: onEditOpen } = useDisclosure({ id: 'edit' });

  const { isOpen: isDeleteOpen, onClose: onDeleteClose, onOpen: onDeleteOpen } = useDisclosure({ id: 'delete' });

  const { setActionModalOpen } = useActionModal();

  const leveraged = useMemo(
    () =>
      orderBy(
        leveragedAndCommittedData?.leveragedActions?.filter((item) =>
          item?.promises?.find(({ personId }) => personId === id),
        ),
        [
          (obj) => (obj?.promises?.[0].leverageOrder !== null ? obj?.promises?.[0].leverageOrder : undefined),
          'createdAt',
        ],
        ['asc', 'asc'],
      ),
    [leveragedAndCommittedData?.leveragedActions, id],
  );

  const committed = useMemo(
    () =>
      orderBy(
        leveragedAndCommittedData?.committedActions?.filter((item) =>
          item?.promises?.find(({ personId }) => personId === id),
        ),
        [(obj) => (obj?.promises?.[0].commitOrder !== null ? obj?.promises?.[0].commitOrder : undefined), 'createdAt'],
        ['asc', 'asc'],
      ),
    [leveragedAndCommittedData?.committedActions, id],
  );

  const handleDeletePerson = useCallback(async () => {
    await deletePerson.mutateAsync({ id });

    toast({
      title: 'Person deleted successfully',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    navigate(RoutesList.PeoplePage);
  }, [deletePerson, id, navigate, toast]);

  const { mutate: updateDiscussionPointsOrderMutation } = useUpdateDiscussionPointsOrder({
    onSuccess: () => {
      if (!id) return;
      invalidateQueries([keys.people.detail(id).queryKey]);
    },
  });

  const updateDiscussionPointsOrder = useCallback(
    (discussionPoints: DiscussionPoint[]) => {
      const mutationPayload = discussionPoints.map((discussionPoint, index) => ({
        _set: {
          order: index + 1,
        },
        where: {
          id: {
            _eq: discussionPoint.id,
          },
        },
      }));

      updateDiscussionPointsOrderMutation(mutationPayload);
    },
    [updateDiscussionPointsOrderMutation],
  );

  const updateCommittedActionsToSingleUserOrder = useCallback(
    (committed: Action[]) => {
      const mutationPayload = committed.map((actionCommitted, index) => ({
        _set: {
          commitOrder: index + 1,
        },
        where: {
          actionId: {
            _eq: actionCommitted.id,
          },
        },
      }));

      updateCommittedActionsToSingleUserOrderMutation(mutationPayload);
    },
    [updateCommittedActionsToSingleUserOrderMutation],
  );

  const updateLeveragedActionsToSingleUserOrder = useCallback(
    (leveraged: Action[]) => {
      const mutationPayload = leveraged.map((actionLeveraged, index) => ({
        _set: {
          leverageOrder: index + 1,
        },
        where: {
          actionId: {
            _eq: actionLeveraged.id,
          },
        },
      }));

      updateLeveragedActionsToSingleUserOrderMutation(mutationPayload);
    },
    [updateLeveragedActionsToSingleUserOrderMutation],
  );

  const onDragStart = ({ active }: DragStartEvent) => {
    setActiveId(active.id);
    setActiveItemCameFrom(active?.data?.current?.containerId);
    setActiveItemIndex(active?.data?.current?.index);
  };

  const onDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (!id) {
        return;
      }

      if (active.id == null) {
        setActiveId(null);
        setActiveItemCameFrom('');
        setActiveItemIndex(0);
        return;
      }

      const overId = over?.id;

      if (overId == null) {
        setActiveId(null);
        setActiveItemCameFrom('');
        setActiveItemIndex(0);
        return;
      }

      const activeIndex = active?.data?.current?.index;
      const overIndex = over?.data?.current?.index;

      const overContainerId = over?.data?.current?.containerId;

      if (activeItemCameFrom === 'discussionPoint' && overContainerId === 'discussionPoint') {
        updateDiscussionPointsOrder(arrayMove(data?.personByPk?.discussion_items ?? [], activeIndex, overIndex));
      }

      if (activeItemCameFrom === 'committed' && overContainerId === 'committed') {
        updateCommittedActionsToSingleUserOrder(arrayMove(committed, activeIndex, overIndex));
      }

      if (activeItemCameFrom === 'leveraged' && overContainerId === 'leveraged') {
        updateLeveragedActionsToSingleUserOrder(arrayMove(leveraged, activeIndex, overIndex));
      }
    },
    [
      activeItemCameFrom,
      committed,
      data?.personByPk?.discussion_items,
      id,
      leveraged,
      updateCommittedActionsToSingleUserOrder,
      updateDiscussionPointsOrder,
      updateLeveragedActionsToSingleUserOrder,
    ],
  );

  const onDragCancel = () => {
    setActiveId(null);
    setActiveItemCameFrom('');
    setActiveItemIndex(0);
  };

  const SectionItemsOverlay = useMemo(() => {
    if (!activeId || !id || !data?.personByPk) {
      return <></>;
    }

    if (activeItemCameFrom === 'discussionPoint') {
      const item = getDiscussionPointsById(data?.personByPk?.discussion_items, activeId.toString());

      if (!item) {
        return <></>;
      }

      return createPortal(
        <DragOverlay dropAnimation={null}>
          <DiscussionPointItem
            id={item?.id}
            body={item?.body}
            duration={item?.duration}
            isCompleted={item?.isCompleted}
            order={activeItemIndex + 1}
            onOpen={() => null}
            onClose={() => null}
          />
        </DragOverlay>,
        document.body,
      );
    }

    if (activeItemCameFrom === 'leveraged') {
      const item = getActionById(leveraged, activeId.toString());

      if (!item) {
        return <></>;
      }

      return createPortal(
        <DragOverlay dropAnimation={null}>
          <ActionRow action={item} idx={activeItemIndex + 1} />
        </DragOverlay>,
        document.body,
      );
    }

    if (activeItemCameFrom === 'committed') {
      const item = getActionById(committed, activeId.toString());

      if (!item) {
        return <></>;
      }

      return createPortal(
        <DragOverlay dropAnimation={null}>
          <ActionRow action={item} idx={activeItemIndex + 1} />
        </DragOverlay>,
        document.body,
      );
    }
  }, [activeId, activeItemCameFrom, activeItemIndex, committed, data?.personByPk, id, leveraged]);

  const handleGoBack = useCallback(() => {
    navigate(RoutesList.PeoplePage);
  }, [navigate]);

  const toggleNoteExpand = useCallback(() => {
    setIsNoteExpanded((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (isError && !data) {
      navigate(RoutesList.PeoplePage);
    }
  }, [data, isError, navigate]);

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center" width="full" minHeight={rem(688)}>
        <Loader />
      </Flex>
    );
  }

  return (
    <VStack alignItems="flex-start" paddingY={4}>
      <Button
        marginBottom={rem(38)}
        leftIcon={<Icon as={IconChevronLeft} boxSize={rem(14)} />}
        onClick={handleGoBack}
        paddingInline={0}
        variant="tertiary"
      >
        All People
      </Button>

      <DndContext
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragCancel={onDragCancel}
        sensors={sensors}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
        autoScroll={{
          activator: AutoScrollActivator.Pointer,
        }}
      >
        <Flex alignItems="center" justifyContent="space-between" width="full">
          <HStack alignItems="center" justifyContent="space-between" width="full" marginBottom={rem(34)}>
            <HStack gap={rem(16)}>
              <PersonAvatar name={data?.personByPk?.name} />

              <VStack alignItems="start" gap={0}>
                <Text as="h2" textStyle="h5SatMedium" lineHeight="normal" noOfLines={1}>
                  {data?.personByPk.name}
                </Text>
                {(data?.personByPk.relation || data?.personByPk.email) && (
                  <Text as="p" textStyle="small" color="text-tertiary" lineHeight="normal">
                    {[data?.personByPk.relation, data?.personByPk.email].filter(Boolean).join(' • ')}
                  </Text>
                )}
              </VStack>
            </HStack>

            <Flex gap={rem(16)}>
              <Button height={rem(28)} onClick={onEditOpen} paddingInline={rem(8)} size="sm" variant="secondary">
                <HStack alignItems="center">
                  <Icon as={IconSRPencilDraw} boxSize={rem(14)} color="text-primary" />

                  <Text textStyle="small" color="text-primary" fontWeight={900}>
                    Edit Person
                  </Text>
                </HStack>
              </Button>

              <Button
                height={rem(28)}
                borderColor="red.900"
                onClick={onDeleteOpen}
                paddingInline={rem(8)}
                size="sm"
                variant="secondary"
              >
                <HStack alignItems="center">
                  <Icon as={IconTrash} boxSize={rem(16)} color="red.500" />
                  <Text textStyle="small" color="red.500" fontWeight={900}>
                    Delete Person
                  </Text>
                </HStack>
              </Button>
            </Flex>
          </HStack>
        </Flex>

        <VStack alignItems="flex-start" gap={rem(12)} width="full" marginBottom={rem(43)}>
          <HStack alignItems="center" justifyContent="space-between" width="full">
            <HStack>
              <Flex
                alignItems="center"
                justifyContent="center"
                width={rem(24)}
                height={rem(24)}
                borderRadius={99}
                backgroundColor="background-secondary"
              >
                <Icon as={IconUncategorized} boxSize={rem(14)} />
              </Flex>
              <Text as="p" textStyle="small" color="text-primary" fontWeight={900}>
                NOTES
              </Text>
            </HStack>
            <Tooltip label="Expand Notes Field" placement="left">
              <Box display="inline-block">
                <SquareIconButton
                  onClick={toggleNoteExpand}
                  aria-label="Expand Notes Field"
                  icon={<Icon as={IconExpand} boxSize={rem(14)} />}
                />
              </Box>
            </Tooltip>
          </HStack>
          <PersonNotesForm personData={data} height={rem(82)} />
        </VStack>

        <VStack gap={rem(58)} width="full">
          <>
            <DiscussionPoints
              discussionPoints={data?.personByPk?.discussion_items ?? []}
              personId={id}
              isWithCounter={false}
            />

            {SectionItemsOverlay}
          </>

          <>
            <DroppableContainer id="leveraged" payload={{ containerId: 'leveraged' }} height="full">
              <Flex alignItems="center" justifyContent="space-between" marginBottom={rem(21)}>
                <Text as="h4" textStyle="large" color="text-primary">
                  {`Actions I've Leveraged to ${data?.personByPk.name}`}
                </Text>

                <AddActionButton
                  variant="secondary"
                  onExternalClick={() => {
                    setActionModalOpen(true, {
                      initialRelation: ActionRelation.Leverage,
                      personId: id,
                      withPlaceholder: true,
                      location: 'people',
                    });
                  }}
                  tooltipText="Add a new leveraged action"
                />
              </Flex>

              <>
                <SortableContext items={leveraged}>
                  <ActionList
                    emptyListComponent={
                      <EmptyActionList
                        minHeight={`calc(${EMPTY_STATE_MIN_HEIGHT} - ${HEADER_HEIGHT})`}
                        withIcons
                        icon={IconCommitPersonOutline}
                        title="As of now, you haven't leveraged any actions"
                        message="But remember, every moment is a chance to step up, take charge, and make things happen. Seize the day and get help to light that fire within you!"
                      />
                    }
                    isEmpty={isEmpty(leveraged)}
                  >
                    {leveraged.map((action, index) => (
                      <ActionListItem
                        key={action.id}
                        item={action}
                        index={index}
                        dndPayload={{ containerId: 'leveraged', index }}
                      />
                    ))}
                  </ActionList>
                </SortableContext>
              </>
            </DroppableContainer>
            {SectionItemsOverlay}
          </>

          <>
            <DroppableContainer id="committed" payload={{ containerId: 'committed' }} height="full">
              <Flex alignItems="center" justifyContent="space-between" marginBottom={rem(21)}>
                <Text as="h4" textStyle="large" color="text-primary">
                  {`Actions I've Committed to ${data?.personByPk.name}`}
                </Text>

                <AddActionButton
                  variant="secondary"
                  onExternalClick={() => {
                    setActionModalOpen(true, {
                      initialRelation: ActionRelation.Commit,
                      personId: id,
                      withPlaceholder: true,
                      location: 'people',
                    });
                  }}
                  tooltipText="Add a new committed action"
                />
              </Flex>

              <>
                <SortableContext items={committed}>
                  <ActionList
                    emptyListComponent={
                      <EmptyActionList
                        minHeight={`calc(${EMPTY_STATE_MIN_HEIGHT} - ${HEADER_HEIGHT})`}
                        withIcons
                        icon={IconLeveragePersonOutline}
                        title="You're at the starting line with this individual!"
                        message="As of now, you haven't committed any actions towards them. But that means you have an untouched canvas, ready for meaningful and impactful interactions. Dive deep, connect, and make it count!"
                      />
                    }
                    isEmpty={isEmpty(committed)}
                  >
                    {committed.map((action, index) => (
                      <ActionListItem
                        key={action.id}
                        item={action}
                        index={index}
                        dndPayload={{ containerId: 'committed', index }}
                      />
                    ))}
                  </ActionList>
                </SortableContext>
              </>
            </DroppableContainer>
            {SectionItemsOverlay}
          </>
        </VStack>

        <ManagePersonModal isOpen={isEditOpen} onClose={onEditClose} person={data?.personByPk} />

        <DialogModal
          isOpen={isDeleteOpen}
          title="Confirm"
          message={`Do you really want to remove ${data?.personByPk.name}?`}
          messageSize={rem(24)}
          onCancel={onDeleteClose}
          onConfirm={handleDeletePerson}
          confirmText="Remove"
          confirmVariant="danger"
          modalSize="xl"
        />
      </DndContext>

      <FullScreenModal
        isOpen={isNoteExpanded}
        onClose={toggleNoteExpand}
        title="Expanded Note Field"
        closeButton={
          <SquareIconButton onClick={toggleNoteExpand} aria-label="Close note modal" icon={<Icon as={IconClose} />} />
        }
      >
        <PersonNotesForm personData={data} fontSize={rem(14)} />
      </FullScreenModal>
    </VStack>
  );
}

export default PersonDetailView;
