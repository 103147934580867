import AddActionButton from '@/components/AddActionButton';
import IconProgressState from '@/components/IconProgressState';
import ThreeDotsBlocksButton from '@/components/ThreeDotsBlocksButton';
import { CATEGORIES_ICONS } from '@/constants/category';
import useIsDailyPlanPage from '@/services/plans/hooks/useIsDailyPlanPage';
import { useActionModal } from '@/stores/useActionModal';
import { useCalendarMonthlyStore } from '@/stores/useCalendar';
import {
  IconActionTrash,
  IconCheckedMark,
  IconChevronDown,
  IconDuplicate,
  IconExport,
  IconHourglass,
  IconIncomplete,
  IconSRPencilDraw,
  IconStarFull,
} from '@/theme/icons';
import { Action } from '@/types/actions';
import { Block } from '@/types/block';
import { getActionsByBlockId } from '@/utils/action';
import { formatDateToString } from '@/utils/calendar';
import { getColorFromToken } from '@/utils/color';
import { fixUncategorizedName, isPlanningPage } from '@/utils/index';
import rem from '@/utils/rem';
import { trackCompleteBlockSelected } from '@/utils/tracking';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Icon,
  InteractivityProps,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useDraggable } from '@dnd-kit/core';
import { isEmpty, isUndefined } from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import GradientCard from './GradientCard';

export type BlockItemProps = {
  actions: Action[];
  completedActions: ReactNode;
  incompleteActions: ReactNode;
  item: Block;
  numberOfCompletedActions: number;
  showCompletedActions?: boolean;
  starredActionsTotalDuration: string;
  totalDuration: string;
  onCompleteBlock: (blockId: string) => void;
  onSelectBlock: () => void;
  onUncompleteBlock: (blockId: string) => void;
  openSetUncompletedActionsModal: () => void;
  openEditBlockModal: () => void;
  openDuplicateBlockModal: () => void;
  onClickAddAction?: (blockId: string) => void;
  onDeleteBlock: (block?: Block) => void;
  draggable?: boolean;
  isDragOverlay?: boolean;
  collapsedByDefault?: boolean;
};

function BlockItem({
  actions,
  completedActions: CompletedActions,
  incompleteActions: IncompleteActions,
  item,
  numberOfCompletedActions,
  onCompleteBlock,
  showCompletedActions = true,
  starredActionsTotalDuration,
  totalDuration,
  onClickAddAction,
  onSelectBlock,
  onUncompleteBlock,
  openSetUncompletedActionsModal,
  openEditBlockModal,
  openDuplicateBlockModal,
  onDeleteBlock,
  draggable = false,
  isDragOverlay = false,
  collapsedByDefault = false,
}: BlockItemProps) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(!collapsedByDefault);

  useEffect(() => {
    setIsAccordionOpen(!collapsedByDefault);
  }, [collapsedByDefault]);

  const handleAccordionToggle = () => {
    setIsAccordionOpen((prevState) => !prevState);
  };

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: item?.id ?? Date.now(),
    data: { item, containerId: 'blockHeader' },
    disabled: !draggable,
  });

  // "attributes" and "listeners" props always convert the element to a button,
  // even when disabled:true is passed to useDraggable. For this reason, we
  // pass attributes and listeners only when draggable is true.
  const draggableProps = draggable
    ? {
        ...attributes,
        ...listeners,
        cursor: 'grab' satisfies InteractivityProps['cursor'],
      }
    : {};

  const categoryColor = item.category.color ?? 'gray.300';
  const opacity = item.isCompleted ? 0.5 : 1;

  // TODO: for blocks spanning over multiple days, completing actions in one day was triggering this modal to
  // prompt user to mark block as completed, even if there were incomplete actions in other days; it's hard,
  // maybe impossible, to fix while we have the weekly context; disabled for now to mitigate the issue, we need to decide
  // if we want to restore it or delete it

  // const setIsCompleted = useActionProgressStatus((state) => state.setIsCompleted);
  // const lastActionCompleted = useActionProgressStatus((state) => state.isCompleted);
  // const blockToCompleteId = useCompleteBlock((state) => state.blockId);
  // const setBlockToCompleteId = useCompleteBlock((state) => state.setBlockId);

  const { setActionModalOpen } = useActionModal();

  // const {
  //   isOpen: isOpenSetBlockAsCompleteModal,
  //   onOpen: openSetBlockAsCompleteModal,
  //   onClose: onCloseSetBlockAsCompleteModal,
  // } = useDisclosure({
  //   onClose: () => {
  //     setBlockToCompleteId(null);
  //   },
  // });

  // const handleCompleteBlockFromCompletedAction = useCallback(() => {
  //   onCompleteBlock(item.id);
  //   // onCloseSetBlockAsCompleteModal();
  //   setIsCompleted(false);
  //   trackBlockCompleted();
  // }, [item.id, onCompleteBlock, setIsCompleted]);

  const onSelectedBlockModal = useCallback(() => {
    onSelectBlock();
    openSetUncompletedActionsModal();
  }, [onSelectBlock, openSetUncompletedActionsModal]);

  const handleToggleCompleteBlock = useCallback(() => {
    const action = getActionsByBlockId(item.id, actions);

    if (item.isCompleted && isEmpty(action.filter((action) => action.progressStatus !== 'complete'))) {
      onUncompleteBlock(item.id);
      return;
    }

    trackCompleteBlockSelected();

    if (!item.isCompleted && isEmpty(action.filter((action) => action.progressStatus !== 'complete'))) {
      onCompleteBlock(item.id);
      return;
    }

    onSelectedBlockModal();
  }, [actions, item.id, item.isCompleted, onCompleteBlock, onSelectedBlockModal, onUncompleteBlock]);

  const onAddAction = useCallback(() => {
    if (onClickAddAction) {
      onClickAddAction(item.id);
      return;
    }

    setActionModalOpen(true, {
      blockId: item.id,
      categoryId: item.categoryId,
      projectId: item.projectId,
      showProjectSelector: false,
      readonlyCategory: true,
      readonlyProject: true,
      location: 'block',
    });
  }, [item.categoryId, item.id, item.projectId, setActionModalOpen, onClickAddAction]);

  // useEffect(() => {
  //   if (lastActionCompleted && blockToCompleteId === item.id && !item.isCompleted) {
  //     openSetBlockAsCompleteModal();
  //   }
  // }, [item.id, blockToCompleteId, lastActionCompleted, openSetBlockAsCompleteModal, item.isCompleted]);

  const location = useLocation();
  const isDailyPlanPage = useIsDailyPlanPage();
  const { selectedDate } = useCalendarMonthlyStore();

  const view = useMemo(() => {
    let view = 'all';
    if (isDailyPlanPage) {
      view = 'day';
    } else if (isPlanningPage(location)) {
      view = 'week';
    }
    return view;
  }, [isDailyPlanPage, location]);

  const menuItems = [
    {
      title: 'Edit',
      icon: IconSRPencilDraw,
      onClick: (block?: Block) => {
        if (isUndefined(block)) {
          return;
        }

        openEditBlockModal();
        onSelectBlock();
      },
    },
    {
      title: 'Duplicate Block',
      icon: IconDuplicate,
      onClick: (block?: Block) => {
        if (isUndefined(block)) {
          return;
        }

        openDuplicateBlockModal();
        onSelectBlock();
      },
    },
    {
      title: 'Delete',
      icon: IconActionTrash,
      onClick: onDeleteBlock,
      iconColor: 'red.500',
    },
    {
      title: item.isCompleted ? 'Incomplete' : 'Complete',
      icon: item.isCompleted ? IconIncomplete : IconCheckedMark,
      onClick: handleToggleCompleteBlock,
    },
    {
      title: 'Export',
      icon: IconExport,
      onClick: (block?: Block) =>
        block?.id &&
        window.open(
          `${window.origin}/print/block/${block.id}?view=${view}&date=${formatDateToString(selectedDate)}`,
          '_blank',
        ),
    },
  ];

  return (
    <Box
      sx={{
        '@media print': {
          bgGradient: 'unset',
          backgroundColor: 'print-background',
        },
      }}
      width="full"
      marginBottom={rem(12)}
      padding={rem(16)}
      bgGradient={`linear-gradient(270deg, ${getColorFromToken(categoryColor, '0.10')} 0%, var(--chakra-colors-background-primary) 66.97%)`}
      borderWidth={1}
      borderStyle="solid"
      borderColor={getColorFromToken(categoryColor, opacity.toString())}
      borderRadius="base"
    >
      <Accordion allowToggle index={isAccordionOpen ? 0 : -1} reduceMotion>
        <AccordionItem>
          <Box>
            <Flex justifyContent="space-between" width="full">
              <Flex alignItems="center" flex="1" opacity={opacity} {...draggableProps} ref={setNodeRef}>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  width={rem(14)}
                  height={rem(14)}
                  marginRight={rem(4)}
                  borderRadius="full"
                  aspectRatio={1}
                >
                  {item.isCompleted ? (
                    <IconProgressState state="complete" dimension={rem(14)} onClick={handleToggleCompleteBlock} />
                  ) : (
                    <Icon
                      as={CATEGORIES_ICONS?.[item?.category?.icon ?? 'uncategorized']}
                      width={rem(14)}
                      height={rem(14)}
                      color={categoryColor}
                    />
                  )}
                </Flex>
                <Tooltip label={fixUncategorizedName(item?.category?.name)} placement="top">
                  <Text
                    textStyle="xsmall"
                    overflow="hidden"
                    paddingRight={rem(6)}
                    color="text-primary"
                    textTransform="uppercase"
                    noOfLines={1}
                  >
                    {fixUncategorizedName(item?.category?.name)}
                  </Text>
                </Tooltip>
              </Flex>
              <HStack>
                <Tooltip label="Time remaining to complete all of your starred actions" placement="top">
                  <Flex alignItems="center" minWidth={rem(30)} opacity={opacity}>
                    <Icon as={IconStarFull} boxSize={rem(12)} color="gray.300" />
                    <Text textStyle="xsmall" marginLeft={rem(4)} color="text-primary">
                      {starredActionsTotalDuration || '0h'}
                    </Text>
                  </Flex>
                </Tooltip>
                <Tooltip label="Time remaining to complete all of your actions" placement="top">
                  <Flex alignItems="center" opacity={opacity}>
                    <Icon as={IconHourglass} boxSize={rem(12)} color="gray.300" />
                    <Text textStyle="xsmall" marginLeft={rem(4)} color="text-primary">
                      {totalDuration || '0h'}
                    </Text>
                  </Flex>
                </Tooltip>
                {!isDragOverlay && (
                  <Flex alignItems="center" marginLeft={rem(8)}>
                    <ThreeDotsBlocksButton
                      height={rem(14)}
                      width={rem(14)}
                      minWidth={rem(14)}
                      block={item}
                      items={menuItems}
                    />
                    <AccordionButton
                      display="flex"
                      width={rem(14)}
                      height={rem(14)}
                      marginLeft={rem(8)}
                      padding="0"
                      onClick={handleAccordionToggle}
                    >
                      <AccordionIcon as={IconChevronDown} width={rem(14)} height={rem(14)} color="text-primary" />
                    </AccordionButton>
                  </Flex>
                )}
              </HStack>
            </Flex>
            <GradientCard title="Result" body={item.result} categoryColor={item.category.color} opacity={opacity} />
          </Box>
          <AccordionPanel padding="0" opacity={item.isCompleted ? 0.5 : 1}>
            <GradientCard title="Purpose" body={item.purpose} categoryColor={item.category.color} opacity={opacity} />
            <Flex flexDirection="column" marginTop={rem(16)}>
              {IncompleteActions}
            </Flex>
            <Flex justifyContent="flex-end" marginTop={rem(12)}>
              <AddActionButton
                backgroundColor={item.category.color}
                blockIsCompleted={item.isCompleted}
                onExternalClick={onAddAction}
                minWidth={rem(24)}
                width={rem(24)}
                height={rem(24)}
                iconSize={rem(10)}
              />
            </Flex>
            {showCompletedActions && (
              <Accordion marginTop={rem(16)} allowToggle reduceMotion>
                <AccordionItem>
                  <AccordionButton gap={rem(4)} padding="0">
                    <Flex textStyle="xsmall" flex="1" textAlign="left">
                      <Text textStyle="xsmall" color="text-secondary" textTransform="uppercase">
                        {numberOfCompletedActions} Completed Actions
                      </Text>
                    </Flex>
                    <AccordionIcon as={IconChevronDown} width={rem(14)} height={rem(14)} color="text-primary" />
                  </AccordionButton>
                  <AccordionPanel marginTop={rem(8)} padding={0} paddingBottom={4}>
                    {CompletedActions}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
            {/* {isOpenSetBlockAsCompleteModal && (
              <CompleteBlockModal
                isOpen={isOpenSetBlockAsCompleteModal}
                onClose={onCloseSetBlockAsCompleteModal}
                onSubmit={handleCompleteBlockFromCompletedAction}
              />
            )} */}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
}

export default BlockItem;
