import { DeleteOptions } from '@/components/DeleteActionModal/DeleteDuplicatedActionModal';
import { COMPLETE } from '@/constants/action';
import { QuickCreateActionPayload } from '@/gql/actions/types';
import { keys } from '@/gql/global/keys';
import {
  Action,
  ActionUpdateWhere,
  DeleteActionVariables,
  DeleteAllActionsVariables,
  DeleteFutureActionsVariables,
  MutationFunction,
} from '@/types/actions';
import { Category } from '@/types/category';
import { formatDateToString, utcToLocalDate } from '@/utils/calendar';
import { filter, orderBy } from 'lodash';
import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { getSecondsFromDuration, humanDuration } from '.';
import { getMonthlyCalendarQueryKey } from './query';
import { invalidateQueries, refetchQueries } from './tanStackQuery';

export type OrderFieldType = 'categoryOrder' | 'projectOrder';

export function calculateTotalMinutesBasedOnDuration(duration?: string | null) {
  const [hour, minute] = (duration || '00:00:00').split(':');
  return Number(hour) * 60 + Number(minute);
}

export function calculateTotalDuration(actions: Action[]) {
  return actions.reduce((acc, action) => {
    return acc + calculateTotalMinutesBasedOnDuration(action.duration || '00:00:00');
  }, 0);
}

export function getTotalDurationOfActions(actions: Action[]) {
  const totalDuration = calculateTotalDuration(actions);

  const durationToHours = String(Math.floor(totalDuration / 60)).padStart(2, '0');
  const durationToMinutes = String(totalDuration % 60).padStart(2, '0');

  return `${durationToHours}:${durationToMinutes}`;
}

export function getTotalDurationOfIncompleteActions(actions: Action[]) {
  const incompleteActions = actions.filter((action) => action.progressStatus !== COMPLETE);
  return getTotalDurationOfActions(incompleteActions);
}

// @TODO: created this while waiting for PO green light about the final duration type
export function getTotalDurationOfActionsExtendedFormat(action: Action[]) {
  const minutes = action?.reduce((acc, curr) => acc + getSecondsFromDuration(curr.duration || '00:00:00'), 0);

  if (minutes === 0) {
    return '0h';
  }

  return humanDuration(minutes);
}

export function getTotalDurationOfPreferredActions(actions: Action[]) {
  return getTotalDurationOfActions(actions.filter((action) => action.dateOfStarring !== null));
}

export function getTotalDurationOfPreferredIncompleteActions(actions: Action[]) {
  const incompleteActions = actions.filter(
    (action) => action.progressStatus !== COMPLETE && action.dateOfStarring !== null,
  );
  return getTotalDurationOfActions(incompleteActions);
}

export function getDurationInMinutes(action: Action): number {
  const [hour, minute] = (action.duration || '00:00:00').split(':');
  return parseInt(hour, 10) * 60 + parseInt(minute, 10);
}

export function getCategoryData(categories?: Category[], key?: string) {
  const categoryId = filter(categories, (datum) => {
    if (datum.name === key) {
      return datum;
    }
  });
  return categoryId[0];
}

export function createActionUpdateWhere(actions: Action[], categoryId: string): ActionUpdateWhere[] {
  const actionUpdateWhere = actions?.map((action) => ({
    _set: {
      categoryId: categoryId,
      blockOrder: action.blockOrder ?? null,
      blockId: action.blockId ?? null,
    },
    where: {
      id: {
        _eq: action.id,
      },
    },
  }));

  return actionUpdateWhere;
}

export const refetchActions = (action: Action, weeklyPlanId?: string | null) => {
  const weeklyPlanToUse = weeklyPlanId && action.weeklyPlanId !== weeklyPlanId ? weeklyPlanId : action.weeklyPlanId;

  const invalidateWeeklyPlans = () => {
    if (weeklyPlanId && action.weeklyPlanId !== weeklyPlanId) {
      refetchQueries([keys.actions.all._ctx.week(action.weeklyPlanId as string).queryKey]);
    }

    refetchQueries([keys.actions.all._ctx.week(weeklyPlanToUse as string).queryKey, getMonthlyCalendarQueryKey()]);
  };

  const invalidateCategory = () => {
    const categoryIdFromPath = location.pathname.match(/category-manager\/([\w-]+)/)?.[1];
    const categoryId = categoryIdFromPath;

    if (action.categoryId) {
      if (categoryId) {
        invalidateQueries([keys.actions.all._ctx.categoryManager(categoryId).queryKey]);
      }
      if (categoryId !== action.categoryId) {
        invalidateQueries([keys.actions.all._ctx.categoryManager(action.categoryId).queryKey]);
      }
    }
  };

  const invalidateProjects = () => {
    const projectIdFromPath = location.pathname.match(/\/projects\/([\w-]+)/)?.[1];
    const projectId = action.projectId || projectIdFromPath;

    if (action.projectId) {
      if (projectId) {
        invalidateQueries([keys.project.detail(projectId).queryKey]);
      }
      if (projectId !== action.projectId) {
        invalidateQueries([keys.project.detail(action.projectId).queryKey]);
      }
    }
  };

  const invalidatePromises = () => {
    if (action.promises) {
      refetchQueries([keys.actions.leveragedAndCommitted.queryKey]);
    }
  };

  invalidateWeeklyPlans();
  invalidateCategory();
  invalidateProjects();
  invalidatePromises();
};

export const useHandleDeleteDuplicatedAction = (
  deleteAction: MutationFunction<DeleteActionVariables>,
  deleteFutureActions: MutationFunction<DeleteFutureActionsVariables>,
  deleteAllActions: MutationFunction<DeleteAllActionsVariables>,
  action: Action | null,
) => {
  const handleDeleteDuplicatedAction = useCallback(
    (selectedOption: DeleteOptions | null) => {
      if (!action?.id) return;

      switch (selectedOption) {
        case DeleteOptions.THIS_ACTION:
          deleteAction.mutate({ actionId: action.id });
          break;

        case DeleteOptions.THIS_AND_FOLLOWING_ACTIONS:
          deleteFutureActions.mutate({
            seriesId: action?.seriesId || '',
            scheduledDate: action.scheduledDate,
          });
          break;

        case DeleteOptions.ALL_ACTIONS:
          deleteAllActions.mutate({ seriesId: action.seriesId || '' });
          break;

        default:
          console.warn('No delete option selected');
      }
    },
    [deleteAction, deleteFutureActions, deleteAllActions, action?.id],
  );

  return { handleDeleteDuplicatedAction };
};

export function removeCurrentFromActionsList(actions: Action[], currentId: string) {
  return actions.filter((item) => item.id !== currentId);
}

export function getIncompleteActions(actions: Action[], iterates = 'categoryOrder', orders: 'asc' | 'desc' = 'asc') {
  return orderBy(
    actions.filter((item) => item.progressStatus !== COMPLETE),
    iterates,
    orders,
  );
}

export function getCompletedActions(actions: Action[], iterates = 'categoryOrder', orders: 'asc' | 'desc' = 'asc') {
  return orderBy(
    actions.filter((item) => item.progressStatus === COMPLETE),
    iterates,
    orders,
  );
}

export function getActionsByCategoryId(actions: Action[], categoryId: string | null) {
  return actions.filter((action) => action.categoryId === categoryId);
}

export function getActionsNotMatchCategoryId(actions: Action[], categoryId: string | null) {
  return actions.filter((action) => action.categoryId !== categoryId);
}

export function updateActionsOrder(actions: Action[], orderField: OrderFieldType): Action[] {
  return actions.map((action, index) => {
    return {
      ...action,
      [orderField]: index + 1,
    };
  });
}
function findNearestIncompleteItem(actions: Action[], updatedActionOrder: number, orderField: OrderFieldType) {
  return actions.reduce((nearestItem, currentItem) => {
    const currentItemOrder = currentItem[orderField] ?? 0;
    const nearestItemOrder = nearestItem[orderField] ?? 0;

    if (currentItemOrder < updatedActionOrder && currentItemOrder > nearestItemOrder) {
      return currentItem;
    }
    return nearestItem;
  }, actions[0]);
}

function updateOrderByNearestIncompleteItem(
  actions: Action[],
  nearestIncompleteItemOrder: number,
  orderField: OrderFieldType,
) {
  return actions.map((item) => {
    const order = item[orderField] ?? 0;

    if (order > nearestIncompleteItemOrder) {
      return { ...item, [orderField]: order + 1 };
    }
    return item;
  });
}

function findIndexById(actions: Action[], actionId: string) {
  return actions.findIndex((item) => item.id === actionId);
}

function fixCollisionOrder(actions: Action[], orderField: OrderFieldType) {
  return actions.map((item, index) => ({
    ...item,
    [orderField]: index + 1,
  }));
}

function sortByOrder(actions: Action[], orderField: OrderFieldType) {
  return actions.sort((a, b) => {
    const aOrder = a[orderField] ?? 0;
    const bOrder = b[orderField] ?? 0;

    return aOrder - bOrder;
  });
}

export function updateOrderOnIncompleteAction(actions: Action[], updatedAction: Action, orderField: OrderFieldType) {
  const incompleteItems = getIncompleteActions(actions);

  if (incompleteItems.length === 0) {
    let modifiedItems = updateOrderByNearestIncompleteItem(
      removeCurrentFromActionsList(actions, updatedAction.id),
      0,
      orderField,
    );
    modifiedItems = sortByOrder([...modifiedItems, { ...updatedAction, [orderField]: 1 }], orderField);

    return fixCollisionOrder(modifiedItems, orderField);
  }

  const nearestIncompleteItem = findNearestIncompleteItem(incompleteItems, updatedAction[orderField] ?? 0, orderField);

  const nearestIncompleteItemOrder = nearestIncompleteItem[orderField] ?? 0;

  const modifiedItems = updateOrderByNearestIncompleteItem(actions, nearestIncompleteItemOrder, orderField);

  const updatedItemIndex = findIndexById(actions, updatedAction.id);

  modifiedItems[updatedItemIndex] = {
    ...updatedAction,
    [orderField]: nearestIncompleteItemOrder + 1,
  };

  return fixCollisionOrder(sortByOrder(modifiedItems, orderField), orderField);
}

export function hasProgressStatusUpdated(actions: Action[], updatedAction: Action) {
  const cachedAction = actions.find((action) => action.id === updatedAction.id);
  return updatedAction.progressStatus !== cachedAction?.progressStatus;
}

export function onCompleteAction(actions: Action[], updatedAction: Action, orderField: OrderFieldType) {
  if (!hasProgressStatusUpdated(actions, updatedAction)) {
    return actions;
  }

  let filteredActions = orderBy(getActionsByCategoryId(actions, updatedAction.categoryId), orderField, 'asc');
  filteredActions = updateActionsOrder(removeCurrentFromActionsList(filteredActions, updatedAction.id), orderField);

  const sortedActions = [...filteredActions, updatedAction];

  const rest = getActionsNotMatchCategoryId(actions, updatedAction.categoryId);

  return [...rest, ...sortedActions];
}

export function onIncompleteAction(actions: Action[], updatedAction: Action, orderField: OrderFieldType) {
  if (!hasProgressStatusUpdated(actions, updatedAction)) {
    return actions;
  }

  let filteredActions = getActionsByCategoryId(actions, updatedAction.categoryId);

  const sortedActions = updateOrderOnIncompleteAction(filteredActions, updatedAction, orderField);

  const rest = getActionsNotMatchCategoryId(actions, updatedAction.categoryId);

  return [...rest, ...sortedActions];
}

export function getActionById(actions: Action[], actionId: string) {
  return actions.find((action) => action.id === actionId);
}

export function getNonBlockActionsByCategoryId(actions: Action[], categoryId: string) {
  return getActionsByCategoryId(
    actions.filter((action) => action.blockId === null),
    categoryId,
  );
}

export function getDailyActions(actions: Action[], selectedDate: Date) {
  return actions.filter((action) => {
    if (!action.scheduledDate) {
      return false;
    }

    const eventDate = utcToLocalDate(action.scheduledDate, action.scheduledTime, action.timezone, action.gmtOffset);

    return formatDateToString(eventDate) === formatDateToString(selectedDate);
  });
}

export function getWeeklyActions(actions: Action[], weeklyPlanId: string) {
  return actions.filter((action) => action.weeklyPlanId === weeklyPlanId);
}

export function getIncompleteActionsByBlockId(blockId: string, actions: Action[]) {
  return actions.filter((action) => action.blockId === blockId && action.progressStatus !== COMPLETE);
}

export function getActionsByBlockId(blockId: string, actions: Action[]): Action[] {
  const blockActions = actions.filter((action) => action.blockId === blockId);
  return blockActions.map((action) => ({ ...action, isLastBlockAction: blockActions.length === 1 }));
}

export function createActionDuplicate(
  source: Action,
  scheduledDate: string,
  scheduledTime: string | null,
  weeklyPlanId: string,
) {
  const action: QuickCreateActionPayload = {
    id: uuidv4(),
    categoryId: source.categoryId as string,
    dateOfStarring: source.dateOfStarring,
    duration: source.duration as string,
    isLocked: source.isLocked,
    notes: source.notes,
    progressStatus: source.progressStatus,
    projectId: source.projectId ?? null,
    scheduledDate,
    scheduledTime,
    timezone: source.timezone,
    title: source.title,
    gmtOffset: source.gmtOffset,
    weeklyPlanId,
    seriesId: source.seriesId ?? source.id,
  };
  if (source.blockId) {
    action.blockId = source.blockId;
  }
  return action;
}
