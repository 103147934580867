import StyledModal from '@/components/StyledModal';
import { Button, ModalBody, ModalFooter, Text } from '@chakra-ui/react';

type Props = {
  isOpen: boolean;
  title: string;
  message: string;
  cancelText?: string;
  confirmText?: string;
  confirmVariant?: string;
  onCancel: () => void;
  onConfirm: () => void;
  modalSize?: string;
  messageSize?: string;
  isButtonConfirmLoading?: boolean;
};

const DialogModal = ({
  isOpen,
  title,
  message,
  cancelText = 'Cancel',
  confirmText = 'OK',
  confirmVariant = 'primary',
  onCancel,
  onConfirm,
  modalSize = 'xl',
  messageSize = 'lg',
  isButtonConfirmLoading = false,
}: Props) => {
  return (
    <StyledModal isOpen={isOpen} onClose={onCancel} size={modalSize} title={title}>
      <ModalBody>
        <Text color="text-secondary" fontSize={messageSize} fontWeight={500}>
          {message}
        </Text>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel} size="lg" variant="secondary">
          {cancelText}
        </Button>
        <Button isLoading={isButtonConfirmLoading} onClick={onConfirm} size="lg" variant={confirmVariant}>
          {confirmText}
        </Button>
      </ModalFooter>
    </StyledModal>
  );
};

export default DialogModal;
