import StyledModal from '@/components/StyledModal';
import { Button, Checkbox, Flex, ModalBody, ModalFooter, Text } from '@chakra-ui/react';
import { useState } from 'react';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (deletedOption: DeleteOptions | null) => void;
};

export enum DeleteOptions {
  THIS_ACTION = 'this_action',
  THIS_AND_FOLLOWING_ACTIONS = 'this_and_following_actions',
  ALL_ACTIONS = 'all_actions',
}

const DeleteDuplicatedActionModal = ({ isOpen, onCancel, onConfirm }: Props) => {
  const [selectedOption, setSelectedOption] = useState<DeleteOptions | null>(null);

  const handleCheckboxChange = (option: DeleteOptions) => {
    setSelectedOption(option);
  };

  return (
    <StyledModal isOpen={isOpen} onClose={onCancel} size="xl" title="Delete Actions">
      <ModalBody>
        <Flex flexDirection="column" gap={2} marginTop={3}>
          <Checkbox
            isChecked={selectedOption === DeleteOptions.THIS_ACTION}
            onChange={() => handleCheckboxChange(DeleteOptions.THIS_ACTION)}
            size="sm"
            variant="round"
          >
            <Text as="span" textStyle="large">
              This action
            </Text>
          </Checkbox>
          <Checkbox
            isChecked={selectedOption === DeleteOptions.THIS_AND_FOLLOWING_ACTIONS}
            onChange={() => handleCheckboxChange(DeleteOptions.THIS_AND_FOLLOWING_ACTIONS)}
            size="sm"
            variant="round"
          >
            <Text as="span" textStyle="large">
              This and following actions
            </Text>
          </Checkbox>
          <Checkbox
            isChecked={selectedOption === DeleteOptions.ALL_ACTIONS}
            onChange={() => handleCheckboxChange(DeleteOptions.ALL_ACTIONS)}
            size="sm"
            variant="round"
          >
            <Text as="span" textStyle="large">
              All actions
            </Text>
          </Checkbox>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onCancel} size="lg" variant="secondary">
          Cancel
        </Button>
        <Button
          isDisabled={!selectedOption}
          onClick={() => onConfirm(selectedOption || null)}
          size="lg"
          variant="danger"
        >
          Confirm
        </Button>
      </ModalFooter>
    </StyledModal>
  );
};

export default DeleteDuplicatedActionModal;
