import StyledModal from '@/components/StyledModal';
import { keys } from '@/gql/global/keys';
import { fetchRestData } from '@/services/ApiRest';
import { memoizedRegenerateCookie } from '@/services/graphql';
import { SIGN_IN_URL } from '@/services/user/hooks';
import { ImageInfo } from '@/types/inspirations';
import { trackImageFromComputerSelected } from '@/utils/tracking';
import { Box } from '@chakra-ui/react';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { createApi } from 'unsplash-js';
import { z } from 'zod';

import ImageTabs from './ImageTabs';
import ImageUpload from './ImageUpload';
import UnsplashImagesGrid from './UnsplashPhotoGrid';
import UnsplashSearchForm from './UnsplashSearchForm';

const UnsplashModalFormSchema = z.object({
  query: z.string().trim().min(1, { message: 'Name requires at least 1 character' }).max(100),
});

type FormData = z.infer<typeof UnsplashModalFormSchema>;

type Props = {
  isImagesModalOpen: boolean;
  onCloseImagesModal: () => void;
  onInsertInspirationImage: (imageInfo: ImageInfo) => void;
  isSavingImage: boolean;
  setIsSavingImage: (val: boolean) => void;
};

export type Images = {
  full: string;
  raw: string;
  regular: string;
  small: string;
  small_s3?: string;
  thumb: string;
};

export interface UnsplashPhoto {
  id: string;
  links: {
    download_location: string;
  };
  urls: Images;
  user: {
    links: {
      html: string;
    };
    name: string;
  };
}

interface UnsplashResponse {
  results: UnsplashPhoto[];
  total_pages: number;
}

const unsplash = () =>
  createApi({
    apiUrl: import.meta.env.VITE_GRAPHQL_API_URL.replace('/v1/graphql', '/api/rest/unsplash'),
    credentials: 'include' as RequestCredentials,
  });

async function getImageMimeType(imageUrl: string): Promise<string> {
  const response = await fetch(imageUrl);
  const contentType = response.headers.get('content-type');
  return contentType!.split('/')[1];
}

const trackUnsplashDownload = async (url: string) => {
  unsplash().photos.trackDownload({ downloadLocation: url });
};

const CoverImageModal = ({
  isImagesModalOpen,
  onCloseImagesModal,
  onInsertInspirationImage,
  isSavingImage = false,
  setIsSavingImage,
}: Props) => {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const { ref: loadMoreButtonRef, inView } = useInView();
  const [tabIndex, setTabIndex] = useState(0);
  const [imageSrc, setImageSrc] = useState<string | null | ArrayBuffer>(null);
  const [imageError, setImageError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState<File>();

  async function handleFetchWithRetry<T>(
    fetchFn: () => Promise<T>,
    extractDataFn: (response: T) => T | null,
  ): Promise<T> {
    try {
      const rawResponse = await fetchFn();

      if (!rawResponse || (rawResponse as any).type === 'error') {
        throw new Error('Error fetching data');
      }

      const data = extractDataFn(rawResponse);

      if (!data) {
        throw new Error('Invalid response from Unsplash');
      }

      return data;
    } catch (error) {
      if (error instanceof Error && error.message.includes('Invalid response from Unsplash')) {
        try {
          await memoizedRegenerateCookie();

          const retryResponse = await fetchFn();

          if (!retryResponse || (retryResponse as any).type === 'error') {
            throw new Error('Error fetching data after retry');
          }

          const retryData = extractDataFn(retryResponse);

          if (!retryData) {
            throw new Error('Invalid response from Unsplash after retry');
          }

          return retryData;
        } catch (retryError) {
          console.log('🔒 Failed to regenerate the cookie, redirecting to sign-in page');
          window.location.assign(SIGN_IN_URL);
          return Promise.reject(new Error('Cookie regeneration failed'));
        }
      } else {
        throw error;
      }
    }
  }

  function extractSearchData(response: any): UnsplashResponse | null {
    const responseData = (response as { response?: { unsplashProxy?: typeof response } })?.response?.unsplashProxy;
    return responseData as unknown as UnsplashResponse | null;
  }

  function extractRandomData(response: any): UnsplashPhoto[] | null {
    const results = (response as { response?: { unsplashProxy?: typeof response } })?.response?.unsplashProxy;
    return results as unknown as UnsplashPhoto[] | null;
  }

  async function fetchUnsplashPhotos(page: number): Promise<UnsplashResponse> {
    const fetchFn = async () => {
      const response = await unsplash().search.getPhotos({
        query: searchQuery,
        page,
        perPage: 20,
        orientation: 'landscape',
      });

      return response;
    };

    const data = await handleFetchWithRetry(fetchFn, extractSearchData);
    const { results, total_pages } = data;

    return {
      results,
      total_pages,
    };
  }

  async function fetchRandomUnsplashPhotos(): Promise<{ results: UnsplashPhoto[]; total_pages: number }> {
    const fetchFn = async () => {
      const response = await unsplash().photos.getRandom({
        count: 20,
        orientation: 'landscape',
      });

      return response;
    };

    const results = await handleFetchWithRetry(fetchFn, extractRandomData);

    return {
      results,
      total_pages: 1,
    };
  }

  const { data: randomUnsplashImages, isLoading: isFetchingRandomImages } = useQuery({
    ...keys.unsplash.all,
    queryFn: fetchRandomUnsplashPhotos,
    enabled: isImagesModalOpen && !searchQuery,
  });

  const { data, error, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    keys.unsplash.list(searchQuery).queryKey,
    ({ pageParam = 1 }) => fetchUnsplashPhotos(pageParam),
    {
      enabled: isImagesModalOpen && searchQuery.length > 1,
      getNextPageParam: (lastPage) => {
        const nextPage = page + 1;
        return nextPage <= lastPage.total_pages ? nextPage : undefined;
      },
    },
  );

  const onCancel = useCallback(() => {
    onCloseImagesModal();
    setPage(1);
    setSearchQuery('');
    setTabIndex(0);
    setImageSrc(null);
    setImageError(null);
  }, [onCloseImagesModal]);

  const onSubmit = useCallback((form: FormData) => {
    setPage(1);
    setSearchQuery(form.query);
  }, []);

  const onImageSelection = useCallback(
    async (photo: UnsplashPhoto) => {
      setIsSavingImage(true);

      const mimeType = await getImageMimeType(photo?.urls?.raw);

      trackUnsplashDownload(photo.links.download_location);

      onInsertInspirationImage({
        imageUrl: photo?.urls?.raw,
        mimeType: mimeType ?? '',
      });
    },
    [onInsertInspirationImage, setIsSavingImage],
  );

  useEffect(() => {
    if (inView && !!searchQuery) {
      fetchNextPage();
      setPage((prevState) => prevState + 1);
    }
  }, [fetchNextPage, inView, searchQuery]);

  const handleFileUpload = (file: File | null) => {
    if (file) {
      const validTypes = ['image/jpeg', 'image/png', 'image/webp'];
      const maxSizeInBytes = 5 * 1024 * 1024; // 5MB

      if (!validTypes.includes(file.type)) {
        setImageError('Only JPEG, PNG or WEBP files are allowed.');
        setImageSrc(null);
        return;
      }

      if (file.size > maxSizeInBytes) {
        setImageError('File size must be less than or equal to 5MB.');
        setImageSrc(null);
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target) {
          setImageSrc(e.target.result);
          setImageError(null);
        }
      };
      reader.readAsDataURL(file);
      setFile(file);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    handleFileUpload(file);
  };

  const directUploadStart = async ({ fileName, fileType }: any) => {
    const data = await fetchRestData(`${import.meta.env.VITE_REST_API_URL}/uploads`, {
      method: 'POST',
      data: { file_name: fileName, file_type: fileType },
    });

    return data;
  };

  const directUploadDo = async ({ data, file }: { data: any; file: File }) => {
    const postData = new FormData();

    for (const key in data?.fields) {
      postData.append(key, data.fields[key]);
    }

    postData.append('file', file);

    await fetchRestData(data.url, {
      method: 'POST',
      data: postData,
      withCredentials: false,
    });

    return { imageUrl: `${data.url}${data.fields['key']}` };
  };

  const handleApply = async (imageFromComputer?: boolean) => {
    setIsUploading(true);

    if (imageFromComputer) {
      trackImageFromComputerSelected();
    }

    if (!file) {
      console.error('No file selected for upload.');
      return;
    }

    try {
      const dataFromBE = await directUploadStart({
        fileName: file?.name,
        fileType: file?.type,
      });

      const { imageUrl } = await directUploadDo({ data: dataFromBE, file: file });
      onInsertInspirationImage({
        imageUrl,
        mimeType: file.type ?? '',
      });
      onCancel();
      console.log(`File upload completed! ${imageUrl}`);
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleChangeImage = () => {
    setImageSrc(null);
    setImageError(null);
  };

  if (error) {
    return <Box>Error fetching photos</Box>;
  }

  return (
    <StyledModal
      contentProps={{ h: 'calc(100% - 7.5rem)' }}
      title="Change cover image"
      isOpen={isImagesModalOpen}
      onClose={onCancel}
      scrollBehavior="inside"
      size="5xl"
      showCloseButton
    >
      <ImageTabs tabIndex={tabIndex} setTabIndex={setTabIndex} />
      {tabIndex == 0 ? (
        <>
          <UnsplashSearchForm
            searchQuery={searchQuery}
            onSubmit={onSubmit}
            setQuery={setSearchQuery}
            fetchRandomUnsplashPhotos={fetchRandomUnsplashPhotos}
            isImagesModalOpen={isImagesModalOpen}
          />
          <UnsplashImagesGrid
            data={data}
            randomImages={randomUnsplashImages?.results}
            error={error}
            isFetchingRandomImages={isFetchingRandomImages}
            isFetchingNextPage={isFetchingNextPage}
            loadMoreButtonRef={loadMoreButtonRef}
            onImageSelection={onImageSelection}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isSavingImage={isSavingImage}
            setPage={setPage}
          />
        </>
      ) : (
        <ImageUpload
          handleFileChange={handleFileChange}
          imageSrc={imageSrc}
          imageError={imageError}
          handleTryAgain={handleChangeImage}
          handleApply={handleApply}
          isUploading={isUploading}
        />
      )}
    </StyledModal>
  );
};

export default CoverImageModal;
