import { keys } from '@/gql/global/keys';
import { APIError } from '@/gql/global/types';
import {
  CREATE_KEY_RESULT,
  CREATE_PROJECT,
  DELETE_INSPIRATION_BOARD_ITEM_BY_PK,
  DELETE_KEY_RESULT,
  DELETE_PROJECT,
  GET_PROJECT,
  GET_PROJECTS_LIST,
  GET_PROJECTS_LIST_BY_ID,
  INSERT_IMAGE_ONE,
  INSERT_NOTE_ONE,
  UPDATE_ACTIONS_PROJECT_ORDER,
  UPDATE_BLOCK_ORDER,
  UPDATE_KEY_RESULT,
  UPDATE_KEY_RESULT_ORDER,
  UPDATE_NOTE_BY_PK,
  UPDATE_PROJECT,
  UPDATE_PROJECT_IMAGE,
} from '@/gql/project';
import {
  CreateKeyResultPayload,
  CreateKeyResultResponse,
  CreateProjectPayload,
  CreateProjectResponse,
  DeleteInspirationBoardPayload,
  DeleteInspirationBoardResponse,
  DeleteKeyResultPayload,
  DeleteKeyResultResponse,
  DeleteProjectPayload,
  DeleteProjectResponse,
  EditInspirationNotePayload,
  EditInspirationNoteResponse,
  GetProjectResponse,
  InsertImagePayload,
  InsertImageResponse,
  InsertNotePayload,
  InsertNoteResponse,
  ProjectDataTypeById,
  QueryDataType,
  QueryImageDataType,
  UpdateActionsProjectOrderPayload,
  UpdateActionsProjectOrderResponse,
  UpdateBlockProjectOrderResponse,
  UpdateKeyResultOrderPayload,
  UpdateKeyResultOrderResponse,
  UpdateKeyResultPayload,
  UpdateKeyResultResponse,
  UpdateProjectImagePayload,
  UpdateProjectImageResponse,
  UpdateProjectPayload,
  UpdateProjectResponse,
  UpdateWeeklyBlockOrderPayload,
} from '@/gql/project/types';
import { useCategories } from '@/services/categories/hooks';
import { fetchData } from '@/services/graphql';
import { queryClient } from '@/services/graphql/queryClient';
import { Action } from '@/types/actions';
import { Block } from '@/types/block';
import { Category } from '@/types/category';
import { getCategoryById } from '@/utils/category';
import { invalidateQueries } from '@/utils/tanStackQuery';
import { useToast } from '@chakra-ui/react';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

export const useProjects = (options?: UseQueryOptions<QueryDataType, APIError, QueryDataType>) => {
  return useQuery({
    queryKey: keys.project.all.queryKey,
    queryFn: () => fetchData(GET_PROJECTS_LIST),
    ...options,
  });
};

export const useProjectsByCategoryId = (
  categoryId: string,
  options?: UseQueryOptions<ProjectDataTypeById, APIError, ProjectDataTypeById>,
) => {
  return useQuery({
    queryKey: keys.project.category(categoryId).queryKey,

    queryFn: () =>
      fetchData(GET_PROJECTS_LIST_BY_ID, {
        categoryId,
      }),
    ...options,
    keepPreviousData: true,
  });
};

export const useCreateProject = (
  options?: Omit<UseMutationOptions<CreateProjectResponse, APIError, CreateProjectPayload>, 'onSuccess'>,
) => {
  return useMutation({
    mutationFn: (payload) => fetchData(CREATE_PROJECT, payload),

    onSuccess: (data, payload) => {
      invalidateQueries([keys.project.all, keys.project.category(payload.categoryId).queryKey]);
    },

    ...options,
  });
};

export const useUpdateProject = () => {
  const toast = useToast();

  return useMutation({
    mutationFn: (payload: UpdateProjectPayload) => fetchData<UpdateProjectResponse>(UPDATE_PROJECT, payload),

    onMutate: (variables) => {
      const previousDetailData = queryClient.getQueryData<GetProjectResponse>(
        keys.project.detail(variables.id).queryKey,
      );

      const previousAllData = queryClient.getQueryData<QueryDataType>(keys.project.all.queryKey);

      return {
        previousDetailData,
        previousAllData,
      };
    },

    onSuccess: (_, variables) => {
      invalidateQueries([
        keys.project.detail(variables.id).queryKey,
        keys.project.all.queryKey,
        keys.project.category(variables.categoryId).queryKey,
      ]);
    },

    onError: (error: any, variables, context) => {
      toast({
        title: error?.response?.errors?.[0]?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });

      queryClient.setQueryData<GetProjectResponse>(
        keys.project.detail(variables.id).queryKey,
        (context as any).previousDetailData,
      );

      queryClient.setQueryData<QueryDataType>(keys.project.all.queryKey, (context as any).previousAllData);
    },
  });
};

export const useUpdateProjectImage = () => {
  const toast = useToast();
  return useMutation({
    mutationFn: (payload: UpdateProjectImagePayload) =>
      fetchData<UpdateProjectImageResponse>(UPDATE_PROJECT_IMAGE, payload),

    onMutate: (variables) => {
      const previousDetailData = queryClient.getQueryData<GetProjectResponse>(
        keys.project.detail(variables.id).queryKey,
      );

      const previousAllData = queryClient.getQueryData<QueryImageDataType>(keys.project.all.queryKey);

      return {
        previousDetailData,
        previousAllData,
      };
    },

    onSuccess: (data, variables, context) => {
      invalidateQueries([keys.project.detail(variables.id).queryKey, keys.project.all.queryKey]);
    },

    onError: (error: any, variables, context) => {
      toast({
        title: error?.response?.errors?.[0]?.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });

      queryClient.setQueryData<GetProjectResponse>(
        keys.project.detail(variables.id).queryKey,
        (context as any).previousDetailData,
      );

      queryClient.setQueryData<QueryImageDataType>(keys.project.all.queryKey, (context as any).previousAllData);
    },
  });
};

export const useDeleteProject = (
  options?: Omit<UseMutationOptions<DeleteProjectResponse, APIError, DeleteProjectPayload>, 'onSuccess'>,
) => {
  return useMutation({
    mutationFn: (payload) => fetchData(DELETE_PROJECT, payload),

    onSuccess: () => {
      invalidateQueries([keys.project.all.queryKey]);
    },

    ...options,
  });
};

export const addCategoryToActions = (actions: Action[], categories: Category[]): Action[] => {
  return actions.map((action) => {
    let category;

    if (action.categoryId) {
      category = getCategoryById(categories, action.categoryId);
    }

    if (!category) {
      return action;
    }

    return {
      ...action,
      category,
    };
  });
};

export const addCategoryToBlocks = (blocks: Block[], categories: Category[]) => {
  return blocks.map((block: Block) => {
    const category = getCategoryById(categories, block.categoryId);

    if (!category) {
      return block;
    }

    return {
      ...block,
      category,
    };
  });
};

export const useProjectDetail = (
  id?: string,
  options?: UseQueryOptions<GetProjectResponse, APIError, GetProjectResponse>,
) => {
  const { refetch: fetchCategories } = useCategories({
    enabled: false,
  });

  return useQuery({
    queryKey: keys.project.detail(id ?? '').queryKey,

    queryFn: async () => {
      const response = await fetchData(GET_PROJECT, {
        id,
      });

      const categories = await fetchCategories();

      if (!categories?.data) {
        return response;
      }

      if (!response.projectByPk) {
        return null;
      }

      const actions = addCategoryToActions(response.projectByPk.actions, categories.data.category);

      const blocks = response.projectByPk.blocks.map((block: Block) => ({
        ...block,
        projectId: id,
      }));

      return {
        projectByPk: {
          ...response.projectByPk,
          actions,
          blocks: addCategoryToBlocks(blocks, categories.data.category),
        },
      };
    },
    ...options,
  });
};

export const useUpdateActionsProjectOrder = (
  options?: UseMutationOptions<UpdateActionsProjectOrderResponse, APIError, UpdateActionsProjectOrderPayload[]>,
) => {
  return useMutation({
    mutationFn: (actionUpdates: UpdateActionsProjectOrderPayload[]) =>
      fetchData<UpdateActionsProjectOrderResponse>(UPDATE_ACTIONS_PROJECT_ORDER, { actionUpdates }),

    ...options,
  });
};

export const useInsertImageOne = (options?: UseMutationOptions<InsertImageResponse, APIError, InsertImagePayload>) => {
  return useMutation({
    mutationFn: (payload: InsertImagePayload) => fetchData<InsertImageResponse>(INSERT_IMAGE_ONE, payload),
    ...options,
    onSuccess: (data, variables) => {
      if (!variables.projectId) return;

      invalidateQueries([keys.project.detail(variables.projectId).queryKey]);
    },
  });
};

export const useDeleteInspirationBoardItemByPk = (
  options?: Omit<
    UseMutationOptions<DeleteInspirationBoardResponse, APIError, DeleteInspirationBoardPayload>,
    'onMutate'
  >,
) => {
  return useMutation({
    mutationFn: (payload: DeleteInspirationBoardPayload) =>
      fetchData<DeleteInspirationBoardResponse>(DELETE_INSPIRATION_BOARD_ITEM_BY_PK, payload),
    ...options,
  });
};

export const useInsertNoteOne = (options?: UseMutationOptions<InsertNoteResponse, APIError, InsertNotePayload>) => {
  return useMutation({
    mutationFn: (payload: InsertNotePayload) => fetchData<InsertNoteResponse>(INSERT_NOTE_ONE, payload),
    ...options,
    onSuccess: (data, { projectId }) => {
      if (!projectId) return;

      invalidateQueries([keys.project.detail(projectId).queryKey]);
    },
  });
};

export const useEditNoteByPk = (
  options?: Omit<UseMutationOptions<EditInspirationNoteResponse, APIError, EditInspirationNotePayload>, 'onMutate'>,
) => {
  return useMutation({
    mutationFn: (payload: EditInspirationNotePayload) =>
      fetchData<EditInspirationNoteResponse>(UPDATE_NOTE_BY_PK, payload),
    ...options,
  });
};

export const useCreateKeyResult = (
  options?: Omit<UseMutationOptions<CreateKeyResultResponse, APIError, CreateKeyResultPayload>, 'onMutate'>,
) => {
  return useMutation({
    mutationFn: (payload) => fetchData(CREATE_KEY_RESULT, payload),
    ...options,
  });
};

export const useUpdateKeyResult = (
  options?: Omit<UseMutationOptions<UpdateKeyResultResponse, APIError, UpdateKeyResultPayload>, 'onMutate'>,
) => {
  return useMutation({
    mutationFn: (payload: Partial<UpdateKeyResultPayload>) => {
      delete payload.projectId;
      return fetchData(UPDATE_KEY_RESULT, payload);
    },
    ...options,
  });
};

export const useUpdateKeyResultsOrder = (
  options?: UseMutationOptions<UpdateKeyResultOrderResponse, APIError, UpdateKeyResultOrderPayload[]>,
) => {
  return useMutation({
    mutationFn: (updates: UpdateKeyResultOrderPayload[]) =>
      fetchData<UpdateKeyResultOrderResponse>(UPDATE_KEY_RESULT_ORDER, { updates }),

    ...options,
  });
};

export const useDeleteKeyResult = (
  options?: Omit<UseMutationOptions<DeleteKeyResultResponse, APIError, DeleteKeyResultPayload>, 'onMutate'>,
) => {
  return useMutation({
    mutationFn: (payload: Partial<DeleteKeyResultPayload>) => {
      delete payload.projectId;
      return fetchData(DELETE_KEY_RESULT, payload);
    },

    onSuccess: (data: DeleteKeyResultResponse) => {
      invalidateQueries([keys.project.detail(data?.deleteKeyResultByPk?.projectId).queryKey]);
    },

    ...options,
  });
};

export const useUpdateBlockProjectOrder = (
  options?: UseMutationOptions<UpdateBlockProjectOrderResponse, APIError, UpdateWeeklyBlockOrderPayload[]>,
) => {
  return useMutation({
    mutationFn: (blockUpdates: UpdateWeeklyBlockOrderPayload[]) =>
      fetchData<UpdateBlockProjectOrderResponse>(UPDATE_BLOCK_ORDER, { blockUpdates }),

    ...options,
  });
};
