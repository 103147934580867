import { gql } from 'graphql-request';

export const CRONOFY_INITIALIZE_CONNECT = gql`
  query cronofyInitializeConnect {
    cronofyInitializeConnect {
      auth_url
    }
  }
`;

export const CRONOFY_DISCONNECT_PROFILE = gql`
  mutation cronofyDisconnectProfile($profileId: String!) {
    cronofyDisconnectProfile(profileId: $profileId) {
      ok
    }
  }
`;

export const CRONOFY_GET_PROFILES = gql`
  query cronofyProfiles {
    cronofySyncProfiles {
      id
      provider
      name
      relinkUrl
      initializing
      outboundSyncCalendarId
      calendars {
        id
        name
        inboundSync
        readonly
      }
    }
  }
`;

export const CRONOFY_GET_EVENTS = gql`
  query cronofyEvents($calendarsIds: [String!]!, $fromDt: DateTime!, $toDt: DateTime!) {
    cronofyEvents(calendarsIds: $calendarsIds, fromDt: $fromDt, toDt: $toDt)
  }
`;

export const CRONOFY_SET_INBOUND_SYNC = gql`
  mutation cronofySetInboundSync($profileId: String!, $calendarId: String!, $enabled: Boolean!) {
    cronofySetInboundSync(profileId: $profileId, calendarId: $calendarId, enabled: $enabled) {
      ok
    }
  }
`;

export const CRONOFY_SET_OUTBOUND_SYNC = gql`
  mutation cronofySetOutboundSync($profileId: String!, $calendarId: String) {
    cronofySetOutboundSync(profileId: $profileId, calendarId: $calendarId) {
      ok
    }
  }
`;

export const CRONOFY_SYNC_EVENT = gql`
  mutation cronofySyncEvent($eventId: String!, $eventType: EventTypeEnum!, $deleted: Boolean) {
    cronofySyncEvent(eventId: $eventId, eventType: $eventType, deleted: $deleted) {
      ok
    }
  }
`;
