import EventDetailModal from '@/components/EventDetailModal';
import IconProgressState from '@/components/IconProgressState';
import ActionBadge from '@/components/MyPlanStep/WeeklyCalendar/WeeklyEventItem/ActionBadge';
import PreferredButton from '@/components/PreferredButton';
import { COMPLETE } from '@/constants/action';
import { useSoundEffects } from '@/contexts/SoundEffects';
import useIsDarkMode from '@/hooks/useIsDarkMode';
import { useUpdateAction } from '@/services/action/hooks';
import useSettingsStore from '@/stores/useSettings';
import { IconCircles, IconClose, IconUncategorized } from '@/theme/icons';
import { ProgressStatus } from '@/types/actions';
import { MyEvent } from '@/types/calendar';
import { checkEventStyles, getEventLength } from '@/utils/calendar';
import { getCalenderItemGradient, getColorFromToken } from '@/utils/color';
import rem from '@/utils/rem';
import { trackActionProgress, trackActionStarred } from '@/utils/tracking';
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  StackProps,
  Text,
  Tooltip,
  VStack,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import { format, isBefore, startOfDay, startOfToday } from 'date-fns';
import { isNull, isUndefined } from 'lodash';
import { MouseEvent, useCallback, useMemo } from 'react';
import { EventProps } from 'react-big-calendar';

type Props = {
  onUnplan: (event: MyEvent) => void;
  onClick: (event: MyEvent) => void;
  withShield?: boolean;
} & Pick<EventProps<MyEvent>, 'title' | 'event'> &
  Omit<StackProps, 'onClick'>;

function ScheduledWeeklyTimeSlot({ event, title, onUnplan, onClick, ...stackProps }: Props) {
  const dateIsBeforeToday = event.start && isBefore(startOfDay(new Date(event.start)), startOfToday());
  const isCompleted = event?.status === COMPLETE;
  const blockEvent = event.__metadata?.blockEvent;
  const categoryEvent = event.__metadata?.categoryEvent;

  const {
    isOpen: isOpenEventDetailModal,
    onOpen: onOpenEventDetailModal,
    onClose: onCloseEventDetailModal,
  } = useDisclosure();

  const { playCompleteAudio } = useSoundEffects();

  const [isOver, setIsOver] = useBoolean(false);
  const action = event?.__metadata?.action;
  const isStarred = !isNull(action?.dateOfStarring);
  const categoryColor = event?.resource?.color ?? 'gray.300';
  const isDarkMode = useIsDarkMode();
  const isExternal = event?.isExternal;
  const eventLength = useMemo(() => getEventLength(event), [event]);
  const categoryIcon = event?.resource?.icon ?? IconUncategorized;

  const bgGradient = useMemo(
    () => getCalenderItemGradient(categoryColor, isStarred, isDarkMode, isExternal),
    [isStarred, categoryColor, isDarkMode, isExternal],
  );

  const onUnplanEvent = useCallback(() => onUnplan(event), [onUnplan, event]);

  const updateActionByPk = useUpdateAction();

  const { showDeclinedEvents } = useSettingsStore();

  const handleStarClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (!action?.id) return;

      e.stopPropagation();

      if (!action?.dateOfStarring) {
        // action is about to be starred in the below mutation
        trackActionStarred();
      }

      updateActionByPk.mutate({
        actionId: action?.id,
        set: {
          dateOfStarring: typeof action?.dateOfStarring === 'string' ? null : new Date(),
        },
      });
    },
    [action?.dateOfStarring, action?.id, updateActionByPk],
  );

  const handleClickProgressStatus = useCallback(
    (progressStatus: ProgressStatus) => {
      if (!action?.id) return;

      updateActionByPk.mutate({
        actionId: action?.id,
        set: {
          progressStatus,
        },
      });

      action && trackActionProgress(progressStatus, action);

      if (progressStatus === 'complete') {
        playCompleteAudio();
      }
    },
    [updateActionByPk, action, playCompleteAudio],
  );

  const handleActionClick = useCallback(() => {
    if (isCompleted) {
      return;
    }

    if (isExternal) {
      onOpenEventDetailModal();
      return;
    }

    onClick(event);
  }, [event, isCompleted, isExternal, onClick, onOpenEventDetailModal]);

  return (
    <>
      <VStack
        className="weekly_timeslot_container"
        sx={{
          '@media print': {
            bgGradient: 'unset',
            backgroundColor: `print-background`,
            overflow: 'hidden !important',
          },
        }}
        position="relative"
        display="flex"
        width="full"
        height="full"
        minHeight={rem(15)}
        bgGradient={bgGradient}
        border={
          categoryEvent || blockEvent
            ? `${rem(1)} solid ${getColorFromToken(categoryColor, dateIsBeforeToday ? '0.4' : '1')} !important`
            : 'none'
        }
        borderRadius={`${rem(4)} !important`}
        onMouseEnter={setIsOver.on}
        onMouseLeave={setIsOver.off}
        {...stackProps}
        {...checkEventStyles(event?.participationStatus || '', showDeclinedEvents)}
      >
        <Button
          sx={{
            containerType: 'size',
            containerName: 'card',
          }}
          position="relative"
          overflow="hidden"
          width="full"
          height="full"
          minHeight="inherit"
          margin={0}
          padding={0}
          opacity={dateIsBeforeToday ? '0.5' : 1}
          cursor={isCompleted ? 'default' : 'inherit'}
          __css={{
            unset: 'all',
          }}
          onClick={handleActionClick}
        >
          {categoryEvent && (
            <Icon
              as={categoryIcon}
              sx={{
                [`@container card (max-height: ${rem(64)})`]: {
                  '&': { display: 'none' },
                },
              }}
              position="absolute"
              bottom={rem(-21)}
              left={rem(-27)}
              width={rem(86)}
              height={rem(86)}
              color={categoryColor}
              opacity="0.1"
            />
          )}

          {blockEvent && (
            <Icon
              as={IconCircles}
              position="absolute"
              bottom="0"
              left="0"
              width={rem(95)}
              height={rem(82)}
              color={categoryColor}
              opacity="0.1"
            />
          )}

          <Flex
            sx={{
              '@media screen': {
                [`@container card (max-height: ${rem(64)})`]: {
                  '&': { flexDirection: 'row', alignItems: 'center', paddingY: rem(3) },
                },
                [`@container card (max-width: ${rem(80)})`]: {
                  '&': { paddingX: rem(2) },
                },
                [`@container card (max-width: ${rem(80)}) and (max-height: ${rem(60)})`]: {
                  '&': { padding: rem(2) },
                },
              },
            }}
            justifyContent="space-between"
            flexDirection="column"
            gap={0}
            overflow="hidden"
            width="full"
            height="full"
            paddingX={rem(8)}
            paddingY={rem(8)}
          >
            <HStack alignItems={isExternal ? 'flex-start' : 'center'} width="full">
              {!isUndefined(action) && (
                <IconProgressState
                  state={action.progressStatus}
                  onClick={handleClickProgressStatus}
                  dimension={rem(14)}
                  sx={{
                    [`@container card (max-height: ${rem(64)})`]: {
                      '&': { display: 'flex' },
                    },
                    [`@container card (max-height: ${rem(24)})`]: {
                      '&': { display: 'none' },
                    },
                  }}
                  display="none"
                  as={Box}
                />
              )}

              {isExternal && event?.resource?.icon && (
                <Icon as={event?.resource?.icon} boxSize={rem(10)} marginTop={rem(3)} />
              )}

              {categoryEvent && (
                <Icon
                  as={categoryIcon}
                  sx={{
                    [`@container card (max-height: ${rem(64)})`]: {
                      '&': { display: 'flex' },
                    },
                    [`@container card (max-height: ${rem(24)})`]: {
                      '&': { display: 'none' },
                    },
                  }}
                  display="none"
                  width={rem(14)}
                  height={rem(14)}
                  color={categoryColor}
                />
              )}

              <Tooltip label={title} placement="top">
                <Text
                  as="span"
                  sx={{
                    '@media screen': {
                      [`@container card (max-height: ${rem(84)})`]: {
                        '&': { WebkitLineClamp: '2', '--chakra-line-clamp': '2' },
                      },
                      [`@container card (max-height: ${rem(32)})`]: {
                        '&': { WebkitLineClamp: '1', '--chakra-line-clamp': '1' },
                      },
                    },
                  }}
                  textStyle="small"
                  width="full"
                  color="text-primary"
                  textAlign="initial"
                  whiteSpace="normal"
                  wordBreak="break-word"
                  noOfLines={3}
                >
                  {title}
                </Text>
              </Tooltip>
            </HStack>

            <>
              {!isExternal && (
                <Flex>
                  <HStack
                    sx={{
                      '@media screen': {
                        [`@container card (min-height: ${rem(64)})`]: {
                          '&': { display: 'flex' },
                        },
                        [`@container card (max-height: ${rem(63)}) and (min-width: ${rem(200)})`]: {
                          '&': { display: 'flex' },
                        },
                      },
                    }}
                    alignItems="center"
                    display="none"
                    width="full"
                  >
                    {!isUndefined(action) && (
                      <IconProgressState
                        state={action?.progressStatus}
                        onClick={handleClickProgressStatus}
                        dimension={rem(14)}
                        as={Box}
                        sx={{
                          '@media screen': {
                            [`@container card (max-height: ${rem(64)})`]: {
                              '&': { display: 'none' },
                            },
                          },
                        }}
                      />
                    )}

                    <ActionBadge
                      backgroundColor={getColorFromToken(categoryColor, '0.10')}
                      sx={{
                        '@media screen': {
                          [`@container card (min-width: ${rem(90)})`]: {
                            '&': { display: 'inline-block' },
                          },
                        },
                      }}
                      display="none"
                    >
                      {eventLength}
                    </ActionBadge>

                    {event.start && !event.noScheduledTime && (
                      <ActionBadge
                        backgroundColor={getColorFromToken(categoryColor, '0.10')}
                        sx={{
                          '@media screen': {
                            [`@container card (min-width: ${rem(160)})`]: {
                              '&': { display: 'inline-block' },
                            },
                          },
                        }}
                        display="none"
                      >
                        {format(event.start, 'h:mm a')}
                      </ActionBadge>
                    )}

                    {!isUndefined(action) && (
                      <PreferredButton
                        isStarred={isStarred}
                        onClick={handleStarClick}
                        sx={{
                          '@media screen': {
                            [`@container card (max-width: ${rem(90)})`]: {
                              '&': { marginLeft: rem(6) },
                            },
                          },
                        }}
                        as={Box}
                        iconColor={isDarkMode ? 'white.500' : 'gray.500'}
                      />
                    )}
                  </HStack>
                </Flex>
              )}
            </>
          </Flex>
        </Button>

        <Button
          position="absolute"
          zIndex={15}
          top={rem(-10)}
          left={rem(-8)}
          alignItems="center"
          justifyContent="center"
          display={isOver && !(event.resource.isExternal || event.resource.planned) ? 'flex' : 'none'}
          width={rem(16)}
          minWidth={rem(16)}
          height={rem(16)}
          borderWidth={rem(1)}
          borderStyle="solid"
          borderColor="text-primary"
          borderRadius="full"
          cursor="pointer"
          backgroundColor="background-primary"
          onClick={onUnplanEvent}
          variant="unstyled"
        >
          <Icon as={IconClose} boxSize={rem(12)} color="text-primary" />
        </Button>
      </VStack>
      {isOpenEventDetailModal && (
        <EventDetailModal
          event={event}
          isOpen={isOpenEventDetailModal}
          title={title}
          onClose={onCloseEventDetailModal}
        />
      )}
    </>
  );
}

export default ScheduledWeeklyTimeSlot;
